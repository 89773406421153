import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { toggleRemovePhoneNumberModal } from '../../../redux/ui/actions'
import withDirection from '../../../settings/withDirection'
import Modals from '../../../components/feedback/modal'
import ModalStyle from '../../DefaultStyle/modal.style'
import { dateTimeStringToDisplayString } from '../../../helpers/dateUtil'
import IntlMessages from '../../../components/utility/intlMessages'
import TableWrapper from '../../DefaultStyle/table.style'
import ExtendPointExpiryDateForm from './extendPointExpiryDateForm'
import { Form, Input, Select } from 'antd'
import Button from '../../../components/uielements/button'
import { getFileItem } from 'antd/lib/upload/utils'
import { apiErrorHandler } from '../../../helpers/errorHelper'
import { postRemovePhoneNumber } from '../../../redux/request/apis'
import notification from '../../../components/notification'

const isoModal = ModalStyle(Modals)
const Modal = withDirection(isoModal)

class RemovePhoneNumberModal extends Component {

    state = {
        loading: false,
        selectedCountryCode: '852',
    }

    handleSubmit = async(e) => {
        this.setState({loading: true})
        e.preventDefault()
        
        const { form, intl, toggleRemovePhoneNumberModal } = this.props
        const { resetFields } = form
        let values = null

        try {
            values = await form.validateFields()
        } catch (error) {
            console.log(error)
        }

        try {
            if (values != null) {
                await postRemovePhoneNumber({data: values})
                resetFields()
                toggleRemovePhoneNumberModal({open: false})
                notification('info', intl.formatMessage({id: 'member.removePhone.success'}))
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error, intl, 'member.removePhone.')
        }

        this.setState({loading: false})
    }

    render() {
        const { loading, selectedCountryCode } = this.state
        const { open, toggleRemovePhoneNumberModal, form } = this.props
        const { getFieldDecorator, validateFields, resetFields }  = form
        const formItemLayout = {
            labelCol: { span:8 },
            wrapperCol: { span:14 },
        }

        const countryCodeSelector = getFieldDecorator('countryCode', {
            initialValue: '852',
        })(
            <Select
                onChange={(value) => {
                    this.setState({ selectedCountryCode: value }, () => {
                        validateFields(['phoneNumber'])
                    })
                }}
            >
                <Select.Option value={'852'}>+852</Select.Option>
                <Select.Option value={'86'}>+86</Select.Option>
                <Select.Option value={'853'}>+853</Select.Option>
            </Select>
        )

        const checkPhoneNumberLengthRule = (selectedCountryCode === '852' || selectedCountryCode === '853') ? { len: 8 } : {}
        
        return (
            <Modal 
                visible={open}
                onCancel={() => {
                    resetFields()
                    toggleRemovePhoneNumberModal({
                        open: false,
                        userId: undefined,
                    })
                }}
                title={<IntlMessages id="member.removePhone.title"/>}
                footer={null}
                width={700}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item
                        label={<IntlMessages id="member.phone"/>}
                        {...formItemLayout}
                    >
                        {getFieldDecorator('phoneNumber', {
                            rules: [
                                {
                                    required: true,
                                    message: <IntlMessages id='member.pleaseEnterPhoneNumber'/>,
                                },
                                checkPhoneNumberLengthRule
                            ]
                        })(
                            <Input
                                addonBefore={countryCodeSelector}
                            />
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
                        <Button
                            className="sino-btn"
                            loading={loading}
                            htmlType='submit'
                            style={{ margin: '0px 10px 0px 0px' }}
                        >
                            <IntlMessages id='common.submit'/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default injectIntl(
    connect(
        state => ({
            open: state.toggleRemovePhoneNumberModal.open,
        }), 
        { toggleRemovePhoneNumberModal }
    )(Form.create()(RemovePhoneNumberModal))
)
