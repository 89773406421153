import antdTw from 'antd/lib/locale-provider/zh_TW';
import appLocaleData from 'react-intl/locale-data/zh';
import zhHKMessages from '../locales/zh-Hans-HK.json';

const ZhHKLan = {
  messages: {
    ...zhHKMessages,
  },
  antd: antdTw,
  locale: 'zh-Hans-HK',
  data: appLocaleData,
};
export default ZhHKLan;
