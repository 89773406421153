import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Lightbox } from 'react-modal-image';
import { toggleImageLightbox } from '../../redux/ui/actions';

class ImageLightbox extends Component {
  render() {
    const { open, image, toggleImageLightbox } = this.props;
    if (!open) {
      return null;
    }
    return (
      <Lightbox
        medium={image}
        large={image}
        alt="Image"
        onClose={() => toggleImageLightbox({ open: false, image: undefined })}
      />
    );
  }
}

export default connect(
  state => ({
    open: state.toggleImageLightbox.open,
    image: state.toggleImageLightbox.image,
  }),
  {
    toggleImageLightbox,
  },
)(ImageLightbox);
