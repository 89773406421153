import * as cons from "./constants";
import { uiReducerMaker } from "../../helpers/reduxHelper";

const toggleImageLightbox = (
  state = { open: false, image: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_IMAGE_LIGHTBOX, state, action);
const toggleCreatePointAdjustmentTypeModal = (
  state = { open: false },
  action
) =>
  uiReducerMaker(cons.TOGGLE_CREATE_POINT_ADJUSTMENT_TYPE_MODAL, state, action);
const toggleReserveGiftModal = (
  state = { open: false, gift: undefined, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_RESERVE_GIFT_MODAL, state, action);
const toggleSelectPointRegistrationOptionModal = (
  state = {
    open: false,
    pointToGetIfApproved: undefined,
    campaignGifts: undefined,
  },
  action
) =>
  uiReducerMaker(
    cons.TOGGLE_SELECT_POINT_REGISTRATION_OPTION_MODAL,
    state,
    action
  );

const toggleCreateUserRemarkModal = (
  state = {
    open: false,
    userId: undefined,
    initRemark: undefined,
    canClose: false,
    initTypeId: undefined,
  },
  action
) => uiReducerMaker(cons.TOGGLE_CREATE_USER_REMARK_MODAL, state, action);
const toggleCreateUserRemarkTypeModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_CREATE_USER_REMARK_TYPE_MODAL, state, action);
const toggleRejectTransactionModal = (
  state = { open: false, transactionId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_REJECT_TRANSACTION_MODAL, state, action);
const toggleCreateOrUpdateGiftSkuModal = (
  state = { open: false, sku: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CREATE_OR_UPDATE_GIT_SKU_MODAL, state, action);
const toggleUpdateGiftSkuModal = (
  state = { open: false, sku: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_UPDATE_GIT_SKU_MODAL, state, action);
const toggleUpdateSCouponStockModal = (
  state = { open: false, sku: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_UPDATE_S_COUPON_STOCK_MODAL, state, action);
const toggleChangePasswordModal = (
  state = { open: false, accountId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CHANGE_PASSWORD_MODAL, state, action);
const toggleUpdateTransactionModal = (
  state = { open: false, transaction: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_UPDATE_TRANSACTION_MODAL, state, action);
const toggleTerminateMember = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_TERMINATE_MEMBER_MODAL, state, action);
const toggleChangePhoneModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CHANGE_PHONE_MODAL, state, action);
const toggleFreezeMemberModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_FREEZE_MEMBER_MODAL, state, action);
const toggleFreezeMemberRemarkModal = (
  state = { open: false, userId: undefined, freezeRecord: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_FREEZE_MEMBER_REMARK_MODAL, state, action);
const toggleUnreezeMemberModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_UNFREEZE_MEMBER_MODAL, state, action);
const toggleGiveSpecialInvitationBadgeModal = (
  state = { open: false, badgeId: undefined },
  action
) =>
  uiReducerMaker(
    cons.TOGGLE_GIVE_SPECIAL_INVITATION_BADGE_MODAL,
    state,
    action
  );
const toggleRemoveSpecialInvitationBadgeModal = (
  state = { open: false, badgeId: undefined },
  action
) =>
  uiReducerMaker(
    cons.TOGGLE_REMOVE_SPECIAL_INVITATION_BADGE_MODAL,
    state,
    action
  );
const toggleDirectCollectGiftModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_DIRECT_COLLECT_GIFT_MODAL, state, action);
const toggleRemovePhoneNumberModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_REMOVE_PHONE_NUMBER_MODAL, state, action);
const toggleGiftStockDetailModal = (
  state = { open: false, gift: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_GIFT_STOCK_DETAIL_MODAL, state, action);
const toggleGiftAlipayCouponCodeModal = (
  state = { open: false, gift: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_GIFT_ALIPAY_COUPON_CODE_MODAL, state, action);
const toggleSCouponStockDetailModal = (
  state = { open: false, coupon: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_S_COUPON_STOCK_DETAIL_MODAL, state, action);

const toggleImportShopsModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_IMPORT_SHOPS_MODAL, state, action);
const toggleEditGeneralRateModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_EDIT_GENERAL_RATE_MODAL, state, action);
const toggleEditDailyPointLimitModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_EDIT_DAILY_POINT_LIMIT_MODAL, state, action);

const toggleEditShortTnCModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_EDIT_SHORT_TNC_MODAL, state, action);
const toggleEditCouponTnCModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_EDIT_COUPON_TNC_MODAL, state, action);

const toggleCheckInQRCodeDetailModal = (
  state = { open: false, checkInEvent: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CHECK_IN_QRCODE_DETAIL_MODAL, state, action);

const toggleCreateShopRemarkModal = (
  state = {
    open: false,
    userId: undefined,
    initRemark: undefined,
    canClose: false,
  },
  action
) => uiReducerMaker(cons.TOGGLE_CREATE_SHOP_REMARK_MODAL, state, action);
const toggleCreateShopRemarkTypeModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_CREATE_SHOP_REMARK_TYPE_MODAL, state, action);
const toggleCreateBalanceAdjustReasonModal = (
  state = { open: false },
  action
) =>
  uiReducerMaker(cons.TOGGLE_CREATE_BALANCE_ADJUST_REASON_MODAL, state, action);

const toggleReserveCouponModal = (
  state = { open: false, coupon: undefined, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_RESERVE_COUPON_MODAL, state, action);

const toggleAddExtraQRCodeModal = (
  state = { open: false, checkInEvent: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_ADD_EXTRA_QRCODE_MODAL, state, action);

const toggleCreateDifferentRewardModal = (
  state = { open: false, reward: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CREATE_DIFFERENT_REWARD_MODAL, state, action);

const toggleChangeThirdPartyPasswordModal = (
  state = { open: false, accountId: undefined },
  action
) =>
  uiReducerMaker(cons.TOGGLE_CHANGE_THIRD_PARTY_PASSWORD_MODAL, state, action);

const toggleCreateOrUpdateIconModal = (
  state = { open: false, icon: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_CREATE_OR_UPDATE_ICON_MODAL, state, action);

const toggleExtendPointExpiryDateModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_EXTENT_POINT_EXPIRY_DATE_MODAL, state, action);

const toggleLicensePlateModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_LICENSE_PLATE_MODAL, state, action);
const toggleBundledCreditCardModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_BUNDLED_CREDIT_CARD_MODAL, state, action);

const toggleUpdateSinoClubRemarkModal = (
  state = { open: false, userId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_UPDATE_SINO_CLUB_REMARK_MODAL, state, action);

const togglePrivilegePromotionCodeModal = (
  state = { open: false, checkInEvent: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_PRIVILEGE_PROMOTION_CODE_MODAL, state, action);
const toggleImportMemberTagsModal = (
  state = { open: false, memberTagId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_IMPORT_MEMBER_TAGS_MODAL, state, action);
const toggleRemoveMemberTagsModal = (
  state = { open: false, memberTagId: undefined },
  action
) => uiReducerMaker(cons.TOGGLE_REMOVE_MEMBER_TAGS_MODAL, state, action);
const toggleAddMemberTagModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_ADD_MEMBER_TAG_MODAL, state, action);

const toggleEditGiftTnCModal = (state = { open: false }, action) =>
  uiReducerMaker(cons.TOGGLE_EDIT_GIFT_TNC_MODAL, state, action);

export default {
  toggleImageLightbox,
  toggleCreatePointAdjustmentTypeModal,
  toggleReserveGiftModal,
  toggleSelectPointRegistrationOptionModal,
  toggleCreateUserRemarkModal,
  toggleCreateUserRemarkTypeModal,
  toggleRejectTransactionModal,
  toggleCreateOrUpdateGiftSkuModal,
  toggleUpdateGiftSkuModal,
  toggleUpdateSCouponStockModal,
  toggleChangePasswordModal,
  toggleUpdateTransactionModal,
  toggleTerminateMember,
  toggleChangePhoneModal,
  toggleFreezeMemberModal,
  toggleFreezeMemberRemarkModal,
  toggleUnreezeMemberModal,
  toggleGiveSpecialInvitationBadgeModal,
  toggleRemoveSpecialInvitationBadgeModal,
  toggleDirectCollectGiftModal,
  toggleRemovePhoneNumberModal,
  toggleGiftStockDetailModal,
  toggleGiftAlipayCouponCodeModal,
  toggleImportShopsModal,
  toggleEditGeneralRateModal,
  toggleEditDailyPointLimitModal,

  toggleEditShortTnCModal,
  toggleEditCouponTnCModal,
  toggleCreateShopRemarkModal,
  toggleCreateShopRemarkTypeModal,
  toggleCreateBalanceAdjustReasonModal,

  // toggleCreateSameRewardModal,
  toggleCreateDifferentRewardModal,
  toggleReserveCouponModal,
  toggleSCouponStockDetailModal,
  toggleCheckInQRCodeDetailModal,
  toggleAddExtraQRCodeModal,

  toggleChangeThirdPartyPasswordModal,
  toggleCreateOrUpdateIconModal,

  toggleExtendPointExpiryDateModal,

  toggleLicensePlateModal,
  toggleBundledCreditCardModal,
  toggleUpdateSinoClubRemarkModal,

  togglePrivilegePromotionCodeModal,
  toggleImportMemberTagsModal,
  toggleRemoveMemberTagsModal,
  toggleAddMemberTagModal,
  toggleEditGiftTnCModal,
};
