/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { siteConfig } from '../../settings';
import appActions from '../../redux/app/actions';

const {
  changeCurrent,
} = appActions;

class Logo extends Component {
  handleClickLogo = () => {
    const { history } = this.props;
    history.push('/member');
    this.props.changeCurrent(['member']);
  }

  render() {
    const { collapsed } = this.props;
    return (
      <div className="isoLogoWrapper" onClick={this.handleClickLogo} role="presentation">
        {collapsed ? (
          <div>
            <h3>
              <a>
                <i className={siteConfig.siteIcon} />
              </a>
            </h3>
          </div>
        ) : (
          <h3>
            <a>
              {siteConfig.siteName}
            </a>
          </h3>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      changeCurrent,
    },
  )(Logo),
);
