import Auth from './auth/reducer';
import App from './app/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import RequestReducers from './request/reducers';
import UiReducers from './ui/reducers';

export default {
  Auth,
  App,
  LanguageSwitcher,
  ...RequestReducers,
  ...UiReducers,
};
