/* eslint-disable import/no-mutable-exports */
const siteConfig = {
  siteName: 'S+ REWARDS',
  siteIcon: 'ion-home',
  footerText: '©2018 Sino Group. All rights reserved.',
};
const themeConfig = {
  topbar: 'themecustom',
  sidebar: 'themecustom',
  layout: 'themecustom',
  theme: 'themecustom',
};
const language = 'english';
const apiConfig = {
  fetchUrl: process.env.REACT_APP_API_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  defaultCancelSpendingTransactionPointAdjustmentReasonId: process.env.REACT_APP_DEFAULT_CANCEL_SPENDING_TRANSACTION_REASON_ID,
};
const splusrewardsIconUrl = process.env.REACT_APP_SPLUS_REWARDS_ICON_URL;

const pointAdjustmentReasonIds = {
  cancelGift: Number(process.env.REACT_APP_DEFAULT_CANCEL_GIFT_REASON_ID),
  cancelCoupon: Number(process.env.REACT_APP_DEFAULT_CANCEL_COUPON_REASON_ID),
}

const remarkTypeIds = {
  cancelGift: Number(process.env.REACT_APP_DEFAULT_CANCEL_GIFT_REMARK_ID),
  cancelCoupon: Number(process.env.REACT_APP_DEFAULT_CANCEL_COUPON_REMARK_ID),
  extendPoints: Number(process.env.REACT_APP_DEFAULT_EXTENT_POINTS_REMARK_ID),
}

export {
  siteConfig, language, themeConfig, apiConfig, splusrewardsIconUrl, pointAdjustmentReasonIds, remarkTypeIds
};
