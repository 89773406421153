import { Form, Input } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../../components/uielements/button';
import IntlMessages from '../../../components/utility/intlMessages';
import Modals from '../../../components/feedback/modal';
import WithDirection from '../../../settings/withDirection';
import ModalStyle from '../../DefaultStyle/modal.style';
import { apiErrorHandler } from '../../../helpers/errorHelper';
import { toggleCreateShopRemarkTypeModal } from '../../../redux/ui/actions';
import { postCreateShopRemarkType } from '../../../redux/request/apis';

const isoModal = ModalStyle(Modals);
const Modal = WithDirection(isoModal);
const FormItem = Form.Item;

class CreateShopRemarkTypeModal extends Component {
  state = {
    submitLoading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.createShopRemarkType(values);
      }
    });
  };

  createShopRemarkType = async (values) => {
    this.setState({ submitLoading: true });
    const { intl, toggleCreateShopRemarkTypeModal, onSuccess } = this.props;
    const data = {
      ...values,
      enabled: true,
    };
    try {
      const response = await postCreateShopRemarkType({ data });
      this.setState({
        submitLoading: false,
      });
      this.props.form.resetFields();
      toggleCreateShopRemarkTypeModal({ open: false });
      if (onSuccess) {
        onSuccess(response.id);
      }
    } catch (error) {
      apiErrorHandler(error, intl);
      this.setState({
        submitLoading: false,
      });
    }
  };

  render() {
    const { toggleCreateShopRemarkTypeModal, open } = this.props;
    const { submitLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    return (
      <Modal
        visible={open}
        onCancel={() => toggleCreateShopRemarkTypeModal({ open: false })}
        title={<IntlMessages id="userRemarkType.addUserRemarkType" />}
        footer={null}
      >
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={<IntlMessages id="userRemarkType.remark.eng" />}
          >
            {getFieldDecorator('name_en', {
              rules: [
                {
                  required: true,
                  message: (
                    <IntlMessages id="userRemarkType.pleaseEnterEngRemark" />
                  ),
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<IntlMessages id="userRemarkType.remark.tc" />}
          >
            {getFieldDecorator('name_tc', {
              rules: [
                {
                  required: true,
                  message: (
                    <IntlMessages id="userRemarkType.pleaseEnterTcRemark" />
                  ),
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<IntlMessages id="userRemarkType.remark.sc" />}
          >
            {getFieldDecorator('name_sc', {
              rules: [
                {
                  required: true,
                  message: (
                    <IntlMessages id="userRemarkType.pleaseEnterScRemark" />
                  ),
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ span: 12, offset: 8 }}>
            <Button
              className="sino-btn"
              htmlType="submit"
              loading={submitLoading}
            >
              <IntlMessages id="common.submit" />
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedFormWIthSubmissionButton = Form.create()(
  CreateShopRemarkTypeModal,
);

export default injectIntl(connect(
  state => ({
    open: state.toggleCreateShopRemarkTypeModal.open,
  }),
  {
    toggleCreateShopRemarkTypeModal,
  },
)(WrappedFormWIthSubmissionButton));
