import { Button, Col, Form, Input, InputNumber, Row, Select, Table } from 'antd'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import IntlMessages from '../../../components/utility/intlMessages'
import { dateTimeStringToDate, dateTimeToAPIString } from '../../../helpers/dateUtil'
import { apiErrorHandler } from '../../../helpers/errorHelper'
import { postExtendPointExpirationDate } from '../../../redux/request/apis'
import notification from '../../../components/notification'

const { Option } = Select

class ExtendPointExpiryDateForm extends Component {

    state = {
        loading: false,
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { form, bucket } = this.props

        form.validateFields((err, values) => {
            if (!err) {
                this.extendBucket(bucket, { ...values })
            }
        })
    }

    extendBucket = async (bucket, {days, months}) => {
        const { intl, userId, onSuccess, form } = this.props
        const { id, expirationDate } = bucket
        let newExpirationDate = dateTimeStringToDate(expirationDate)

        if (typeof days === 'number') newExpirationDate.add(days, 'd')
        if (typeof months === 'number') newExpirationDate.add(months, 'M')

        const data = {
            userId,
            pointBucketId: id,
            expirationDate: dateTimeToAPIString(newExpirationDate)
        }

        let response = {}

        this.setState({ loading:true })

        try {
            response = await postExtendPointExpirationDate({data})
            notification('info', intl.formatMessage({ id:'member.extendPoints.success' }))
        } catch (error) {
            apiErrorHandler(error, intl)
        }

        form.resetFields()
        this.setState({ loading: false })

        if (typeof onSuccess === 'function' && response.id) {    
            onSuccess(data)
        }
    }

    render() {
        const { loading } = this.state
        const { getFieldDecorator } = this.props.form
        const { extendTimes, extendable, expirationDate } = this.props.bucket
        
        let newExpirationDate = dateTimeStringToDate(expirationDate)
        newExpirationDate.add(6, 'M')

        if (extendable === false && extendTimes > 0) {
            return (
                <IntlMessages id="member.extendPoints.hasBeenExtended"/>
            )
        }
        
        if (extendable === false || newExpirationDate.isBefore()) {
            return (
                <IntlMessages id="member.extendPoints.cannotExtend"/>
            )
        } 

        return (
            <Form onSubmit={this.handleSubmit}>
                {getFieldDecorator('userId')(
                    <Input type={'hidden'}/>
                )}
                {getFieldDecorator('pointBucketId')(
                    <Input type={'hidden'}/>
                )}
                <Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                            {getFieldDecorator('months', { 
                                rules: [
                                    { required: true, message: <IntlMessages id="memebr.extendPoints.message.pleaseInputMonths"/> }
                                ]
                            })(
                                <Select disabled={loading}>
                                    <Option value={3}>3<IntlMessages id="member.extendPoints.label.months"/></Option>
                                    <Option value={4}>4<IntlMessages id="member.extendPoints.label.months"/></Option>
                                    <Option value={5}>5<IntlMessages id="member.extendPoints.label.months"/></Option>
                                    <Option value={6}>6<IntlMessages id="member.extendPoints.label.months"/></Option>
                                </Select>
                            )}
                        </Col>
                        <Col span={12}>
                            <Button
                                loading={loading}
                                htmlType="submit"
                                style={{ margin: '0px 10px 0px 0px' }}
                            >
                                <IntlMessages id="common.submit" />
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(
    connect(
        state => ({

        }),
        dispatch => ({

        })
    )(Form.create({
        mapPropsToFields: (props) => {
            return {
                userId: Form.createFormField({value: props.userId}),
                pointBucketId: Form.createFormField({value: props.bucket.id}),
            }
        }
    })(ExtendPointExpiryDateForm))
)