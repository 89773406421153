import {
  Form, Modal, Row, Col, Input, Skeleton, Tag,
} from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ModalImage from 'react-modal-image';
import _ from 'lodash';
import Button from '../../../components/uielements/button';
import IntlMessages from '../../../components/utility/intlMessages';
import { aes_decrypt } from '../../../helpers/simpleEncrypt';
import { apiErrorHandler } from '../../../helpers/errorHelper';
import { toggleDirectCollectGiftModal } from '../../../redux/ui/actions';
import { postRedeemGift, getGiftItemById } from '../../../redux/request/apis';
import { ReserveGiftModalWrapper } from '../style/reserveGiftModal.style';
import notification from '../../../components/notification';
import { dateTimeStringToDisplayString } from '../../../helpers/dateUtil';
import { getAllShop } from '../../../redux/request/actions';
class DirectCollectGiftModal extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  state = {
    collectObject: undefined,
    submitLoading: false,
    giftItemInfo: undefined,
    giftItemInfoLoading: false,
    inputRef: null
  }

  componentDidMount() {
    const {
      getAllShop, shopsFetched,
    } = this.props;
    if (!shopsFetched) {
      getAllShop({});
    }
  }

  componentDidUpdate(props) {
    if(!props.open && this.inputRef.current){
      this.inputRef.current.focus();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { intl } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        try {
          const collectObject = JSON.parse(aes_decrypt(values.keyword));
          this.setState({ collectObject });
          this.handleGetGiftInfo(collectObject.gift_item_id);
        } catch (err) {
          notification('error', intl.formatMessage({ id: 'qrCode.invalid' }));
          this.props.form.setFieldsValue({
            keyword: undefined,
          });
        }
      }
    });
  }

  handleGetGiftInfo = async (giftItemId) => {
    this.setState({ giftItemInfoLoading: true });
    const giftItemInfo = await getGiftItemById({ giftItemId });
    this.setState({ giftItemInfoLoading: false, giftItemInfo });
  }

  showConfirm = () => {
    const { intl } = this.props;
    const SELF = this;
    Modal.confirm({
      title: intl.formatMessage({ id: 'member.giftRedeemtion.confirmRedeem' }),
      okText: intl.formatMessage({ id: 'member.giftRedeemtion.redeem' }),
      cancelText: intl.formatMessage({ id: 'common.cancel' }),
      onOk() {
        SELF.redeemGift();
      },
      onCancel() {
      },
    });
  }

  redeemGift = async () => {
    this.setState({ submitLoading: true });
    const {
      intl,
    } = this.props;
    const { collectObject } = this.state;
    const data = {
      giftItemId: collectObject.gift_item_id,
      userId: collectObject.user_id,
    };
    try {
      await postRedeemGift({ data });
      notification('info', intl.formatMessage({ id: 'member.giftReservation.successfullyRedeemGift' }));
      this.handleClose();
    } catch (error) {
      apiErrorHandler(error, intl);
      this.setState({ submitLoading: false });
    }
  };

  handleClose = () => {
    const {
      toggleDirectCollectGiftModal,
    } = this.props;
    this.setState({ collectObject: undefined, submitLoading: false });
    toggleDirectCollectGiftModal({ open: false });
  }

  collectButtion = () => {
    const {
      intl, malls, shops, accountInfo, shopsLoading,
    } = this.props;
    const {
      collectObject, giftItemInfo, submitLoading,
    } = this.state;

    let name = 'name_en';
    if (intl.locale === 'en-US') {
      name = 'name_en';
    } else if (intl.locale === 'zh-Hans-HK') {
      name = 'name_tc';
    } else if (intl.locale === 'zh-Hans-CN') {
      name = 'name_sc';
    }
    if (giftItemInfo && collectObject && !shopsLoading) {
      const sku = _.find(giftItemInfo.gift.skus, e => giftItemInfo.giftItem.skuId === e.id);
      const skuShops = _.filter(shops, e => sku.shopIds.includes(e.id) && e.internal === false);
      const mall = _.find(malls, e => e.code === sku.mallCode);
      if (giftItemInfo.giftItem.status === 'TO_BE_COLLECTED' && giftItemInfo.giftItem.status === 'EXPIRED') {
        return <p />;
      } if (giftItemInfo.giftItem.status === 'REDEEMED') {
        return <p style={{ fontWeight: 800, fontSize: '20px', color: '#f64744' }}><IntlMessages id="gift.alreadyRedeemed" /></p>;
      }
      if (giftItemInfo.giftItem.status === 'RESERVED' || giftItemInfo.giftItem.status === 'RECEIVED') {
        if (skuShops.length > 0) {
          return (
            <p style={{ fontWeight: 800, fontSize: '20px', color: '#f64744' }}>
              <IntlMessages id="gift.collecteAtMerchant" />
              {':'}
              <br />
              {
                skuShops.map(shop => <div style={{ fontWeight: 800, fontSize: '16px', color: '#323332' }}>{shop[name]}</div>)
              }
            </p>
          );
        }
        if (accountInfo && (!accountInfo.mall || accountInfo.mall.code === sku.mallCode || !sku.mallCode)) {
          return (
            <Button
              className="sino-btn"
              icon="check"
              style={{ marginBottom: '5px' }}
              loading={submitLoading}
              onClick={this.showConfirm}
            >
              <IntlMessages id="member.giftRedeemtion.redeem" />
            </Button>
          );
        }
        return (
          <p style={{ fontWeight: 800, fontSize: '20px', color: '#f64744' }}>
            <IntlMessages id="gift.collecteAtOtherMall" />
            {':'}
            <br />
            <div style={{ fontWeight: 800, fontSize: '16px', color: '#323332' }}>{mall[name]}</div>
          </p>
        );
      }
      return <p />;
    }
    return <p />;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      intl, open,
    } = this.props;
    const {
      collectObject, giftItemInfo, giftItemInfoLoading,
    } = this.state;
    
    let locale = 'en';
    if (intl.locale === 'en-US') {
      locale = 'en';
    } else if (intl.locale === 'zh-Hans-HK') {
      locale = 'zh_hk';
    } else if (intl.locale === 'zh-Hans-CN') {
      locale = 'zh_cn';
    }
    console.log('isOpen:', open)
    const sku = giftItemInfo ? _.find(giftItemInfo.gift.skus, e => giftItemInfo.giftItem.skuId === e.id) : undefined;
    return (
      <Modal
        visible={open}
        onCancel={this.handleClose}
        title={<IntlMessages id="gift.directCollectGift" />}
        footer={null}
        width={600}
      >
        {
          collectObject
            ? (
              <ReserveGiftModalWrapper>
                {
                  giftItemInfoLoading
                    ? <Skeleton active />
                    : (
                      <>
                        <Row>
                          <Col span={4}>
                            <ModalImage alt="gift image" hideDownload small={giftItemInfo.gift.thumbnailImageUrl} large={giftItemInfo.gift.contentImageUrl} />
                          </Col>
                          <Col span={20} style={{ paddingLeft: 10 }}>
                            <div style={{ fontSize: '20px', color: '#e50076' }}>
                              {giftItemInfo.gift.title[locale]}
                            </div>
                            <div style={{ color: '#323332' }}>
                              <IntlMessages id="gift.expiryDate" />
                              :
                              {' '}
                              {dateTimeStringToDisplayString(giftItemInfo.gift.expiryDate)}
                            </div>
                            <div style={{ color: '#323332' }}>
                              <IntlMessages id="gift.collectLocation" />
                              :
                              {' '}
                              {giftItemInfo ? giftItemInfo.gift.redemptionLocation[locale] : '-'}
                            </div>
                            <div>
                              <Tag color="magenta" style={{ marginBottom: '5px' }}>
                                {sku.tag[locale]}
                              </Tag>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                          <Col style={{ textAlign: 'center' }}>
                            {this.collectButtion()}
                          </Col>
                        </Row>
                      </>
                    )
                }
              </ReserveGiftModalWrapper>
            )
            : (
              <div style={{ fontSize: '20px', color: '#e50076' }}>
                <IntlMessages id="gift.scanGiftQrcode" />
                <br />
                <Form className="search-form" onSubmit={this.onSubmit}>
                  <Row gutter={24}>
                    <Col xs={24} md={24}>
                      <Form.Item>
                        {getFieldDecorator('keyword', {
                          rules: [
                          ],
                        })(
                          <Input onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''} ref={this.inputRef} autoFocus />,
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            )
        }
      </Modal>
    );
  }
}

const WrappedFormWIthSubmissionButton = Form.create()(
  DirectCollectGiftModal,
);

export default injectIntl(connect(
  state => ({
    open: state.toggleDirectCollectGiftModal.open,
    accountInfo: state.getMyAccountInfo.data,
    malls: state.getAllMall.data,
    shops: state.getAllShop.data,
    shopsFetched: state.getAllShop.dataFetched,
    shopsLoading: state.getAllShop.isFetching,
  }),
  {
    toggleDirectCollectGiftModal,
    getAllShop,
  },
)(WrappedFormWIthSubmissionButton));
