import { all } from 'redux-saga/effects';
import requestSagas from './request/sagas';
import uiSagas from './ui/sagas';

import authSagas from './auth/saga';

function* rootSagas() {
  const allSagas = [];
  allSagas.push(authSagas());
  requestSagas.forEach((sage) => {
    allSagas.push(sage);
  });
  uiSagas.forEach((sage) => {
    allSagas.push(sage);
  });

  yield all(allSagas);
}

export default rootSagas;
