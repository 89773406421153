import * as cons from "./constants";
import { requestReducerMaker, initialState } from "../../helpers/reduxHelper";

const postLogin = (state = initialState, action) =>
  requestReducerMaker(cons.POST_LOGIN, state, action);
const postLoginByRefreshToken = (state = initialState, action) =>
  requestReducerMaker(cons.POST_LOGIN_BY_REFRESH_TOKEN, state, action);
const getMyAuthority = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MY_AUTHORITY, state, action);
const getLogout = (state = initialState, action) =>
  requestReducerMaker(cons.GET_LOGOUT, state, action);

// Member Apis
const getMemberList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MEMBER_LIST, state, action);
const getMemberDetail = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MEMBER_DETAIL, state, action);
const postForceLogoutMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_FORCE_LOGOUT_MEMBER, state, action);
const getUserRemarkList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_REMARK_LIST, state, action);
const postCreateUserRemark = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_USER_REMARK, state, action);
const getAllUserRemarkType = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_USER_REMARK_TYPE, state, action);
const postCreateUserRemarkType = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_USER_REMARK_TYPE, state, action);
const postCreateMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_MEMBER, state, action);
const putUpdateMember = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_MEMBER, state, action);
const postTerminateMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_TERMINATE_MEMBER, state, action);
const getAccountWithPhone = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ACCOUNT_WITH_PHONE, state, action);
const getSendSmsVerify = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SEND_SMS_VERIFY, state, action);
const putChangeMemberPhone = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_CHANGE_MEMBER_PHONE, state, action);
const postFreezeMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_FREEZE_MEMBER, state, action);
const postUnfreezeMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UNFREEZE_MEMBER, state, action);
const putUpdateMemberReceivePromotionMessage = (state = initialState, action) =>
  requestReducerMaker(
    cons.PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE,
    state,
    action
  );
const putUpdateMemberAllowTransferPersonalData = (
  state = initialState,
  action
) =>
  requestReducerMaker(
    cons.PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA,
    state,
    action
  );
const getUserRecordList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_RECORD_LIST, state, action);
const getVerificationCodeList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_VERIFICATION_CODE_LIST, state, action);

const getUnlinkedSpendTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.GET_UNLINKED_SPEND_TRANSACTION, state, action);
const getLinkedSpendTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.GET_LINKED_SPEND_TRANSACTION, state, action);
const postSpendingLinkUp = (state = initialState, action) =>
  requestReducerMaker(cons.POST_LINK_UP_SPENDING, state, action);
const postExtendPointExpirationDate = (state = initialState, action) =>
  requestReducerMaker(cons.POST_EXTEND_POINT_EXPIRATION_DATE, state, action);
const postGetParkingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.POST_GET_PARKING_TRANSACTION, state, action);
const postRedeemParkingHour = (state = initialState, action) =>
  requestReducerMaker(cons.POST_REDEEM_PARKING_HOUR, state, action);
const postReturnGift = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RETURN_GIFT, state, action);
const postReturnSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RETURN_SCOUPON, state, action);
// Mall Apis
const getAllMall = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_MALL, state, action);
const getShopList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SHOP_LIST, state, action);
const putUpdateShop = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_SHOP, state, action);
const getAllShopByMallCode = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_SHOP_BY_MALL_CODE, state, action);
const getAllShopCategory = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_SHOP_CATEGORY, state, action);
const getShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SHOP, state, action);
const getAllShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_SHOP, state, action);
const postCreateShop = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_SHOP, state, action);
// Gift Apis
const getGiftList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_LIST, state, action);
const getAllFoodieShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_FOODIE_SHOP, state, action);
const postUpdateGiftEnabled = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_GIFT_ENABLED, state, action);
const getGiftListForRedeem = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_LIST_FOR_REDEEM, state, action);
const getGiftListForRedeemRemarkList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_LIST_FOR_REDEEM_REMARK_LIST, state, action);
const getGiftForUser = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_FOR_USER, state, action);
const postRedeemGift = (state = initialState, action) =>
  requestReducerMaker(cons.POST_REDEEM_GIFT, state, action);
const postReserveGift = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RESERVE_GIFT, state, action);
const getAllGiftCategory = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_GIFT_CATEGORY, state, action);
const postCreateGift = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_GIFT, state, action);
const putUpdateGift = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_GIFT, state, action);
const getGift = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT, state, action);
const postAddGiftItem = (state = initialState, action) =>
  requestReducerMaker(cons.POST_ADD_GIFT_ITEM, state, action);
const postMinusGiftItem = (state = initialState, action) =>
  requestReducerMaker(cons.POST_MINUS_GIFT_ITEM, state, action);
const postCreateSkuForGift = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_SKU_FOR_GIFT, state, action);
const getGiftStockDetail = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_STOCK_DETAIL, state, action);
const getGiftItemById = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_ITEM_BY_ID, state, action);
const putUpdateGiftSKU = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_GIFT_SKU, state, action);
const deleteGift = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_GIFT, state, action);
const deleteGiftSku = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_GIFT_SKU, state, action);
const getGiftCodeCSV = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_CODE_CSV, state, action);
const getGiftTnC = (state = initialState, action) =>
  requestReducerMaker(cons.GET_GIFT_TNC, state, action);

// Unpublished privilege
const getPrivilegeList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_PRIVILEGE_LIST, state, action);
const getPrivilege = (state = initialState, action) =>
  requestReducerMaker(cons.GET_PRIVILEGE, state, action);

// Badge Apis
const getAllBadge = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_BADGE, state, action);
const getBadgeList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BADGE_LIST, state, action);
const postCreateBadge = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BADGE, state, action);
const putUpdateBadge = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_BADGE, state, action);
const getBadge = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BADGE, state, action);
const postGiveSpecialInvitationBadgeToMember = (state = initialState, action) =>
  requestReducerMaker(
    cons.POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER,
    state,
    action
  );
const deleteSpecialInvitationBadgeFromMember = (state = initialState, action) =>
  requestReducerMaker(
    cons.DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER,
    state,
    action
  );
const getUsersThatHaveAchievedGivenBadge = (state = initialState, action) =>
  requestReducerMaker(
    cons.GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE,
    state,
    action
  );

// Member Tags
const getAllMemberTags = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_MEMBER_TAGS, state, action);
const getMemberTagsList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MEMBER_TAGS_LIST, state, action);
const getMemberTagsListByMember = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MEMBER_TAGS_LIST_BY_MEMBER, state, action);
const getMemberTags = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MEMBER_TAGS, state, action);
const postCreateMemberTags = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_MEMBER_TAGS, state, action);
const putUpdateMemberTags = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_MEMBER_TAGS, state, action);
const postUpdateMemberTagsTargetList = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_MEMBER_TAGS_TARGET_LIST, state, action);
const postAddMemberTagsTargetList = (state = initialState, action) =>
  requestReducerMaker(cons.POST_ADD_MEMBER_TAGS_TARGET_LIST, state, action);
const postRemoveMemberTagsTargetList = (state = initialState, action) =>
  requestReducerMaker(cons.POST_REMOVE_MEMBER_TAGS_TARGET_LIST, state, action);
const postMemberTagsAddMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_MEMBER_TAGS_ADD_MEMBER, state, action);
const postMemberTagsRemoveMember = (state = initialState, action) =>
  requestReducerMaker(cons.POST_MEMBER_TAGS_REMOVE_MEMBER, state, action);

// Crazy Ad
const getCrazyAdList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CRAZY_AD_LIST, state, action);
const getCrazyAd = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CRAZY_AD, state, action);
const postCreateCrazyAd = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_CRAZY_AD, state, action);
const putUpdateCrazyAd = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_CRAZY_AD, state, action);
// Campaign
const getCampaignList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CAMPAIGN_LIST, state, action);
const getCampaign = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CAMPAIGN, state, action);
const postCreateCampaign = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_CAMPAIGN, state, action);
const putUpdateCampaign = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_CAMPAIGN, state, action);
const getAllCampaign = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_CAMPAIGN, state, action);
// Promotion
const getPromotionList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_PROMOTION_LIST, state, action);
const getPromotion = (state = initialState, action) =>
  requestReducerMaker(cons.GET_PROMOTION, state, action);
const postCreatePromotion = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_PROMOTION, state, action);
const putUpdatePromotion = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_PROMOTION, state, action);
// Bonus Rule
const getBonusRuleList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BONUS_RULE_LIST, state, action);
const getBonusRule = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BONUS_RULE, state, action);
const postCreateBonusRule = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BONUS_RULE, state, action);
const putUpdateBonusRule = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_BONUS_RULE, state, action);
// User Push
const getUserPushList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_PUSH_LIST, state, action);
const getUserPush = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_PUSH, state, action);
const postCreateUserPush = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_USER_PUSH, state, action);
const putUpdateUserPush = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_USER_PUSH, state, action);
const putCancelUserPush = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_CANCEL_USER_PUSH, state, action);
// Merchant Push
const getMerchantPushList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_PUSH_LIST, state, action);
const getMerchantPush = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_PUSH, state, action);
const postCreateMerchantPush = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_MERCHANT_PUSH, state, action);
const putUpdateMerchantPush = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_MERCHANT_PUSH, state, action);
const putCancelMerchantPush = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_CANCEL_MERCHANT_PUSH, state, action);
// FAQ
const getAllOnlineEnquiry = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_ONLINE_ENQUIRY, state, action);
const getOnlineEnquiryList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ONLINE_ENQUIRY_LIST, state, action);
const getOnlineEnquiry = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ONLINE_ENQUIRY, state, action);
const putUpdateOnlineEnquiry = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_ONLINE_ENQUIRY, state, action);
// Spending
const getSpendingTransactionList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SPENDING_TRANSACTION_LIST, state, action);
const putApproveSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_APPROVE_SPENDING_TRANSACTION, state, action);
const putRejectSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_REJECT_SPENDING_TRANSACTION, state, action);
const getAllRejectReason = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_REJECT_REASON, state, action);
const postSubmitSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.POST_SUMBIT_SPENDING_TRANSACTION, state, action);
const postUpdateSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_SPENDING_TRANSACTION, state, action);
const postRecallSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RECALL_SPENDING_TRANSACTION, state, action);
const postEstimateSpendingTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.POST_ESTIMATE_SPENDING_TRANSACTION, state, action);
// Point Transaction
const getPointTransactionList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_POINT_TRANSACRION_LIST, state, action);
const getPointTransactionBySpendingTransactionId = (
  state = initialState,
  action
) =>
  requestReducerMaker(
    cons.GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID,
    state,
    action
  );
// Point Adjustment
const getAllPointAdjustmentType = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_POINT_ADJUSTMENT_TYPE, state, action);
const postSubmitPointAdjustment = (state = initialState, action) =>
  requestReducerMaker(cons.POST_SUBMIT_POINT_ADJUSTMENT, state, action);
const postCreatePointAdjustmentType = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_POINT_ADJUSTMENT_TYPE, state, action);
// Image API
const postUploadImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_IMAGE, state, action);
const postUploadProfileImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_PROFILE_IMAGE, state, action);
const postUploadReceiptImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_RECEIPT_IMAGE, state, action);
const postUploadMallImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_MALL_IMAGE, state, action);
const postUploadGiftImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_GIFT_IMAGE, state, action);
const postUploadSCouponImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_S_COUPON_IMAGE, state, action);
const postUploadAdvertisementImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_ADVERTISEMENT_IMAGE, state, action);
const postUploadBadgeImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_BADGE_IMAGE, state, action);
const postUploadBadgePromotionImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_BADGE_PROMOTION_IMAGE, state, action);

// Merchant
const getAllMerchant = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_MERCHANT, state, action);
const getMerchantStock = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_STOCK, state, action);
const getMerchantReport = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_REPORT, state, action);
const getMerchantByShopId = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_BY_SHOP_ID, state, action);
const postMerchantRegister = (state = initialState, action) =>
  requestReducerMaker(cons.POST_MERCHANT_REGISTER, state, action);
const putUpdateMerchant = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_MERCHANT, state, action);
// User Management
const putUpdateUserPassword = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_USER_PASSWORD, state, action);
const getUserList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_LIST, state, action);
const putUpdateUserPasswordWithCurrentPassword = (
  state = initialState,
  action
) =>
  requestReducerMaker(
    cons.PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD,
    state,
    action
  );
const postCreateUser = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_USER, state, action);
const putUpdateUserStatus = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_USER_STATUS, state, action);
const getMyAccountInfo = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MY_ACCOUNT_INFO, state, action);
const putUpdateAccountStatus = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_ACCOUNT_STATUS, state, action);
// Inbox Message
const getInboxMessageScheduleList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_INBOX_MESSAGE_SCHEDULE_LIST, state, action);
const getInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.GET_INBOX_MESSAGE_SCHEDULE, state, action);
const postCreateInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_INBOX_MESSAGE_SCHEDULE, state, action);
const putUpdateInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_INBOX_MESSAGE_SCHEDULE, state, action);
const putCancelInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_CANCEL_INBOX_MESSAGE_SCHEDULE, state, action);
// Merchant Message
const getMerchantInboxMessageList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_INBOX_MESSAGE_LIST, state, action);
const getMerchantInboxMessageScheduleList = (state = initialState, action) =>
  requestReducerMaker(
    cons.GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST,
    state,
    action
  );
const getMerchantInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MERCHANT_INBOX_MESSAGE_SCHEDULE, state, action);
const postCreateMerchantInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(
    cons.POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    state,
    action
  );
const putUpdateMerchantInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(
    cons.PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    state,
    action
  );
const putCancelMerchantInboxMessageSchedule = (state = initialState, action) =>
  requestReducerMaker(
    cons.PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    state,
    action
  );
// Parking
const getAllCarPark = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_CAR_PARK, state, action);
const getCarPark = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CAR_PARK, state, action);
const putUpdateCarPak = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_CAR_PARK, state, action);
// Check In
const getCheckInEventList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CHECK_IN_EVENT_LIST, state, action);
const postCreateCheckInEvent = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_CHECK_IN_EVENT, state, action);
const getCheckInEvent = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CHECK_IN_EVENT, state, action);
const putUpdateCheckInEvent = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_CHECK_IN_EVENT, state, action);
// Custom Referral Point
const getCustomReferralPointList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CUSTOM_REFERRAL_POINT_LIST, state, action);
const postCreateCustomReferralPoint = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_CUSTOM_REFERRAL_POINT, state, action);
const getCustomReferralPoint = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CUSTOM_REFERRAL_POINT, state, action);
const putUpdateCustomReferralPoint = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_CUSTOM_REFERRAL_POINT, state, action);
const deleteCustomReferralPoint = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_CUSTOM_REFERRAL_POINT, state, action);
// SDollar
const getSDollarShopList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_SHOP_LIST, state, action);
const getSDollarShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_SHOP, state, action);
const putConfirmPendingBalance = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_CONFIRM_PENDING_BALANCE, state, action);

const getAllShopRemarkType = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_SHOP_REMARK_TYPE, state, action);
const postCreateShopRemarkType = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_SHOP_REMARK_TYPE, state, action);
const postCreateShopRemark = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_SHOP_REMARK, state, action);
const getShopRemarkList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SHOP_REMARK_LIST, state, action);
const getAllBalanceAdjustReason = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_BALANCE_ADJUST_REASON, state, action);
const postCreateBalanceAdjustReason = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BALANCE_ADJUST_REASON, state, action);
const postCreateBalanceAdjustment = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BALANCE_ADJUSTMENT, state, action);
const getTenantBalancePeriod = (state = initialState, action) =>
  requestReducerMaker(cons.GET_TENANT_BALANCE_PERIOD, state, action);
const getTenantBalanceRecordList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_TENANT_BALANCE_RECORD_LIST, state, action);
const postResendTenantEmail = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RESEND_TENANT_EMAIL, state, action);
const getSDollarGlobalRate = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_GLOBAL_RATE, state, action);
const postUpdateSDollarGlobalRate = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_SDOLLAR_GLOBAL_RATE, state, action);
const getAllTenantBalanceRecordList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_TENANT_BALANCE_RECORD_LIST, state, action);
const putIssueCheque = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_ISSUE_CHEQUE, state, action);

// SDollar Coupon
const getSDollarCouponList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_COUPON_LIST, state, action);
const getSDollarCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_COUPON, state, action);
const postCreateSDollarCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_SDOLLAR_COUPON, state, action);
const putUpdateSDollarCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_SDOLLAR_COUPON, state, action);

const getSDollarShortTnC = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SDOLLAR_SHORT_TNC, state, action);
const postUpdateSDollarShortTnC = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_SDOLLAR_SHORT_TNC, state, action);
const getSCouponTnC = (state = initialState, action) =>
  requestReducerMaker(cons.GET_S_COUPON_TNC, state, action);
const postUpdateSCouponTnC = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_S_COUPON_TNC, state, action);

const getAllTenantShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_TENANT_SHOP, state, action);
const getAllFoodieTenantShop = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_FOODIE_TENANT_SHOP, state, action);

// S-Coupon
const getSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.GET_S_COUPON, state, action);
const postCreateSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_S_COUPON, state, action);
const putUpdateSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_S_COUPON, state, action);
const deleteSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_S_COUPON, state, action);
const postAddSCouponStock = (state = initialState, action) =>
  requestReducerMaker(cons.POST_ADD_S_COUPON_STOCK, state, action);
const postMinusSCouponStock = (state = initialState, action) =>
  requestReducerMaker(cons.POST_MINUS_S_COUPON_STOCK, state, action);
const postUpdateSCouponEnabled = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_S_COUPON_ENABLED, state, action);
const getSCouponList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_S_COUPON_LIST, state, action);
const getSCouponStockDetail = (state = initialState, action) =>
  requestReducerMaker(cons.GET_S_COUPON_STOCK_DETAIL, state, action);
const getUserReserveableSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_RESERVEABLE_S_COUPON, state, action);
const getUserReserveableSCouponRemarkList = (state = initialState, action) =>
  requestReducerMaker(
    cons.GET_USER_RESERVEABLE_S_COUPON_REMARK_LIST,
    state,
    action
  );
const getUserSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_S_COUPON, state, action);
const postReserveSCoupon = (state = initialState, action) =>
  requestReducerMaker(cons.POST_RESERVE_S_COUPON, state, action);
const getCouponCodeCSV = (state = initialState, action) =>
  requestReducerMaker(cons.GET_COUPON_CODE_CSV, state, action);

const putAddQRCodeAmount = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_ADD_QRCODE_AMOUNT, state, action);
const getTenantStatementPDF = (state = initialState, action) =>
  requestReducerMaker(cons.GET_TENANT_STATMENT_PDF, state, action);

// Badge Promotion
const getAllSchedule = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ALL_SCHEDULE, state, action);
const postCreateBadgePromotion = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BADGE_PROMOTION, state, action);
const putUpdateBadgePromotion = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_BADGE_PROMOTION, state, action);
const deleteBadgePromotion = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_BADGE_PROMOTION, state, action);
const getBadgePromotionList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BADGE_PROMOTION_LIST, state, action);
const getBadgePromotionDetail = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BADGE_PROMOTION_DETAIL, state, action);

const getUserFreezeRecord = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_FREEZE_RECORD, state, action);
const getUserTerminateRecord = (state = initialState, action) =>
  requestReducerMaker(cons.GET_USER_TERMINATE_RECORD, state, action);

const getSpendingLimit = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SPENDING_LIMIT, state, action);
const postChangeSpendingLimit = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CHANGE_SPENDING_LIMIT, state, action);

// Third Party Integrator
const getIntegratorList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_INTEGRATOR_LIST, state, action);
const getIntegrator = (state = initialState, action) =>
  requestReducerMaker(cons.GET_INTEGRATOR, state, action);
const postCreateIntegrator = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_INTEGRATOR, state, action);
const putUpdateIntegrator = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_INTEGRATOR, state, action);
const putUpdateIntegratorPassword = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_INTEGRATOR_PASSWORD, state, action);

// Mall
const getMallList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MALL_LIST, state, action);
const getMall = (state = initialState, action) =>
  requestReducerMaker(cons.GET_MALL, state, action);
const putUpdateMall = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_MALL, state, action);

// Contactlass parking
const getLicensePlatesRecordList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_LICENSE_PLATE_RECORD_LIST, state, action);
const getCreditCardListByMemberId = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CREDIT_CARD_LIST_BY_MEMBER_ID, state, action);
const getLicensePlatesRecordWithParkingHistory = (
  state = initialState,
  action
) =>
  requestReducerMaker(
    cons.GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY,
    state,
    action
  );
const getLicensePlatesRecordWithParkingHistoryDetail = (
  state = initialState,
  action
) =>
  requestReducerMaker(
    cons.GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL,
    state,
    action
  );
const putExitCarpark = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_EXIT_CARPARK, state, action);
const putSettleOutstandingPayment = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_SETTLE_OUTSTANDING_PAYMENT, state, action);
const getCarparkList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_CARPARK_LIST, state, action);
const getParkHistoryWithTransaction = (state = initialState, action) =>
  requestReducerMaker(cons.GET_PARK_HISTORY_WITH_TRANSACTION, state, action);

// Contactlass parking(simulation)
const putVerifyLicensePlate = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_VERIFY_LICENSE_PLATE, state, action);
const postEnterCarPark = (state = initialState, action) =>
  requestReducerMaker(cons.POST_ENTER_CAR_PARK, state, action);
const putExitCarParkSystem = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_EXIT_CAR_PARK_SYSTEM, state, action);
const putUpdateParkingTime = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_PARKING_TIME, state, action);
const postPayByOctopus = (state = initialState, action) =>
  requestReducerMaker(cons.POST_PAY_BY_OCTOPUS, state, action);
const putInvalidateCreditCard = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_INVALIDATE_CREDIT_CARD, state, action);

// Banner
const getBannerList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BANNER_LIST, state, action);
const getBannerById = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BANNER_BY_ID, state, action);
const postCreateBanner = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_BANNER, state, action);
const putUpdateBanner = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_BANNER, state, action);
const putUpdateBannerEnabled = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_BANNER_ENABLED, state, action);
const getBannerRedirectList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BANNER_REDIRECT_LIST, state, action);

// Banner Category
const getBannerCategoryList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BANNER_CATEGORY_LIST, state, action);

// Banner Area
const getBannerAreaList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_BANNER_AREA_LIST, state, action);

// Dynamic Banner List
const getDynamicBannerList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_DYNAMIC_BANNER_LIST, state, action);
const getDynamicBannerListById = (state = initialState, action) =>
  requestReducerMaker(cons.GET_DYNAMIC_BANNER_LIST_BY_ID, state, action);
const putUpdateDynamicBannerList = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_DYNAMIC_BANNER_LIST, state, action);

// Homepage banner
const getHomepageBannerList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOMEPAGE_BANNER_LIST, state, action);
const getHomepageBannerById = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOMEPAGE_BANNER_BY_ID, state, action);
const postCreateHomepageBanner = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_HOMEPAGE_BANNER, state, action);
const putUpdateHomepageBanner = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_HOMEPAGE_BANNER, state, action);
const putDisableHomepageBanner = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_DISABLE_HOMEPAGE_BANNER, state, action);
const putEnableHomepageBanner = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_ENABLE_HOMEPAGE_BANNER, state, action);
const getHomepageBannerRedirectList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOMEPAGE_REDIRECT_LIST, state, action);

// Hot deal
const getHotDealList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOT_DEAL_LIST, state, action);
const getHotDealFullList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOT_DEAL_FULL_LIST, state, action);
const getHotDealDetail = (state = initialState, action) =>
  requestReducerMaker(cons.GET_HOT_DEAL, state, action);
const postHotDeal = (state = initialState, action) =>
  requestReducerMaker(cons.POST_HOT_DEAL, state, action);
const deleteHotDeal = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_HOT_DEAL, state, action);
const updateHotDeal = (state = initialState, action) =>
  requestReducerMaker(cons.UPDATE_HOT_DEAL, state, action);
const getFoodieGiftList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_FOODIE_GIFT_LIST, state, action);
const getFoodieCouponList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_FOODIE_COUPON_LIST, state, action);
const postFoodieImage = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPLOAD_FOODIE_IMAGE, state, action);
const deleteHotDealItem = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_HOT_DEAL_ITEM, state, action);

// Sino Club Member
const getSinoClubMemberList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_CLUB_MEMBER_LIST, state, action);
const getSinoClubMember = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_CLUB_MEMBER, state, action);
const getSinoUpgradeList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_UPGRADE_LIST, state, action);
const getSinoParkingList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_PARKING_LIST, state, action);
const getSinoDocumentList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_DOC_LIST, state, action);

// Activity
const getActivityList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ACTIVITY_LIST, state, action);
const getActivity = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ACTIVITY, state, action);
const postCreateActivity = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_ACTIVITY, state, action);
const putUpdateActivity = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_ACTIVITY, state, action);
const putUpdateActivityEnabled = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_ACTIVITY_ENABLED, state, action);
const putAllocateActivityQuota = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_ALLOCATE_ACTIVITY_QUOTA, state, action);
const getActivityUsedRewardList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ACTIVITY_USED_REWARD_LIST, state, action);

// Activity Segment
const getActivitySegments = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ACTIVITY_SEGMENTS, state, action);
const postCreateActivitySegment = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_ACTIVITY_SEGMENT, state, action);
const putUpdateActivitySegment = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_ACTIVITY_SEGMENT, state, action);
const deleteActivitySegment = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_ACTIVITY_SEGMENT, state, action);

// Sino Club P Member Referral Code
const getPMemberReferralCodeList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_P_MEMBER_REFERRAL_CODE_LIST, state, action);
const getPMemberReferralCode = (state = initialState, action) =>
  requestReducerMaker(cons.GET_P_MEMBER_REFERRAL_CODE, state, action);
const postCreatePMemberReferralCode = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_P_MEMBER_REFERRAL_CODE, state, action);
const putUpdatePMemberReferralCode = (state = initialState, action) =>
  requestReducerMaker(cons.PUT_UPDATE_P_MEMBER_REFERRAL_CODE, state, action);
const putUpdatePMemberReferralCodeEnabled = (state = initialState, action) =>
  requestReducerMaker(
    cons.POST_UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED,
    state,
    action
  );
const deletePMemberReferralCode = (state = initialState, action) =>
  requestReducerMaker(cons.DELETE_P_MEMBER_REFERRAL_CODE, state, action);

// Estate
const getSinoEstateList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_SINO_ESTATE_LIST, state, action);
const getNonSinoEstateList = (state = initialState, action) =>
  requestReducerMaker(cons.GET_NON_SINO_ESTATE_LIST, state, action);
const getEstate = (state = initialState, action) =>
  requestReducerMaker(cons.GET_ESTATE, state, action);
const postCreateEstate = (state = initialState, action) =>
  requestReducerMaker(cons.POST_CREATE_ESTATE, state, action);
const postUpdateEstate = (state = initialState, action) =>
  requestReducerMaker(cons.POST_UPDATE_ESTATE, state, action);

export default {
  postLogin,
  postLoginByRefreshToken,
  getMyAuthority,
  getLogout,
  getMemberList,
  getMemberDetail,
  postForceLogoutMember,
  getUserRemarkList,
  postCreateUserRemark,
  getAllUserRemarkType,
  postCreateUserRemarkType,
  postCreateMember,
  putUpdateMember,
  postTerminateMember,
  getAccountWithPhone,
  getSendSmsVerify,
  putChangeMemberPhone,
  postFreezeMember,
  postUnfreezeMember,
  putUpdateMemberReceivePromotionMessage,
  putUpdateMemberAllowTransferPersonalData,
  getUserRecordList,
  getAllMall,
  getShopList,
  putUpdateShop,
  getAllShopByMallCode,
  getAllShopCategory,
  getShop,
  getAllShop,
  postCreateShop,
  getGiftList,
  getAllFoodieShop,
  postUpdateGiftEnabled,
  getGiftListForRedeem,
  getGiftListForRedeemRemarkList,
  postReserveGift,
  getGiftForUser,
  postRedeemGift,
  getAllGiftCategory,
  postCreateGift,
  putUpdateGift,
  getGift,
  postAddGiftItem,
  postMinusGiftItem,
  postCreateSkuForGift,
  getGiftStockDetail,
  getGiftItemById,
  putUpdateGiftSKU,
  deleteGift,
  deleteGiftSku,
  getGiftCodeCSV,
  getGiftTnC,
  getPrivilegeList,
  getPrivilege,
  getAllBadge,
  getBadgeList,
  postCreateBadge,
  putUpdateBadge,
  getBadge,
  postGiveSpecialInvitationBadgeToMember,
  deleteSpecialInvitationBadgeFromMember,
  getUsersThatHaveAchievedGivenBadge,
  getAllMemberTags,
  getMemberTagsList,
  getMemberTagsListByMember,
  getMemberTags,
  postCreateMemberTags,
  putUpdateMemberTags,
  postUpdateMemberTagsTargetList,
  postAddMemberTagsTargetList,
  postRemoveMemberTagsTargetList,
  postMemberTagsAddMember,
  postMemberTagsRemoveMember,
  getCrazyAdList,
  getCrazyAd,
  postCreateCrazyAd,
  putUpdateCrazyAd,
  getCampaignList,
  getCampaign,
  postCreateCampaign,
  putUpdateCampaign,
  getAllCampaign,
  getPromotionList,
  getPromotion,
  postCreatePromotion,
  putUpdatePromotion,
  getBonusRuleList,
  getBonusRule,
  postCreateBonusRule,
  putUpdateBonusRule,
  getUserPushList,
  getUserPush,
  postCreateUserPush,
  putUpdateUserPush,
  putCancelUserPush,
  getMerchantPushList,
  getMerchantPush,
  postCreateMerchantPush,
  putUpdateMerchantPush,
  putCancelMerchantPush,
  getAllOnlineEnquiry,
  getOnlineEnquiryList,
  getOnlineEnquiry,
  putUpdateOnlineEnquiry,
  getSpendingTransactionList,
  putApproveSpendingTransaction,
  putRejectSpendingTransaction,
  getAllRejectReason,
  postSubmitSpendingTransaction,
  postUpdateSpendingTransaction,
  postRecallSpendingTransaction,
  postEstimateSpendingTransaction,
  getPointTransactionList,
  getPointTransactionBySpendingTransactionId,
  getAllPointAdjustmentType,
  postSubmitPointAdjustment,
  postCreatePointAdjustmentType,
  postUploadImage,
  postUploadProfileImage,
  postUploadReceiptImage,
  postUploadMallImage,
  postUploadGiftImage,
  postUploadSCouponImage,
  postUploadAdvertisementImage,
  postUploadBadgeImage,
  postUploadBadgePromotionImage,
  getAllMerchant,
  getMerchantStock,
  getMerchantReport,
  getMerchantByShopId,
  postMerchantRegister,
  putUpdateMerchant,
  putUpdateUserPassword,
  getUserList,
  putUpdateUserPasswordWithCurrentPassword,
  postCreateUser,
  putUpdateUserStatus,
  getMyAccountInfo,
  putUpdateAccountStatus,
  getInboxMessageScheduleList,
  getInboxMessageSchedule,
  postCreateInboxMessageSchedule,
  putUpdateInboxMessageSchedule,
  putCancelInboxMessageSchedule,
  getMerchantInboxMessageList,
  getMerchantInboxMessageScheduleList,
  getMerchantInboxMessageSchedule,
  postCreateMerchantInboxMessageSchedule,
  putUpdateMerchantInboxMessageSchedule,
  putCancelMerchantInboxMessageSchedule,
  getAllCarPark,
  getCarPark,
  putUpdateCarPak,
  getCheckInEventList,
  postCreateCheckInEvent,
  getCheckInEvent,
  putUpdateCheckInEvent,
  getCustomReferralPointList,
  postCreateCustomReferralPoint,
  getCustomReferralPoint,
  putUpdateCustomReferralPoint,
  deleteCustomReferralPoint,

  getSDollarShopList,
  getSDollarShop,
  putConfirmPendingBalance,
  getAllShopRemarkType,
  postCreateShopRemarkType,
  postCreateShopRemark,
  getShopRemarkList,
  getAllBalanceAdjustReason,
  postCreateBalanceAdjustReason,
  postCreateBalanceAdjustment,
  getTenantBalancePeriod,
  getTenantBalanceRecordList,
  postResendTenantEmail,
  getSDollarGlobalRate,
  postUpdateSDollarGlobalRate,
  getAllTenantBalanceRecordList,
  putIssueCheque,

  getSDollarCouponList,
  getSDollarCoupon,
  postCreateSDollarCoupon,
  putUpdateSDollarCoupon,
  getSDollarShortTnC,
  postUpdateSDollarShortTnC,
  getSCouponTnC,
  postUpdateSCouponTnC,
  getAllTenantShop,
  getAllFoodieTenantShop,
  getSCoupon,
  postCreateSCoupon,
  putUpdateSCoupon,
  deleteSCoupon,
  postAddSCouponStock,
  postMinusSCouponStock,
  postUpdateSCouponEnabled,
  getSCouponList,
  getSCouponStockDetail,
  getUserReserveableSCoupon,
  getUserReserveableSCouponRemarkList,
  getUserSCoupon,
  postReserveSCoupon,
  getCouponCodeCSV,

  putAddQRCodeAmount,
  getTenantStatementPDF,

  getAllSchedule,
  postCreateBadgePromotion,
  putUpdateBadgePromotion,
  deleteBadgePromotion,
  getBadgePromotionList,
  getBadgePromotionDetail,

  getUserFreezeRecord,
  getUserTerminateRecord,
  getVerificationCodeList,

  getSpendingLimit,
  postChangeSpendingLimit,

  getIntegratorList,
  getIntegrator,
  postCreateIntegrator,
  putUpdateIntegrator,
  putUpdateIntegratorPassword,

  getMallList,
  getMall,
  putUpdateMall,
  getUnlinkedSpendTransaction,
  getLinkedSpendTransaction,
  postSpendingLinkUp,
  postExtendPointExpirationDate,
  postGetParkingTransaction,
  postRedeemParkingHour,
  postReturnGift,
  postReturnSCoupon,

  getLicensePlatesRecordList,
  getCreditCardListByMemberId,
  getLicensePlatesRecordWithParkingHistory,
  getLicensePlatesRecordWithParkingHistoryDetail,
  putExitCarpark,
  putSettleOutstandingPayment,
  getCarparkList,
  getParkHistoryWithTransaction,

  putVerifyLicensePlate,
  postEnterCarPark,
  putExitCarParkSystem,
  putUpdateParkingTime,
  postPayByOctopus,
  putInvalidateCreditCard,

  getBannerList,
  getBannerById,
  postCreateBanner,
  putUpdateBanner,
  putUpdateBannerEnabled,
  getBannerRedirectList,
  getBannerCategoryList,
  getBannerAreaList,

  getDynamicBannerList,
  getDynamicBannerListById,
  putUpdateDynamicBannerList,

  getHomepageBannerList,
  getHomepageBannerById,
  postCreateHomepageBanner,
  putUpdateHomepageBanner,
  putDisableHomepageBanner,
  putEnableHomepageBanner,
  getHomepageBannerRedirectList,

  getBannerRedirectList,

  getHotDealList,
  getHotDealFullList,
  getHotDealDetail,
  postHotDeal,
  deleteHotDeal,
  updateHotDeal,
  getFoodieGiftList,
  getFoodieCouponList,
  postFoodieImage,
  deleteHotDealItem,

  getSinoClubMemberList,
  getSinoClubMember,
  getSinoUpgradeList,
  getSinoParkingList,
  getSinoDocumentList,

  getActivityList,
  getActivity,
  postCreateActivity,
  putUpdateActivity,
  putUpdateActivityEnabled,
  putAllocateActivityQuota,
  getActivityUsedRewardList,

  getActivitySegments,
  postCreateActivitySegment,
  putUpdateActivitySegment,
  deleteActivitySegment,

  getSinoEstateList,
  getNonSinoEstateList,
  getEstate,
  postCreateEstate,
  postUpdateEstate,

  getPMemberReferralCodeList,
  getPMemberReferralCode,
  postCreatePMemberReferralCode,
  putUpdatePMemberReferralCode,
  putUpdatePMemberReferralCodeEnabled,
  deletePMemberReferralCode,
};
