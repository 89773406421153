import CryptoJS from 'crypto-js';

const aes256_key = 'PqIgw2*?#38.?_irAE4>3!r{#?ef01lw';
const aes256_ivkey = 'iR2a4FgPkQp3cwqb';

const key = CryptoJS.enc.Latin1.parse(aes256_key);
const iv = CryptoJS.enc.Latin1.parse(aes256_ivkey);

export const aes_encrypt = data => CryptoJS.AES.encrypt(data, key, { iv });
export const aes_decrypt = (data) => {
  const decrypted = CryptoJS.AES.decrypt(data, key, { iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
export const md5_encrypt = data => CryptoJS.MD5(data).toString();
