import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { clearUser, getUser, setUser } from '../../helpers/utility';
import AuthHelper from '../../helpers/authHelper';
import actions from './actions';
import notification from '../../components/notification';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { history, userInfo, intl } = payload;
    try {
      const response = yield call(AuthHelper.login, userInfo);

      response.username = userInfo.username;
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: response,
        history,
      });
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR, payload: error.response, intl });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ payload, history }) {
    yield setUser(payload);

    yield put({ type: 'GET_AUTHORITY_REQUEST' });
    if (history) {
      history.push('/dashboard');
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, ({ payload, intl }) => {
    notification(
      'error',
      payload.detail,
      intl.formatMessage({ id: 'api.auth.login.errorCode.400' }),
    );
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearUser();
    yield put(push('/signin'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const user = getUser();
    if (user) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: user,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
