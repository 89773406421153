import * as cons from "./constants";
import * as apis from "./apis";
import { requestActionMaker } from "../../helpers/reduxHelper";

export const postLogin = (para) =>
  requestActionMaker({ cons: cons.POST_LOGIN, api: apis.postLogin, ...para });
export const postLoginByRefreshToken = (para) =>
  requestActionMaker({
    cons: cons.POST_LOGIN_BY_REFRESH_TOKEN,
    api: apis.postLoginByRefreshToken,
    ...para,
  });
export const getMyAuthority = (para) =>
  requestActionMaker({
    cons: cons.GET_MY_AUTHORITY,
    api: apis.getMyAuthority,
    ...para,
  });
export const getLogout = (para) =>
  requestActionMaker({ cons: cons.GET_LOGOUT, api: apis.getLogout, ...para });
// Member Apis
export const getMemberList = (para) =>
  requestActionMaker({
    cons: cons.GET_MEMBER_LIST,
    api: apis.getMemberList,
    ...para,
  });
export const getMemberDetail = (para) =>
  requestActionMaker({
    cons: cons.GET_MEMBER_DETAIL,
    api: apis.getMemberDetail,
    ...para,
  });
export const postForceLogoutMember = (para) =>
  requestActionMaker({
    cons: cons.POST_FORCE_LOGOUT_MEMBER,
    api: apis.postForceLogoutMember,
    ...para,
  });
export const getUserRemarkList = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_REMARK_LIST,
    api: apis.getUserRemarkList,
    ...para,
  });
export const postCreateUserRemark = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_USER_REMARK,
    api: apis.postCreateUserRemark,
    ...para,
  });
export const getAllUserRemarkType = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_USER_REMARK_TYPE,
    api: apis.getAllUserRemarkType,
    ...para,
  });
export const postCreateUserRemarkType = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_USER_REMARK_TYPE,
    api: apis.postCreateUserRemarkType,
    ...para,
  });
export const postCreateMember = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_MEMBER,
    api: apis.postCreateMember,
    ...para,
  });
export const putUpdateMember = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MEMBER,
    api: apis.putUpdateMember,
    ...para,
  });
export const postTerminateMember = (para) =>
  requestActionMaker({
    cons: cons.POST_TERMINATE_MEMBER,
    api: apis.postTerminateMember,
    ...para,
  });
export const getAccountWithPhone = (para) =>
  requestActionMaker({
    cons: cons.GET_ACCOUNT_WITH_PHONE,
    api: apis.getAccountWithPhone,
    ...para,
  });
export const getSendSmsVerify = (para) =>
  requestActionMaker({
    cons: cons.GET_SEND_SMS_VERIFY,
    api: apis.getSendSmsVerify,
    ...para,
  });
export const putChangeMemberPhone = (para) =>
  requestActionMaker({
    cons: cons.PUT_CHANGE_MEMBER_PHONE,
    api: apis.putChangeMemberPhone,
    ...para,
  });
export const postFreezeMember = (para) =>
  requestActionMaker({
    cons: cons.POST_FREEZE_MEMBER,
    api: apis.postFreezeMember,
    ...para,
  });
export const postUnfreezeMember = (para) =>
  requestActionMaker({
    cons: cons.POST_UNFREEZE_MEMBER,
    api: apis.postUnfreezeMember,
    ...para,
  });
export const putUpdateMemberReceivePromotionMessage = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE,
    api: apis.putUpdateMemberReceivePromotionMessage,
    ...para,
  });
export const putUpdateMemberAllowTransferPersonalData = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA,
    api: apis.putUpdateMemberAllowTransferPersonalData,
    ...para,
  });
export const getUserRecordList = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_RECORD_LIST,
    api: apis.getUserRecordList,
    ...para,
  });

export const getParkingRecordList = (para) =>
  requestActionMaker({
    cons: cons.GET_PARKING_RECORD_LIST,
    api: apis.getParkHistoryWithTransaction,
    ...para,
  });

export const getUnlinkedSpendTransaction = (para) =>
  requestActionMaker({
    reset: true,
    cons: cons.GET_UNLINKED_SPEND_TRANSACTION,
    api: apis.getUnlinkedSpendingTransaction,
    ...para,
  });
export const getLinkedSpendTransaction = (para) =>
  requestActionMaker({
    reset: true,
    cons: cons.GET_LINKED_SPEND_TRANSACTION,
    api: apis.getLinkedSpendingTransaction,
    ...para,
  });
export const postSpendingLinkUp = (para) =>
  requestActionMaker({
    cons: cons.POST_LINK_UP_SPENDING,
    api: apis.postSpendingLinkUp,
    ...para,
  });
export const postExtendPointExpirationDate = (para) =>
  requestActionMaker({
    cons: cons.POST_EXTEND_POINT_EXPIRATION_DATE,
    api: apis.postExtendPointExpirationDate,
    ...para,
  });
export const postGetParkingTransaction = (para) =>
  requestActionMaker({
    cons: cons.POST_GET_PARKING_TRANSACTION,
    api: apis.postGetParkingTransaction,
    ...para,
  });
export const postRedeemParkingHour = (para) =>
  requestActionMaker({
    cons: cons.POST_GET_PARKING_TRANSACTION,
    api: apis.postRedeemParkingHour,
    ...para,
  });
export const postReturnGift = (para) =>
  requestActionMaker({
    cons: cons.POST_RETURN_GIFT,
    api: apis.postReturnGift,
    ...para,
  });
export const postReturnSCoupon = (para) =>
  requestActionMaker({
    cons: cons.POST_RETURN_SCOUPON,
    api: apis.postReturnSCoupon,
    ...para,
  });
export const getVerificationCodeList = (para) =>
  requestActionMaker({
    cons: cons.GET_VERIFICATION_CODE_LIST,
    api: apis.getVerificationCodeList,
    ...para,
  });
// Mall Apis
export const getAllMall = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_MALL,
    api: apis.getAllMall,
    ...para,
  });
export const getShopList = (para) =>
  requestActionMaker({
    cons: cons.GET_SHOP_LIST,
    api: apis.getShopList,
    ...para,
  });
export const putUpdateShop = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_SHOP,
    api: apis.putUpdateShop,
    ...para,
  });
export const getAllShopByMallCode = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_SHOP_BY_MALL_CODE,
    api: apis.getAllShopByMallCode,
    ...para,
  });
export const getAllShopCategory = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_SHOP_CATEGORY,
    api: apis.getAllShopCategory,
    ...para,
  });
export const getShop = (para) =>
  requestActionMaker({ cons: cons.GET_SHOP, api: apis.getShop, ...para });
export const getAllShop = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_SHOP,
    api: apis.getAllShop,
    ...para,
  });
export const postCreateShop = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_SHOP,
    api: apis.postCreateShop,
    ...para,
  });
// Gift Apis
export const getGiftList = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_LIST,
    api: apis.getGiftList,
    ...para,
  });
export const getAllFoodieShop = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_FOODIE_SHOP,
    api: apis.getAllFoodieShop,
    ...para,
  });

export const postUpdateGiftEnabled = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_GIFT_ENABLED,
    api: apis.postUpdateGiftEnabled,
    ...para,
  });
export const getGiftListForRedeem = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_LIST_FOR_REDEEM,
    api: apis.getGiftListForRedeem,
    ...para,
  });
export const getGiftListForRedeemRemarkList = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_LIST_FOR_REDEEM_REMARK_LIST,
    api: apis.getGiftListForRedeemRemarkList,
    ...para,
  });
export const postReserveGift = (para) =>
  requestActionMaker({
    cons: cons.POST_RESERVE_GIFT,
    api: apis.postReserveGift,
    ...para,
  });
export const getGiftForUser = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_FOR_USER,
    api: apis.getGiftForUser,
    ...para,
  });
export const postRedeemGift = (para) =>
  requestActionMaker({
    cons: cons.POST_REDEEM_GIFT,
    api: apis.postRedeemGift,
    ...para,
  });
export const getAllGiftCategory = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_GIFT_CATEGORY,
    api: apis.getAllGiftCategory,
    ...para,
  });
export const postCreateGift = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_GIFT,
    api: apis.postCreateGift,
    ...para,
  });
export const putUpdateGift = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_GIFT,
    api: apis.putUpdateGift,
    ...para,
  });
export const getGift = (para) =>
  requestActionMaker({ cons: cons.GET_GIFT, api: apis.getGift, ...para });
export const postAddGiftItem = (para) =>
  requestActionMaker({
    cons: cons.POST_ADD_GIFT_ITEM,
    api: apis.postAddGiftItem,
    ...para,
  });
export const postMinusGiftItem = (para) =>
  requestActionMaker({
    cons: cons.POST_MINUS_GIFT_ITEM,
    api: apis.postMinusGiftItem,
    ...para,
  });
export const postCreateSkuForGift = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_SKU_FOR_GIFT,
    api: apis.postCreateSkuForGift,
    ...para,
  });
export const getGiftStockDetail = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_STOCK_DETAIL,
    api: apis.getGiftStockDetail,
    ...para,
  });
export const getGiftItemById = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_ITEM_BY_ID,
    api: apis.getGiftItemById,
    ...para,
  });
export const putUpdateGiftSKU = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_GIFT_SKU,
    api: apis.putUpdateGiftSKU,
    ...para,
  });
export const deleteGift = (para) =>
  requestActionMaker({ cons: cons.DELETE_GIFT, api: apis.deleteGift, ...para });
export const deleteGiftSku = (para) =>
  requestActionMaker({
    cons: cons.DELETE_GIFT_SKU,
    api: apis.deleteGiftSku,
    ...para,
  });
export const getGiftCodeCSV = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_CODE_CSV,
    api: apis.getGiftCodeCSV,
    ...para,
  });
export const getGiftTnC = (para) =>
  requestActionMaker({
    cons: cons.GET_GIFT_TNC,
    api: apis.getGiftTnC,
    ...para,
  });

export const getPrivilegeList = (para) =>
  requestActionMaker({
    cons: cons.GET_PRIVILEGE_LIST,
    api: apis.getPrivilegeList,
    ...para,
  });
export const getPrivilege = (para) =>
  requestActionMaker({
    cons: cons.GET_PRIVILEGE,
    api: apis.getPrivilege,
    ...para,
  });

// Badge Apis
export const getAllBadge = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_BADGE,
    api: apis.getAllBadge,
    ...para,
  });
export const getBadgeList = (para) =>
  requestActionMaker({
    cons: cons.GET_BADGE_LIST,
    api: apis.getBadgeList,
    ...para,
  });
export const postCreateBadge = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BADGE,
    api: apis.postCreateBadge,
    ...para,
  });
export const putUpdateBadge = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_BADGE,
    api: apis.putUpdateBadge,
    ...para,
  });
export const getBadge = (para) =>
  requestActionMaker({ cons: cons.GET_BADGE, api: apis.getBadge, ...para });
export const postGiveSpecialInvitationBadgeToMember = (para) =>
  requestActionMaker({
    cons: cons.POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER,
    api: apis.postGiveSpecialInvitationBadgeToMember,
    ...para,
  });
export const deleteSpecialInvitationBadgeFromMember = (para) =>
  requestActionMaker({
    cons: cons.DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER,
    api: apis.deleteSpecialInvitationBadgeFromMember,
    ...para,
  });
export const getUsersThatHaveAchievedGivenBadge = (para) =>
  requestActionMaker({
    cons: cons.GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE,
    api: apis.getUsersThatHaveAchievedGivenBadge,
    ...para,
  });

//Member Tags
export const getAllMemberTags = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_MEMBER_TAGS,
    api: apis.getAllMemberTags,
    ...para,
  });
export const getMemberTagsList = (para) =>
  requestActionMaker({
    cons: cons.GET_MEMBER_TAGS_LIST,
    api: apis.getMemberTagsList,
    ...para,
  });
export const getMemberTagsListByMember = (para) =>
  requestActionMaker({
    cons: cons.GET_MEMBER_TAGS_LIST_BY_MEMBER,
    api: apis.getMemberTagsListByMember,
    ...para,
  });
export const getMemberTags = (para) =>
  requestActionMaker({
    cons: cons.GET_MEMBER_TAGS,
    api: apis.getMemberTags,
    ...para,
  });
export const postCreateMemberTags = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_MEMBER_TAGS,
    api: apis.postCreateMemberTags,
    ...para,
  });
export const putUpdateMemberTags = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MEMBER_TAGS,
    api: apis.putUpdateMemberTags,
    ...para,
  });
export const postUpdateMemberTagsTargetList = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_MEMBER_TAGS_TARGET_LIST,
    api: apis.postUpdateMemberTagsTargetList,
    ...para,
  });
export const postMemberTagsAddMember = (para) =>
  requestActionMaker({
    cons: cons.POST_MEMBER_TAGS_ADD_MEMBER,
    api: apis.postMemberTagsAddMember,
    ...para,
  });
export const postMemberTagsRemoveMember = (para) =>
  requestActionMaker({
    cons: cons.POST_MEMBER_TAGS_REMOVE_MEMBER,
    api: apis.postMemberTagsRemoveMember,
    ...para,
  });

// Crazy Ad
export const getCrazyAdList = (para) =>
  requestActionMaker({
    cons: cons.GET_CRAZY_AD_LIST,
    api: apis.getCrazyAdList,
    ...para,
  });
export const getCrazyAd = (para) =>
  requestActionMaker({
    cons: cons.GET_CRAZY_AD,
    api: apis.getCrazyAd,
    ...para,
  });
export const postCreateCrazyAd = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_CRAZY_AD,
    api: apis.postCreateCrazyAd,
    ...para,
  });
export const putUpdateCrazyAd = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_CRAZY_AD,
    api: apis.putUpdateCrazyAd,
    ...para,
  });
// Campaign
export const getCampaignList = (para) =>
  requestActionMaker({
    cons: cons.GET_CAMPAIGN_LIST,
    api: apis.getCampaignList,
    ...para,
  });
export const getCampaign = (para) =>
  requestActionMaker({
    cons: cons.GET_CAMPAIGN,
    api: apis.getCampaign,
    ...para,
  });
export const postCreateCampaign = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_CAMPAIGN,
    api: apis.postCreateCampaign,
    ...para,
  });
export const putUpdateCampaign = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_CAMPAIGN,
    api: apis.putUpdateCampaign,
    ...para,
  });
export const getAllCampaign = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_CAMPAIGN,
    api: apis.getAllCampaign,
    ...para,
  });
// Promotion
export const getPromotionList = (para) =>
  requestActionMaker({
    cons: cons.GET_PROMOTION_LIST,
    api: apis.getPromotionList,
    ...para,
  });
export const getPromotion = (para) =>
  requestActionMaker({
    cons: cons.GET_PROMOTION,
    api: apis.getPromotion,
    ...para,
  });
export const postCreatePromotion = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_PROMOTION,
    api: apis.postCreatePromotion,
    ...para,
  });
export const putUpdatePromotion = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_PROMOTION,
    api: apis.putUpdatePromotion,
    ...para,
  });
// Bonus Rule
export const getBonusRuleList = (para) =>
  requestActionMaker({
    cons: cons.GET_BONUS_RULE_LIST,
    api: apis.getBonusRuleList,
    ...para,
  });
export const getBonusRule = (para) =>
  requestActionMaker({
    cons: cons.GET_BONUS_RULE,
    api: apis.getBonusRule,
    ...para,
  });
export const postCreateBonusRule = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BONUS_RULE,
    api: apis.postCreateBonusRule,
    ...para,
  });
export const putUpdateBonusRule = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_BONUS_RULE,
    api: apis.putUpdateBonusRule,
    ...para,
  });
// User Push
export const getUserPushList = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_PUSH_LIST,
    api: apis.getUserPushList,
    ...para,
  });
export const getUserPush = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_PUSH,
    api: apis.getUserPush,
    ...para,
  });
export const postCreateUserPush = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_USER_PUSH,
    api: apis.postCreateUserPush,
    ...para,
  });
export const putUpdateUserPush = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_USER_PUSH,
    api: apis.putUpdateUserPush,
    ...para,
  });
export const putCancelUserPush = (para) =>
  requestActionMaker({
    cons: cons.PUT_CANCEL_USER_PUSH,
    api: apis.putCancelUserPush,
    ...para,
  });
// Merchant Push
export const getMerchantPushList = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_PUSH_LIST,
    api: apis.getMerchantPushList,
    ...para,
  });
export const getMerchantPush = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_PUSH,
    api: apis.getMerchantPush,
    ...para,
  });
export const postCreateMerchantPush = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_MERCHANT_PUSH,
    api: apis.postCreateMerchantPush,
    ...para,
  });
export const putUpdateMerchantPush = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MERCHANT_PUSH,
    api: apis.putUpdateMerchantPush,
    ...para,
  });
export const putCancelMerchantPush = (para) =>
  requestActionMaker({
    cons: cons.PUT_CANCEL_MERCHANT_PUSH,
    api: apis.putCancelMerchantPush,
    ...para,
  });
// FAQ
export const getAllOnlineEnquiry = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_ONLINE_ENQUIRY,
    api: apis.getAllOnlineEnquiry,
    ...para,
  });
export const getOnlineEnquiryList = (para) =>
  requestActionMaker({
    cons: cons.GET_ONLINE_ENQUIRY_LIST,
    api: apis.getOnlineEnquiryList,
    ...para,
  });
export const getOnlineEnquiry = (para) =>
  requestActionMaker({
    cons: cons.GET_ONLINE_ENQUIRY,
    api: apis.getOnlineEnquiry,
    ...para,
  });
export const putUpdateOnlineEnquiry = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_ONLINE_ENQUIRY,
    api: apis.putUpdateOnlineEnquiry,
    ...para,
  });
// Spending
export const getSpendingTransactionList = (para) =>
  requestActionMaker({
    cons: cons.GET_SPENDING_TRANSACTION_LIST,
    api: apis.getSpendingTransactionList,
    ...para,
  });
export const putApproveSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.PUT_APPROVE_SPENDING_TRANSACTION,
    api: apis.putApproveSpendingTransaction,
    ...para,
  });
export const putRejectSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.PUT_REJECT_SPENDING_TRANSACTION,
    api: apis.putRejectSpendingTransaction,
    ...para,
  });
export const getAllRejectReason = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_REJECT_REASON,
    api: apis.getAllRejectReason,
    ...para,
  });
export const postSubmitSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.POST_SUMBIT_SPENDING_TRANSACTION,
    api: apis.postSubmitSpendingTransaction,
    ...para,
  });
export const postUpdateSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_SPENDING_TRANSACTION,
    api: apis.postUpdateSpendingTransaction,
    ...para,
  });
export const postRecallSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.POST_RECALL_SPENDING_TRANSACTION,
    api: apis.postRecallSpendingTransaction,
    ...para,
  });
export const postEstimateSpendingTransaction = (para) =>
  requestActionMaker({
    cons: cons.POST_ESTIMATE_SPENDING_TRANSACTION,
    api: apis.postEstimateSpendingTransaction,
    ...para,
  });
// Point Transaction
export const getPointTransactionList = (para) =>
  requestActionMaker({
    cons: cons.GET_POINT_TRANSACRION_LIST,
    api: apis.getPointTransactionList,
    ...para,
  });
export const getPointTransactionBySpendingTransactionId = (para) =>
  requestActionMaker({
    cons: cons.GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID,
    api: apis.getPointTransactionBySpendingTransactionId,
    ...para,
  });
// Point Adjustment
export const getAllPointAdjustmentType = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_POINT_ADJUSTMENT_TYPE,
    api: apis.getAllPointAdjustmentType,
    ...para,
  });
export const postSubmitPointAdjustment = (para) =>
  requestActionMaker({
    cons: cons.POST_SUBMIT_POINT_ADJUSTMENT,
    api: apis.postSubmitPointAdjustment,
    ...para,
  });
export const postCreatePointAdjustmentType = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_POINT_ADJUSTMENT_TYPE,
    api: apis.postCreatePointAdjustmentType,
    ...para,
  });
// Image API
export const postUploadImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_IMAGE,
    api: apis.postUploadImage,
    ...para,
  });
export const postUploadProfileImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_PROFILE_IMAGE,
    api: apis.postUploadProfileImage,
    ...para,
  });
export const postUploadReceiptImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_RECEIPT_IMAGE,
    api: apis.postUploadReceiptImage,
    ...para,
  });
export const postUploadMallImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_MALL_IMAGE,
    api: apis.postUploadMallImage,
    ...para,
  });
export const postUploadGiftImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_GIFT_IMAGE,
    api: apis.postUploadGiftImage,
    ...para,
  });
export const postUploadSCouponImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_S_COUPON_IMAGE,
    api: apis.postUploadSCouponImage,
    ...para,
  });
export const postUploadAdvertisementImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_ADVERTISEMENT_IMAGE,
    api: apis.postUploadAdvertisementImage,
    ...para,
  });
export const postUploadBadgeImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_BADGE_IMAGE,
    api: apis.postUploadBadgeImage,
    ...para,
  });
export const postUploadBadgePromotionImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_BADGE_PROMOTION_IMAGE,
    api: apis.postUploadBadgePromotionImage,
    ...para,
  });
export const postHomeBannerImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_HOME_BANNER_IMAGE,
    api: apis.postUploadHomeBannerImage,
    ...para,
  });

// Merchant
export const getAllMerchant = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_MERCHANT,
    api: apis.getAllMerchant,
    ...para,
  });
export const getMerchantStock = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_STOCK,
    api: apis.getMerchantStock,
    ...para,
  });
export const getMerchantReport = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_REPORT,
    api: apis.getMerchantReport,
    ...para,
  });
export const getMerchantByShopId = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_BY_SHOP_ID,
    api: apis.getMerchantByShopId,
    ...para,
  });
export const postMerchantRegister = (para) =>
  requestActionMaker({
    cons: cons.POST_MERCHANT_REGISTER,
    api: apis.postMerchantRegister,
    ...para,
  });
export const putUpdateMerchant = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MERCHANT,
    api: apis.putUpdateMerchant,
    ...para,
  });
// User Management
export const putUpdateUserPassword = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_USER_PASSWORD,
    api: apis.putUpdateUserPassword,
    ...para,
  });
export const getUserList = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_LIST,
    api: apis.getUserList,
    ...para,
  });
export const putUpdateUserPasswordWithCurrentPassword = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD,
    api: apis.putUpdateUserPasswordWithCurrentPassword,
    ...para,
  });
export const postCreateUser = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_USER,
    api: apis.postCreateUser,
    ...para,
  });
export const putUpdateUserStatus = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_USER_STATUS,
    api: apis.putUpdateUserStatus,
    ...para,
  });
export const getMyAccountInfo = (para) =>
  requestActionMaker({
    cons: cons.GET_MY_ACCOUNT_INFO,
    api: apis.getMyAccountInfo,
    ...para,
  });
export const putUpdateAccountStatus = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_ACCOUNT_STATUS,
    api: apis.putUpdateAccountStatus,
    ...para,
  });
// Inbox Message
export const getInboxMessageScheduleList = (para) =>
  requestActionMaker({
    cons: cons.GET_INBOX_MESSAGE_SCHEDULE_LIST,
    api: apis.getInboxMessageScheduleList,
    ...para,
  });
export const getInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.GET_INBOX_MESSAGE_SCHEDULE,
    api: apis.getInboxMessageSchedule,
    ...para,
  });
export const postCreateInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_INBOX_MESSAGE_SCHEDULE,
    api: apis.postCreateInboxMessageSchedule,
    ...para,
  });
export const putUpdateInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_INBOX_MESSAGE_SCHEDULE,
    api: apis.putUpdateInboxMessageSchedule,
    ...para,
  });
export const putCancelInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.PUT_CANCEL_INBOX_MESSAGE_SCHEDULE,
    api: apis.putCancelInboxMessageSchedule,
    ...para,
  });
// Merchant Message
export const getMerchantInboxMessageList = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_INBOX_MESSAGE_LIST,
    api: apis.getMerchantInboxMessageList,
    ...para,
  });
export const getMerchantInboxMessageScheduleList = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST,
    api: apis.getMerchantInboxMessageScheduleList,
    ...para,
  });
export const getMerchantInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.GET_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    api: apis.getMerchantInboxMessageSchedule,
    ...para,
  });
export const postCreateMerchantInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    api: apis.postCreateMerchantInboxMessageSchedule,
    ...para,
  });
export const putUpdateMerchantInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    api: apis.putUpdateMerchantInboxMessageSchedule,
    ...para,
  });
export const putCancelMerchantInboxMessageSchedule = (para) =>
  requestActionMaker({
    cons: cons.PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE,
    api: apis.putCancelMerchantInboxMessageSchedule,
    ...para,
  });
// Parking
export const getAllCarPark = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_CAR_PARK,
    api: apis.getAllCarPark,
    ...para,
  });
export const getCarPark = (para) =>
  requestActionMaker({
    cons: cons.GET_CAR_PARK,
    api: apis.getCarPark,
    ...para,
  });
export const putUpdateCarPak = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_CAR_PARK,
    api: apis.putUpdateCarPark,
    ...para,
  });
// Check In
export const getCheckInEventList = (para) =>
  requestActionMaker({
    cons: cons.GET_CHECK_IN_EVENT_LIST,
    api: apis.getCheckInEventList,
    ...para,
  });
export const postCreateCheckInEvent = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_CHECK_IN_EVENT,
    api: apis.postCreateCheckInEvent,
    ...para,
  });
export const getCheckInEvent = (para) =>
  requestActionMaker({
    cons: cons.GET_CHECK_IN_EVENT,
    api: apis.getCheckInEvent,
    ...para,
  });
export const putUpdateCheckInEvent = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_CHECK_IN_EVENT,
    api: apis.putUpdateCheckInEvent,
    ...para,
  });
// Custom Referral Point
export const getCustomReferralPointList = (para) =>
  requestActionMaker({
    cons: cons.GET_CUSTOM_REFERRAL_POINT_LIST,
    api: apis.getCustomReferralPointList,
    ...para,
  });
export const postCreateCustomReferralPoint = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_CUSTOM_REFERRAL_POINT,
    api: apis.postCreateCustomReferralPoint,
    ...para,
  });
export const getCustomReferralPoint = (para) =>
  requestActionMaker({
    cons: cons.GET_CUSTOM_REFERRAL_POINT,
    api: apis.getCustomReferralPoint,
    ...para,
  });
export const putUpdateCustomReferralPoint = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_CUSTOM_REFERRAL_POINT,
    api: apis.putUpdateCustomReferralPoint,
    ...para,
  });
export const deleteCustomReferralPoint = (para) =>
  requestActionMaker({
    cons: cons.DELETE_CUSTOM_REFERRAL_POINT,
    api: apis.deleteCustomReferralPoint,
    ...para,
  });
// SDollar
export const getSDollarShopList = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_SHOP_LIST,
    api: apis.getSDollarShopList,
    ...para,
  });
export const getSDollarShop = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_SHOP,
    api: apis.getSDollarShop,
    ...para,
  });
export const putConfirmPendingBalance = (para) =>
  requestActionMaker({
    cons: cons.PUT_CONFIRM_PENDING_BALANCE,
    api: apis.putConfirmPendingBalance,
    ...para,
  });

export const getAllShopRemarkType = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_SHOP_REMARK_TYPE,
    api: apis.getAllShopRemarkType,
    ...para,
  });
export const postCreateShopRemarkType = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_SHOP_REMARK_TYPE,
    api: apis.postCreateShopRemarkType,
    ...para,
  });
export const postCreateShopRemark = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_SHOP_REMARK,
    api: apis.postCreateShopRemark,
    ...para,
  });
export const getShopRemarkList = (para) =>
  requestActionMaker({
    cons: cons.GET_SHOP_REMARK_LIST,
    api: apis.getShopRemarkList,
    ...para,
  });

export const getAllBalanceAdjustReason = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_BALANCE_ADJUST_REASON,
    api: apis.getAllBalanceAdjustReason,
    ...para,
  });
export const postCreateBalanceAdjustReason = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BALANCE_ADJUST_REASON,
    api: apis.postCreateBalanceAdjustReason,
    ...para,
  });
export const postCreateBalanceAdjustment = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BALANCE_ADJUSTMENT,
    api: apis.postCreateBalanceAdjustment,
    ...para,
  });

export const getTenantBalancePeriod = (para) =>
  requestActionMaker({
    cons: cons.GET_TENANT_BALANCE_PERIOD,
    api: apis.getTenantBalancePeriod,
    ...para,
  });
export const getTenantBalanceRecordList = (para) =>
  requestActionMaker({
    cons: cons.GET_TENANT_BALANCE_RECORD_LIST,
    api: apis.getTenantBalanceRecordList,
    ...para,
  });

export const postResendTenantEmail = (para) =>
  requestActionMaker({
    cons: cons.POST_RESEND_TENANT_EMAIL,
    api: apis.postResendTenantEmail,
    ...para,
  });

export const getSDollarGlobalRate = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_GLOBAL_RATE,
    api: apis.getSDollarGlobalRate,
    ...para,
  });
export const postUpdateSDollarGlobalRate = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_SDOLLAR_GLOBAL_RATE,
    api: apis.postUpdateSDollarGlobalRate,
    ...para,
  });
export const getAllTenantBalanceRecordList = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_TENANT_BALANCE_RECORD_LIST,
    api: apis.getAllTenantBalanceRecordList,
    ...para,
  });
export const putIssueCheque = (para) =>
  requestActionMaker({
    cons: cons.PUT_ISSUE_CHEQUE,
    api: apis.putIssueCheque,
    ...para,
  });

// SDollar Coupon
export const getSDollarCouponList = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_COUPON_LIST,
    api: apis.getSDollarCouponList,
    ...para,
  });
export const getSDollarCoupon = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_COUPON,
    api: apis.getSDollarCoupon,
    ...para,
  });
export const postCreateSDollarCoupon = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_SDOLLAR_COUPON,
    api: apis.postCreateSDollarCoupon,
    ...para,
  });
export const putUpdateSDollarCoupon = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_SDOLLAR_COUPON,
    api: apis.putUpdateSDollarCoupon,
    ...para,
  });

export const getSDollarShortTnC = (para) =>
  requestActionMaker({
    cons: cons.GET_SDOLLAR_SHORT_TNC,
    api: apis.getSDollarShortTnC,
    ...para,
  });
export const postUpdateSDollarShortTnC = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_SDOLLAR_SHORT_TNC,
    api: apis.postUpdateSDollarShortTnC,
    ...para,
  });

export const getSCouponTnC = (para) =>
  requestActionMaker({
    cons: cons.GET_S_COUPON_TNC,
    api: apis.getSCouponTnC,
    ...para,
  });
export const postUpdateSCouponTnC = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_S_COUPON_TNC,
    api: apis.postUpdateSCouponTnC,
    ...para,
  });

export const getAllTenantShop = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_TENANT_SHOP,
    api: apis.getAllTenantShop,
    ...para,
  });
export const getAllFoodieTenantShop = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_FOODIE_TENANT_SHOP,
    api: apis.getAllFoodieTenantShop,
    ...para,
  });

// S-Coupon
export const getSCoupon = (para) =>
  requestActionMaker({
    cons: cons.GET_S_COUPON,
    api: apis.getSCoupon,
    ...para,
  });
export const postCreateSCoupon = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_S_COUPON,
    api: apis.postCreateSCoupon,
    ...para,
  });
export const putUpdateSCoupon = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_S_COUPON,
    api: apis.putUpdateSCoupon,
    ...para,
  });
export const deleteSCoupon = (para) =>
  requestActionMaker({
    cons: cons.DELETE_S_COUPON,
    api: apis.deleteSCoupon,
    ...para,
  });
export const postAddSCouponStock = (para) =>
  requestActionMaker({
    cons: cons.POST_ADD_S_COUPON_STOCK,
    api: apis.postAddSCouponStock,
    ...para,
  });
export const postMinusSCouponStock = (para) =>
  requestActionMaker({
    cons: cons.POST_MINUS_S_COUPON_STOCK,
    api: apis.postMinusSCouponStock,
    ...para,
  });
export const postUpdateSCouponEnabled = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_S_COUPON_ENABLED,
    api: apis.postUpdateSCouponEnabled,
    ...para,
  });
export const getSCouponList = (para) =>
  requestActionMaker({
    cons: cons.GET_S_COUPON_LIST,
    api: apis.getSCouponList,
    ...para,
  });
export const getSCouponStockDetail = (para) =>
  requestActionMaker({
    cons: cons.GET_S_COUPON_STOCK_DETAIL,
    api: apis.getSCouponStockDetail,
    ...para,
  });
export const getUserReserveableSCoupon = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_RESERVEABLE_S_COUPON,
    api: apis.getUserReserveableSCoupon,
    ...para,
  });
export const getUserReserveableSCouponRemarkList = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_RESERVEABLE_S_COUPON_REMARK_LIST,
    api: apis.getUserReserveableSCouponRemarkList,
    ...para,
  });
export const getUserSCoupon = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_S_COUPON,
    api: apis.getUserSCoupon,
    ...para,
  });
export const postReserveSCoupon = (para) =>
  requestActionMaker({
    cons: cons.POST_RESERVE_S_COUPON,
    api: apis.postReserveSCoupon,
    ...para,
  });
export const getCouponCodeCSV = (para) =>
  requestActionMaker({
    cons: cons.GET_COUPON_CODE_CSV,
    api: apis.getCouponCodeCSV,
    ...para,
  });

export const putAddQRCodeAmount = (para) =>
  requestActionMaker({
    cons: cons.PUT_ADD_QRCODE_AMOUNT,
    api: apis.putAddQRCodeAmount,
    ...para,
  });
export const getTenantStatementPDF = (para) =>
  requestActionMaker({
    cons: cons.GET_TENANT_STATMENT_PDF,
    api: apis.getTenantStatementPDF,
    ...para,
  });

// Badge Promotion
export const getAllSchedule = (para) =>
  requestActionMaker({
    cons: cons.GET_ALL_SCHEDULE,
    api: apis.getAllSchedule,
    ...para,
  });
export const postCreateBadgePromotion = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BADGE_PROMOTION,
    api: apis.postCreateBadgePromotion,
    ...para,
  });
export const putUpdateBadgePromotion = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_BADGE_PROMOTION,
    api: apis.putUpdateBadgePromotion,
    ...para,
  });
export const deleteBadgePromotion = (para) =>
  requestActionMaker({
    cons: cons.DELETE_BADGE_PROMOTION,
    api: apis.deleteBadgePromotion,
    ...para,
  });
export const getBadgePromotionList = (para) =>
  requestActionMaker({
    cons: cons.GET_BADGE_PROMOTION_LIST,
    api: apis.getBadgePromotionList,
    ...para,
  });
export const getBadgePromotionDetail = (para) =>
  requestActionMaker({
    cons: cons.GET_BADGE_PROMOTION_DETAIL,
    api: apis.getBadgePromotionDetail,
    ...para,
  });

export const getUserFreezeRecord = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_FREEZE_RECORD,
    api: apis.getUserFreezeRecord,
    ...para,
  });
export const getUserTerminateRecord = (para) =>
  requestActionMaker({
    cons: cons.GET_USER_TERMINATE_RECORD,
    api: apis.getUserTerminateRecord,
    ...para,
  });

export const getSpendingLimit = (para) =>
  requestActionMaker({
    cons: cons.GET_SPENDING_LIMIT,
    api: apis.getSpendingLimit,
    ...para,
  });
export const postChangeSpendingLimit = (para) =>
  requestActionMaker({
    cons: cons.POST_CHANGE_SPENDING_LIMIT,
    api: apis.postChangeSpendingLimit,
    ...para,
  });

// Third Party Integrator
export const getIntegratorList = (para) =>
  requestActionMaker({
    cons: cons.GET_INTEGRATOR_LIST,
    api: apis.getIntegratorList,
    ...para,
  });
export const getIntegrator = (para) =>
  requestActionMaker({
    cons: cons.GET_INTEGRATOR,
    api: apis.getIntegrator,
    ...para,
  });
export const postCreateIntegrator = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_INTEGRATOR,
    api: apis.postCreateIntegrator,
    ...para,
  });
export const putUpdateIntegrator = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_INTEGRATOR,
    api: apis.putUpdateIntegrator,
    ...para,
  });
export const putUpdateIntegratorPassword = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_INTEGRATOR_PASSWORD,
    api: apis.putUpdateIntegratorPassword,
    ...para,
  });

// Mall
export const getMallList = (para) =>
  requestActionMaker({
    cons: cons.GET_MALL_LIST,
    api: apis.getMallList,
    ...para,
  });
export const getMall = (para) =>
  requestActionMaker({ cons: cons.GET_MALL, api: apis.getMall, ...para });
export const putUpdateMall = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_MALL,
    api: apis.putUpdateMall,
    ...para,
  });

// Contactlass parking
export const getLicensePlatesRecordList = (para) =>
  requestActionMaker({
    cons: cons.GET_LICENSE_PLATE_RECORD_LIST,
    api: apis.getLicensePlatesRecordList,
    ...para,
  });
export const getCreditCardListByMemberId = (para) =>
  requestActionMaker({
    cons: cons.GET_CREDIT_CARD_LIST_BY_MEMBER_ID,
    api: apis.getCreditCardListByMemberId,
    ...para,
  });
export const getLicensePlatesRecordWithParkingHistory = (para) =>
  requestActionMaker({
    cons: cons.GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY,
    api: apis.getLicensePlatesRecordWithParkingHistory,
    ...para,
  });
export const getLicensePlatesRecordWithParkingHistoryDetail = (para) =>
  requestActionMaker({
    cons: cons.GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL,
    api: apis.getLicensePlatesRecordWithParkingHistoryDetail,
    ...para,
  });
//new added
export const getParkHistoryWithTransaction = (para) =>
  requestActionMaker({
    cons: cons.GET_PARK_HISTORY_WITH_TRANSACTION,
    api: apis.getParkHistoryWithTransaction,
    ...para,
  });

export const putExitCarpark = (para) =>
  requestActionMaker({
    cons: cons.PUT_EXIT_CARPARK,
    api: apis.putExitCarpark,
    ...para,
  });
export const putSettleOutstandingPayment = (para) =>
  requestActionMaker({
    cons: cons.PUT_SETTLE_OUTSTANDING_PAYMENT,
    api: apis.putSettleOutstandingPayment,
    ...para,
  });

// Contactlass parking(simulation)
export const putVerifyLicensePlate = (para) =>
  requestActionMaker({
    cons: cons.PUT_VERIFY_LICENSE_PLATE,
    api: apis.putVerifyLicensePlate,
    ...para,
  });
export const postEnterCarPark = (para) =>
  requestActionMaker({
    cons: cons.POST_ENTER_CAR_PARK,
    api: apis.postEnterCarPark,
    ...para,
  });
export const putExitCarParkSystem = (para) =>
  requestActionMaker({
    cons: cons.PUT_EXIT_CAR_PARK_SYSTEM,
    api: apis.putExitCarParkSystem,
    ...para,
  });
export const putUpdateParkingTime = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_PARKING_TIME,
    api: apis.putUpdateParkingTime,
    ...para,
  });
export const postPayByOctopus = (para) =>
  requestActionMaker({
    cons: cons.POST_PAY_BY_OCTOPUS,
    api: apis.postPayByOctopus,
    ...para,
  });
export const putInvalidateCreditCard = (para) =>
  requestActionMaker({
    cons: cons.PUT_INVALIDATE_CREDIT_CARD,
    api: apis.putInvalidateCreditCard,
    ...para,
  });
export const getCarparkList = (para) =>
  requestActionMaker({
    cons: cons.GET_CARPARK_LIST,
    api: apis.getCarparkList,
    ...para,
  });

// Banner
export const getBannerList = (para) =>
  requestActionMaker({
    cons: cons.GET_BANNER_LIST,
    api: apis.getBannerList,
    ...para,
  });
export const getBannerById = (para) =>
  requestActionMaker({
    cons: cons.GET_BANNER_BY_ID,
    api: apis.getBannerById,
    ...para,
  });
export const postCreateBanner = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_BANNER,
    api: apis.postCreateBanner,
    ...para,
  });
export const putUpdateBanner = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_BANNER,
    api: apis.putUpdateBanner,
    ...para,
  });
export const putUpdateBannerEnabled = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_BANNER_ENABLED,
    api: apis.putUpdateBannerEnabled,
    ...para,
  });
export const getBannerRedirectList = (para) =>
  requestActionMaker({
    cons: cons.GET_BANNER_REDIRECT_LIST,
    api: apis.getBannerRedirectList,
    ...para,
  });

// Banner Category
export const getBannerCategoryList = (para) =>
  requestActionMaker({
    cons: cons.GET_BANNER_CATEGORY_LIST,
    api: apis.getBannerCategoryList,
    ...para,
  });

// Banner Area
export const getBannerAreaList = (para) =>
  requestActionMaker({
    cons: cons.GET_BANNER_AREA_LIST,
    api: apis.getBannerAreaList,
    ...para,
  });

// Dynamic Banner List
export const getDynamicBannerList = (para) =>
  requestActionMaker({
    cons: cons.GET_DYNAMIC_BANNER_LIST,
    api: apis.getDynamicBannerList,
    ...para,
  });
export const getDynamicBannerListById = (para) =>
  requestActionMaker({
    cons: cons.GET_DYNAMIC_BANNER_LIST_BY_ID,
    api: apis.getDynamicBannerListById,
    ...para,
  });
export const putUpdateDynamicBannerList = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_DYNAMIC_BANNER_LIST,
    api: apis.putUpdateDynamicBannerList,
    ...para,
  });

// Homepage banner
export const getHomepageBannerList = (para) =>
  requestActionMaker({
    cons: cons.GET_HOMEPAGE_BANNER_LIST,
    api: apis.getHomepageBannerList,
    ...para,
  });
export const getHomepageBannerById = (para) =>
  requestActionMaker({
    cons: cons.GET_HOMEPAGE_BANNER_BY_ID,
    api: apis.getHomepageBannerById,
    ...para,
  });
export const postCreateHomepageBanner = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_HOMEPAGE_BANNER,
    api: apis.postCreateHomepageBanner,
    ...para,
  });
export const putUpdateHomepageBanner = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_HOMEPAGE_BANNER,
    api: apis.putUpdateHomepageBanner,
    ...para,
  });
export const putDisableHomepageBanner = (para) =>
  requestActionMaker({
    cons: cons.PUT_DISABLE_HOMEPAGE_BANNER,
    api: apis.putDisableHomepageBanner,
    ...para,
  });
export const putEnableHomepageBanner = (para) =>
  requestActionMaker({
    cons: cons.PUT_ENABLE_HOMEPAGE_BANNER,
    api: apis.putEnableHomepageBanner,
    ...para,
  });
export const getHomepageBannerRedirectList = (para) =>
  requestActionMaker({
    cons: cons.GET_HOMEPAGE_REDIRECT_LIST,
    api: apis.getHomepageBannerRedirectList,
    ...para,
  });

// Hot deal
export const getHotDealList = (para) =>
  requestActionMaker({
    cons: cons.GET_HOT_DEAL_LIST,
    api: apis.getHotDealList,
    ...para,
  });
export const getHotDealFullList = (para) =>
  requestActionMaker({
    cons: cons.GET_HOT_DEAL_FULL_LIST,
    api: apis.getHotDealFullList,
    ...para,
  });
export const getHotDeal = (para) =>
  requestActionMaker({
    cons: cons.GET_HOT_DEAL,
    api: apis.getHotDealDetail,
    ...para,
  });
export const createHotDeal = (para) =>
  requestActionMaker({
    cons: cons.POST_HOT_DEAL,
    api: apis.createHotDeal,
    ...para,
  });
export const deleteHotDeal = (para) =>
  requestActionMaker({
    cons: cons.DELETE_HOT_DEAL,
    api: apis.deleteHotDeal,
    ...para,
  });
export const updateHotDeal = (para) =>
  requestActionMaker({
    cons: cons.UPDATE_HOT_DEAL,
    api: apis.updateHotDeal,
    ...para,
  });
export const getFoodieGiftList = (para) =>
  requestActionMaker({
    cons: cons.GET_FOODIE_GIFT_LIST,
    api: apis.getFoodieGiftList,
    ...para,
  });
export const getFoodieCouponList = (para) =>
  requestActionMaker({
    cons: cons.GET_FOODIE_COUPON_LIST,
    api: apis.getFoodieCouponList,
    ...para,
  });
export const postFoodieImage = (para) =>
  requestActionMaker({
    cons: cons.POST_UPLOAD_FOODIE_IMAGE,
    api: apis.postUploadFoodieImage,
    ...para,
  });

// Sino Club Member
export const getSinoClubMemberList = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_CLUB_MEMBER_LIST,
    api: apis.getSinoClubMemberList,
    ...para,
  });

export const getSinoUpgradeList = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_UPGRADE_LIST,
    api: apis.getSinoUpgradeList,
    ...para,
  });
export const getSinoParkingList = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_PARKING_LIST,
    api: apis.getSinoParkingList,
    ...para,
  });

// Estate
export const getSinoEstateList = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_ESTATE_LIST,
    api: apis.getSinoEstateList,
    ...para,
  });
export const getNonSinoEstateList = (para) =>
  requestActionMaker({
    cons: cons.GET_NON_SINO_ESTATE_LIST,
    api: apis.getNonSinoEstateList,
    ...para,
  });
export const getEstate = (para) =>
  requestActionMaker({ cons: cons.GET_ESTATE, api: apis.getEstate, ...para });
export const postCreateEstate = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_ESTATE,
    api: apis.postCreateEstate,
    ...para,
  });
export const postUpdateEstate = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_ESTATE,
    api: apis.postUpdateEstate,
    ...para,
  });
export const getSinoDocumentList = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_DOC_LIST,
    api: apis.getSinoDocumentList,
    ...para,
  });
export const getSinoClubMember = (para) =>
  requestActionMaker({
    cons: cons.GET_SINO_CLUB_MEMBER,
    api: apis.getMemberProfile,
    ...para,
  });

// Activity
export const getActivityList = (para) =>
  requestActionMaker({
    cons: cons.GET_ACTIVITY_LIST,
    api: apis.getActivityList,
    ...para,
  });
export const getActivity = (para) =>
  requestActionMaker({
    cons: cons.GET_ACTIVITY,
    api: apis.getActivity,
    ...para,
  });
export const postCreateActivity = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_ACTIVITY,
    api: apis.postCreateActivity,
    ...para,
  });
export const putUpdateActivity = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_ACTIVITY,
    api: apis.putUpdateActivity,
    ...para,
  });
export const putUpdateActivityEnabled = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_ACTIVITY_ENABLED,
    api: apis.putUpdateActivityEnabled,
    ...para,
  });
export const putAllocateActivityQuota = (para) =>
  requestActionMaker({
    cons: cons.PUT_ALLOCATE_ACTIVITY_QUOTA,
    api: apis.putAllocateActivityQuota,
    ...para,
  });
export const getActivityUsedRewardList = (para) =>
  requestActionMaker({
    cons: cons.GET_ACTIVITY_USED_REWARD_LIST,
    api: apis.getActivityUsedRewardList,
    ...para,
  });

// Activity Segment
export const getActivitySegments = (para) =>
  requestActionMaker({
    cons: cons.GET_ACTIVITY_SEGMENTS,
    api: apis.getActivitySegments,
    ...para,
  });
export const postCreateActivitySegment = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_ACTIVITY_SEGMENT,
    api: apis.postCreateActivitySegment,
    ...para,
  });
export const putUpdateActivitySegment = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_ACTIVITY_SEGMENT,
    api: apis.putUpdateActivitySegment,
    ...para,
  });
export const deleteActivitySegment = (para) =>
  requestActionMaker({
    cons: cons.DELETE_ACTIVITY_SEGMENT,
    api: apis.deleteActivitySegment,
    ...para,
  });

// Sino Club P Member Referral Code
export const getPMemberReferralCodeList = (para) =>
  requestActionMaker({
    cons: cons.GET_P_MEMBER_REFERRAL_CODE_LIST,
    api: apis.getPMemberReferralCodeList,
    ...para,
  });
export const getPMemberReferralCode = (para) =>
  requestActionMaker({
    cons: cons.GET_P_MEMBER_REFERRAL_CODE,
    api: apis.getPMemberReferralCode,
    ...para,
  });
export const postCreatePMemberReferralCode = (para) =>
  requestActionMaker({
    cons: cons.POST_CREATE_P_MEMBER_REFERRAL_CODE,
    api: apis.postCreatePMemberReferralCode,
    ...para,
  });
export const putUpdatePMemberReferralCode = (para) =>
  requestActionMaker({
    cons: cons.PUT_UPDATE_P_MEMBER_REFERRAL_CODE,
    api: apis.putUpdatePMemberReferralCode,
    ...para,
  });
export const putUpdatePMemberReferralCodeEnabled = (para) =>
  requestActionMaker({
    cons: cons.POST_UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED,
    api: apis.putUpdatePMemberReferralCodeEnabled,
    ...para,
  });
export const deletePMemberReferralCode = (para) =>
  requestActionMaker({
    cons: cons.DELETE_P_MEMBER_REFERRAL_CODE,
    api: apis.deletePMemberReferralCode,
    ...para,
  });
