import clone from 'clone';
import defaultTheme from './themedefault';

const theme = clone(defaultTheme);
theme.palette.primary = ['#e50076'];
// theme.palette.secondary = ['#fcaf17'];
theme.buttonColor = '#f37169';
theme.backgroundColor = '#f37169';
theme.textColor = '#ffffff';
export default theme;
