import { uiConstantsMaker } from "../../helpers/reduxHelper";

export const TOGGLE_IMAGE_LIGHTBOX = uiConstantsMaker("TOGGLE_IMAGE_LIGHTBOX");
export const TOGGLE_CREATE_POINT_ADJUSTMENT_TYPE_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_POINT_ADJUSTMENT_TYPE_MODAL"
);
export const TOGGLE_RESERVE_GIFT_MODAL = uiConstantsMaker(
  "TOGGLE_RESERVE_GIFT_MODAL"
);
export const TOGGLE_SELECT_POINT_REGISTRATION_OPTION_MODAL = uiConstantsMaker(
  "TOGGLE_SELECT_POINT_REGISTRATION_OPTION_MODAL"
);
export const TOGGLE_CREATE_USER_REMARK_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_USER_REMARK_MODAL"
);
export const TOGGLE_CREATE_USER_REMARK_TYPE_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_USER_REMARK_TYPE_MODAL"
);
export const TOGGLE_REJECT_TRANSACTION_MODAL = uiConstantsMaker(
  "TOGGLE_REJECT_TRANSACTION_MODAL"
);
export const TOGGLE_CREATE_OR_UPDATE_GIT_SKU_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_OR_UPDATE_GIT_SKU_MODAL"
);
export const TOGGLE_UPDATE_GIT_SKU_MODAL = uiConstantsMaker(
  "TOGGLE_UPDATE_GIT_SKU_MODAL"
);
export const TOGGLE_UPDATE_S_COUPON_STOCK_MODAL = uiConstantsMaker(
  "TOGGLE_UPDATE_S_COUPON_STOCK_MODAL"
);
export const TOGGLE_CHANGE_PASSWORD_MODAL = uiConstantsMaker(
  "TOGGLE_CHANGE_PASSWORD_MODAL"
);
export const TOGGLE_UPDATE_TRANSACTION_MODAL = uiConstantsMaker(
  "TOGGLE_UPDATE_TRANSACTION_MODAL"
);
export const TOGGLE_TERMINATE_MEMBER_MODAL = uiConstantsMaker(
  "TOGGLE_TERMINATE_MEMBER_MODAL"
);
export const TOGGLE_CHANGE_PHONE_MODAL = uiConstantsMaker(
  "TOGGLE_CHANGE_PHONE_MODAL"
);
export const TOGGLE_FREEZE_MEMBER_MODAL = uiConstantsMaker(
  "TOGGLE_FREEZE_MEMBER_MODAL"
);
export const TOGGLE_FREEZE_MEMBER_REMARK_MODAL = uiConstantsMaker(
  "TOGGLE_FREEZE_MEMBER_REMARK_MODAL"
);
export const TOGGLE_UNFREEZE_MEMBER_MODAL = uiConstantsMaker(
  "TOGGLE_UNFREEZE_MEMBER_MODAL"
);
export const TOGGLE_GIVE_SPECIAL_INVITATION_BADGE_MODAL = uiConstantsMaker(
  "TOGGLE_GIVE_SPECIAL_INVITATION_BADGE_MODAL"
);
export const TOGGLE_REMOVE_SPECIAL_INVITATION_BADGE_MODAL = uiConstantsMaker(
  "TOGGLE_REMOVE_SPECIAL_INVITATION_BADGE_MODAL"
);
export const TOGGLE_DIRECT_COLLECT_GIFT_MODAL = uiConstantsMaker(
  "TOGGLE_DIRECT_COLLECT_GIFT_MODAL"
);
export const TOGGLE_REMOVE_PHONE_NUMBER_MODAL = uiConstantsMaker(
  "TOGGLE_REMOVE_PHONE_NUMBER_MODAL"
);
export const TOGGLE_GIFT_STOCK_DETAIL_MODAL = uiConstantsMaker(
  "TOGGLE_GIFT_STOCK_DETAIL_MODAL"
);
export const TOGGLE_GIFT_ALIPAY_COUPON_CODE_MODAL = uiConstantsMaker(
  "TOGGLE_GIFT_ALIPAY_COUPON_CODE_MODAL"
);
export const TOGGLE_S_COUPON_STOCK_DETAIL_MODAL = uiConstantsMaker(
  "TOGGLE_S_COUPON_STOCK_DETAIL_MODAL"
);
export const TOGGLE_IMPORT_SHOPS_MODAL = uiConstantsMaker(
  "TOGGLE_IMPORT_SHOPS_MODAL"
);
export const TOGGLE_EDIT_GENERAL_RATE_MODAL = uiConstantsMaker(
  "TOGGLE_EDIT_GENERAL_RATE_MODAL"
);
export const TOGGLE_EDIT_DAILY_POINT_LIMIT_MODAL = uiConstantsMaker(
  "TOGGLE_EDIT_DAILY_POINT_LIMIT_MODAL"
);

export const TOGGLE_EDIT_SHORT_TNC_MODAL = uiConstantsMaker(
  "TOGGLE_EDIT_SHORT_TNC_MODAL"
);
export const TOGGLE_EDIT_COUPON_TNC_MODAL = uiConstantsMaker(
  "TOGGLE_EDIT_COUPON_TNC_MODAL"
);

export const TOGGLE_CREATE_SHOP_REMARK_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_SHOP_REMARK_MODAL"
);
export const TOGGLE_CREATE_SHOP_REMARK_TYPE_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_SHOP_REMARK_TYPE_MODAL"
);
export const TOGGLE_CREATE_BALANCE_ADJUST_REASON_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_BALANCE_ADJUST_REASON_MODAL"
);
export const TOGGLE_CREATE_SAME_REWARD_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_SAME_REWARD_MODAL"
);
export const TOGGLE_CREATE_DIFFERENT_REWARD_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_DIFFERENT_REWARD_MODAL"
);
export const TOGGLE_RESERVE_COUPON_MODAL = uiConstantsMaker(
  "TOGGLE_RESERVE_COUPON_MODAL"
);
export const TOGGLE_CHECK_IN_QRCODE_DETAIL_MODAL = uiConstantsMaker(
  "TOGGLE_CHECK_IN_QRCODE_DETAIL_MODAL"
);
export const TOGGLE_ADD_EXTRA_QRCODE_MODAL = uiConstantsMaker(
  "TOGGLE_ADD_EXTRA_QRCODE_MODAL"
);

export const TOGGLE_CHANGE_THIRD_PARTY_PASSWORD_MODAL = uiConstantsMaker(
  "TOGGLE_CHANGE_THIRD_PARTY_PASSWORD_MODAL"
);
export const TOGGLE_CREATE_OR_UPDATE_ICON_MODAL = uiConstantsMaker(
  "TOGGLE_CREATE_OR_UPDATE_ICON_MODAL"
);

export const TOGGLE_EXTENT_POINT_EXPIRY_DATE_MODAL = uiConstantsMaker(
  "TOGGLE_EXTENT_POINT_EXPIRY_DATE_MODAL"
);

export const TOGGLE_LICENSE_PLATE_MODAL = uiConstantsMaker(
  "TOGGLE_LICENSE_PLATE_MODAL"
);
export const TOGGLE_BUNDLED_CREDIT_CARD_MODAL = uiConstantsMaker(
  "TOGGLE_BUNDLED_CREDIT_CARD_MODAL"
);

export const TOGGLE_UPDATE_SINO_CLUB_REMARK_MODAL = uiConstantsMaker(
  "TOGGLE_UPDATE_SINO_CLUB_REMARK_MODAL"
);

export const TOGGLE_PRIVILEGE_PROMOTION_CODE_MODAL = uiConstantsMaker(
  "TOGGLE_PRIVILEGE_PROMOTION_CODE_MODAL"
);
export const TOGGLE_IMPORT_MEMBER_TAGS_MODAL = uiConstantsMaker(
  "TOGGLE_IMPORT_MEMBER_TAGS_MODAL"
);
export const TOGGLE_REMOVE_MEMBER_TAGS_MODAL = uiConstantsMaker(
  "TOGGLE_REMOVE_MEMBER_TAGS_MODAL"
);
export const TOGGLE_ADD_MEMBER_TAG_MODAL = uiConstantsMaker(
  "TOGGLE_ADD_MEMBER_TAG_MODAL"
);

export const TOGGLE_EDIT_GIFT_TNC_MODAL = uiConstantsMaker(
  "TOGGLE_EDIT_GIFT_TNC_MODAL"
);
