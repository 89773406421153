import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import rootSaga from './sagas';

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware, logger];


function composeFn() {
  if (process.env.REACT_APP_ENABLE_REDUX_DEV_TOOL === 'true') {
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
  return compose(applyMiddleware(...middlewares));
}

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  composeFn(),
);
sagaMiddleware.run(rootSaga);
export { store, history };
