import { language } from '../../settings';

import englishLang from '../../image/flag/uk.svg';
import chineseLang from '../../image/flag/china.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'chinese-traditional',
      locale: 'zhHK',
      text: '繁',
      icon: chineseLang,
    },
    {
      languageId: 'chinese-simplified',
      locale: 'zhCN',
      text: '简',
      icon: chineseLang,
    },
    {
      languageId: 'english',
      locale: 'en',
      text: 'EN',
      icon: englishLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((option) => {
    if (option.languageId === lang) {
      selecetedLanguage = option;
    }
  });
  return selecetedLanguage;
}
export default config;
