import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Popover from '../../../components/uielements/popover';
import IntlMessages from '../../../components/utility/intlMessages';
import userpic from '../../../image/user.png';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { clearAuthInfo } from '../../../helpers/authHelper';
import appActions from '../../../redux/app/actions';
import { getLogout } from '../../../redux/request/apis';

const {
  changeCurrent,
} = appActions;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible });
  }

  handleLogout = async () => {
    const { history } = this.props;
    try {
      const data = {
        // memberId: members[i].memberId,
        // badgeId,
      };
      const response = await getLogout({ data });
      clearAuthInfo();
      history.push('/login');
      this.props.changeCurrent(['member']);
    } catch (error) {
      clearAuthInfo();
      history.push('/login');
      this.props.changeCurrent(['member']);
    }
  };

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link className="isoDropdownLink" to="changePassword">
          <IntlMessages id="sidebar.changePassword" />
        </Link>
        <a className="isoDropdownLink" onClick={this.handleLogout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default withRouter(
  connect(
    null,
    {
      changeCurrent,
    },
  )(TopbarUser),
);
