import { Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../components/uielements/button';
import IntlMessages from '../../components/utility/intlMessages';
import notification from '../../components/notification';
import { apiErrorHandler } from '../../helpers/errorHelper';
import { toggleChangePasswordModal } from '../../redux/ui/actions';
import { putUpdateUserPassword } from '../../redux/request/apis';

const FormItem = Form.Item;

class ChangePasswordModal extends Component {
  state = {
    submitLoading: false,
    confirmDirty: false,
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFieldsAndScroll(['confirm'], { force: true });
    }
    callback();
  };


  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.updatePassword(values);
      }
    });
  };

  updatePassword = async (values) => {
    this.setState({ submitLoading: true });
    const {
      intl, toggleChangePasswordModal, accountId,
    } = this.props;
    const data = {
      accountId,
      newPassword: values.password,
    };
    try {
      await putUpdateUserPassword({ data });
      this.setState({ submitLoading: false });
      toggleChangePasswordModal({ open: false, accountId: undefined });
      notification(
        'info',
        intl.formatMessage({ id: 'common.submit.successfully' }),
      );
    } catch (error) {
      apiErrorHandler(error, intl);
      this.setState({
        submitLoading: false,
      });
    }
    this.props.form.resetFields();
  }

  render() {
    const { toggleChangePasswordModal, open } = this.props;
    const { submitLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    return (
      <Modal
        visible={open}
        onCancel={() => toggleChangePasswordModal({ open: false, accountId: undefined })}
        title={<IntlMessages id="user.changePassword" />}
        footer={null}
      >
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Form.Item
            label={<IntlMessages id="user.newPassword" />}
            {...formItemLayout}
          >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&*])(?=.{8,})'),
                  message: <IntlMessages id="password.rules" />,
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password type="password" />)}
          </Form.Item>
          <Form.Item
            label={<IntlMessages id="user.confirmPassword" />}
            {...formItemLayout}
          >
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#$%^&*])(?=.{8,})'),
                  message: <IntlMessages id="password.rules" />,
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password type="password" onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <FormItem wrapperCol={{ span: 12, offset: 8 }}>
            <Button
              className="sino-btn"
              htmlType="submit"
              loading={submitLoading}
            >
              <IntlMessages id="common.submit" />
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedFormWIthSubmissionButton = Form.create()(
  ChangePasswordModal,
);

export default injectIntl(connect(
  state => ({
    open: state.toggleChangePasswordModal.open,
    accountId: state.toggleChangePasswordModal.accountId,
  }),
  {
    toggleChangePasswordModal,
  },
)(WrappedFormWIthSubmissionButton));
