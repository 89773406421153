export const requestConstantsMaker = str => ({
  REQUEST: `${str}_REQUEST`,
  SUCCESS: `${str}_SUCCESS`,
  FAILURE: `${str}_FAILURE`,
});

export const requestActionMaker = action => ({
  ...action,
  type: action.cons.REQUEST,
});

export const uiConstantsMaker = str => ({
  REQUEST_UPDATE: `${str}_REQUEST_UPDATE`,
  SUCCESS: `${str}_SUCCESS`,
  FAILURE: `${str}_FAILURE`,
});

export const uiActionMaker = action => ({
  ...action,
  type: action.cons.REQUEST_UPDATE,
});

export const fetchingState = (state, action) => {
  let needIsFetchingState = true;
  if (action.reset) {
    state.data = [];
  }
  if (action.isFetchBkg) needIsFetchingState = false;

  return {
    ...state,
    dataFetched: false,
    isFetching: needIsFetchingState,
    error: false,
    ack: action.ack,
    type: action.type,
  };
};

export const successState = (state, action) => {
  const { ignoreAck } = action;

  if (state.ack !== action.ack && !ignoreAck) {
    return {
      ...state,
      reject_ack: true,
    };
  }
  return {
    ...state,
    dataFetched: true,
    isFetching: false,
    reject_ack: false,
    ...action,
  };
};

export const failureState = (state, action) => ({
  ...state,
  isFetching: false,
  error: true,
  errorData: action.error,
  api: action.cons,
  timestamp: Date.now(),
  type: action.type,
});

export const requestReducerMaker = (cons, state, action) => {
  switch (action.type) {
    case cons.REQUEST:
      return fetchingState(state, action);
    case cons.SUCCESS:
      return successState(state, action);
    case cons.FAILURE:
      return failureState(state, action);
    default:
      return state;
  }
};

export const uiReducerMaker = (cons, state, action) => {
  if (action.type === cons.SUCCESS) {
    return {
      ...state,
      ...action,
    };
  }
  return state;
};

export const initialState = {
  data: [],
  dataFetched: false,
  isFetching: false,
  error: false,
};
