import './global.css';

import { Layout, ConfigProvider } from 'antd';
import React, { Component } from 'react';

import { Debounce } from 'react-throttle';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import WindowResizeListener from 'react-window-size-listener';
import { connect } from 'react-redux';
import AppHolder from './commonStyle';
import { AppLocale } from '../../dashApp';
import AppRouter from './AppRouter';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';
import ImageLightbox from '../Common/ImageLightbox';
import ChangePasswordModal from '../Common/ChangePasswordModal';
import DirectCollectGiftModal from '../Member/components/directCollectGiftModal';
import RemovePhoneNumberModal from '../Member/components/removePhoneNumberModal';
import CreateUserRemarkModal from '../Member/components/createUserRemarkModal';
// import EditGeneralRateModal from '../SDollar/components/editGeneralRateModal';
import CreateShopRemarkModal from '../SDollar/components/createShopRemarkModal';
import { getMyAuthority, getMyAccountInfo, getAllMall } from '../../redux/request/actions';
import { forceLogout } from '../../redux/request/request';
import appActions from '../../redux/app/actions';
import { siteConfig, themeConfig } from '../../settings';

import themes from '../../settings/themes';

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
export class App extends Component {
  componentDidMount() {
    const { getMyAuthority, getMyAccountInfo, getAllMall } = this.props;
    getMyAccountInfo({});
    getMyAuthority({ reset: true });
    getAllMall({});
  }

  render() {
    const { url } = this.props.match;
    const { locale, height, authority } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    if (!authority) {
      return (
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <ThemeProvider theme={themes[themeConfig.theme]}>
              <AppHolder>
                <Layout style={{ height: appHeight }}>
                  <Debounce time="1000" handler="onResize">
                    <WindowResizeListener
                      onResize={windowSize => this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight,
                      )
                      }
                    />
                  </Debounce>
                </Layout>
              </AppHolder>
            </ThemeProvider>
          </IntlProvider>
        </ConfigProvider>
      );
    }
    console.log('currentAppLocale', currentAppLocale);
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize => this.props.toggleAll(
                      windowSize.windowWidth,
                      windowSize.windowHeight,
                    )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    id="main-layout"
                    className="isoContentMainLayout"
                    style={{
                      height,
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '70px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative',
                      }}
                    >
                      <AppRouter url={url} />
                      <ImageLightbox />
                      <ChangePasswordModal />
                      <DirectCollectGiftModal />
                      <RemovePhoneNumberModal />
                      <CreateUserRemarkModal />
                      {/* <EditGeneralRateModal/> */}
                      <CreateShopRemarkModal/>
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed',
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  state => ({
    locale: state.LanguageSwitcher.language.locale,
    height: state.App.height,
    authority: state.getMyAuthority.data,
  }),
  {
    getMyAuthority,
    getMyAccountInfo,
    getAllMall,
    toggleAll,
  },
)(App);
