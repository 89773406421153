import _ from "lodash";
import request from "./request";
import { apiConfig } from "../../settings";
import { getAccessToken, getRefreshToken } from "../../helpers/authHelper";

const POST_LOGIN = "/api/v1/user/login";
const POST_LOGIN_BY_REFRESH_TOKEN = "/api/v1/user/login";
const GET_MY_AUTHORITY = "/api/v1/cms-user-management/my-authority";
const GET_LOGOUT = "/api/v1/user/logout";

// Member Apis
const GET_MEMBER_LIST = "/api/v1/user/list";
const GET_MEMBER_DETAIL = "/api/v1/user/detail";
const POST_FORCE_LOGOUT_MEMBER = "/api/v1/user/force-logout";
const GET_USER_REMARK_LIST = "/api/v1/user/user-remark/list";
const POST_CREATE_USER_REMARK = "/api/v1/user/user-remark";
const GET_ALL_USER_REMARK_TYPE = "/api/v1/user/user-remark/type/all";
const POST_CREATE_USER_REMARK_TYPE = "/api/v1/user/user-remark/type";
const POST_CREATE_MEMBER = "/api/v1/user";
const PUT_UPDATE_MEMBER = "/api/v1/user";
const POST_TERMINATE_MEMBER = "/api/v1/user_terminate_record/terminate";
const GET_ACCOUNT_WITH_PHONE = "/api/v1/account/phone";
const GET_SEND_SMS_VERIFY = "/api/v1/account/phone/sms/verify";
const PUT_CHANGE_MEMBER_PHONE = "/api/v1/account/change_phone_number_username";
const POST_FREEZE_MEMBER = "/api/v1/user_freeze_record/freeze";
const POST_UNFREEZE_MEMBER = "/api/v1/user_freeze_record/unfreeze";
const PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE =
  "/api/v1/user/receivePromotionMsg";
const PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA =
  "/api/v1/user/allowTransferPersonalData";
const GET_USER_RECORD_LIST = "/api/v1/user/records";
const GET_UNLINKED_SPENDING_TRANSACTION = "/api/v1/spending/unlinked";
const GET_LINKED_SPENDING_TRANSACTION = "/api/v1/spending/linked";
const POST_LINK_UP_SPENDING = "/api/v1/spending/linkUp";
const POST_EXTEND_POINT_EXPIRATION_DATE =
  "/api/v1/user/pointBucket/extendExpirationDate";
const POST_GET_PARKING_TRANSACTION = "/api/v1/parking/get-parking-transaction";
const POST_REDEEM_PARKING_HOUR = "/api/v1/parking/redeem-parking-hour";
const POST_REDEEM_PARKING_HOUR_NEW =
  "/api/v1/contactless_parking/redeem-parking-hour";
const POST_RETURN_GIFT = "/api/v1/gift/return";
const POST_RETURN_SCOUPON = "/api/v1/s-coupon/return";
const POST_REMOVE_PHONE_NUMBER = "/api/v1/account/remove_phone_number";
const PUT_TRIGGER_SMS = "/api/v1/account/request_login_code";
const GET_VERIFICATION_CODE = "/api/v1/account/login_code/list";
const GET_BADGE_DISTRIBUTION_HIS = "/api/v1/sino-club/badge/find";
const POST_BADGE_DISTRIBUTION = "/api/v1/sino-club/badge/update";
// Mall Apis
const GET_ALL_MALL = "/api/v1/mall/all";
const GET_SHOP_LIST = "/api/v1/mall/shop/list";
const PUT_UPDATE_SHOP = "/api/v1/mall/shop/update";
const PUT_UPDATE_REDEEMED_IN_CONCIERGE =
  "/api/v1/mall/shop/isRedeemedInConcierge/update";
const GET_ALL_SHOP_BY_MALL_CODE = "/api/v1/mall/shop/list/mall-code";
const GET_ALL_SHOP_CATEGORY = "/api/v1/mall/shop-category/all";
const GET_SHOP = "/api/v1/mall/shop";
const GET_ALL_SHOP = "/api/v1/mall/shop/all";
const POST_CREATE_SHOP = "/api/v1/mall/shop/create";
// Gift Apis
const GET_GIFT_LIST = "/api/v1/gift/list";
const GET_ALL_FOODIE_SHOP = "/api/v1/foodie/gift/shop/list";
const POST_UPDATE_GIFT_ENABLED = "/api/v1/gift/updateGiftEnabled";
const GET_GIFT_LIST_FOR_REDEEM = "/api/v1/gift/allGiftForRedeem";
const POST_RESERVE_GIFT = "/api/v1/gift/reserve";
const GET_GIFT_FOR_USER = "/api/v1/gift/user";
const POST_REDEEM_GIFT = "/api/v1/gift/redeem";
const GET_ALL_GIFT_CATEGORY = "/api/v1/gift/category/all";
const POST_CREATE_GIFT = "/api/v1/gift/create";
const PUT_UPDATE_GIFT = "/api/v1/gift/update";
const GET_GIFT = "/api/v1/gift";
const POST_ADD_GIFT_ITEM = "/api/v1/gift/addGiftItem";
const POST_MINUS_GIFT_ITEM = "/api/v1/gift/minusGiftItem";
const POST_CREATE_SKU_FOR_GIFT = "/api/v1/gift/sku/create";
const GET_GIFT_STOCK_DETAIL = "/api/v1/gift/stock/detail";
const GET_GIFT_ITEM_BY_ID = "/api/v1/gift/item/id";
const PUT_UPDATE_GIFT_SKU = "/api/v1/gift/sku/update";
const DELETE_GIFT = "/api/v1/gift/delete/id";
const DELETE_GIFT_SKU = "/api/v1/gift/delete/sku/id";
const GET_GIFT_CODE_CSV = "/api/v1/gift/third-party/unique-code/csv/get";
const GET_GIFT_TNC = "/api/v1/gift/terms";
const POST_UPDATE_GIFT_TNC = "/api/v1/gift/terms";

// Unpublished Privilege
const GET_PRIVILEGE_LIST =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/unpublished-privilege/all";
const GET_PRIVILEGE =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/unpublished-privilege/detail";
const POST_CREATE_PRIVILEGE =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/unpublished-privilege/create";
const PUT_UPDATE_PRIVILEGE =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/unpublished-privilege/update";
const PUT_UPDATE_PRIVILEGE_ENABLED =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/unpublished-privilege/enabled";

// Badge Apis
const GET_ALL_BADGE = "/api/v1/badge/all";
const GET_BADGE_LIST = "/api/v1/badge/list";
const POST_CREATE_BADGE = "/api/v1/badge";
const PUT_UPDATE_BADGE = "/api/v1/badge";
const GET_BADGE = "/api/v1/badge";
const POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER =
  "/api/v1/badge/special_invitation_member";
const DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER =
  "/api/v1/badge/special_invitation_member/remove";
const GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE = "/api/v1/badge/achieved";

//Member Tags
const GET_ALL_MEMBER_TAGS =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag/all";
const GET_MEMBER_TAGS_LIST =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag/list";
const GET_MEMBER_TAGS_LIST_BY_MEMBER =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag/user";
const GET_MEMBER_TAGS =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag";
const POST_CREATE_MEMBER_TAGS =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag";
const PUT_UPDATE_MEMBER_TAGS =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag";
const POST_UPDATE_MEMBER_TAGS_ENABLED =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag/setEnabled";
const POST_UPDATE_MEMBER_TAGS_TARGET_LIST =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/member-tag/update/target-list";
const POST_ADD_MEMBER_TAGS_TARGET_LIST =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/member-tag/add/target-list";
const POST_REMOVE_MEMBER_TAGS_TARGET_LIST =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/member-tag/remove/target-list";
const POST_MEMBER_TAGS_ADD_MEMBER =
  process.env.REACT_APP_LOYALTY_API_URL + "/api/v1/cms/member-tag/add/member";
const POST_MEMBER_TAGS_REMOVE_MEMBER =
  process.env.REACT_APP_LOYALTY_API_URL +
  "/api/v1/cms/member-tag/remove/member";

// Crazy Ad
const GET_CRAZY_AD_LIST = "/api/v1/crazy-ad/list";
const GET_CRAZY_AD = "/api/v1/crazy-ad";
const POST_CREATE_CRAZY_AD = "/api/v1/crazy-ad";
const PUT_UPDATE_CRAZY_AD = "/api/v1/crazy-ad";
// Campaign
const GET_CAMPAIGN_LIST = "/api/v1/campaign/list";
const GET_CAMPAIGN = "/api/v1/campaign";
const POST_CREATE_CAMPAIGN = "/api/v1/campaign";
const PUT_UPDATE_CAMPAIGN = "/api/v1/campaign";
const GET_ALL_CAMPAIGN = "/api/v1/campaign/all";
// Promotion
const GET_PROMOTION_LIST = "/api/v1/feed/promotion-feed/list";
const GET_PROMOTION = "/api/v1/feed/promotion-feed";
const POST_CREATE_PROMOTION = "/api/v1/feed/promotion-feed";
const PUT_UPDATE_PROMOTION = "/api/v1/feed/promotion-feed";
// Bonus Rule
const GET_BONUS_RULE_LIST = "/api/v1/spending_point_bonus/rule/list";
const GET_BONUS_RULE = "/api/v1/spending_point_bonus/rule";
const POST_CREATE_BONUS_RULE = "/api/v1/spending_point_bonus/rule";
const PUT_UPDATE_BONUS_RULE = "/api/v1/spending_point_bonus/rule";
// User Push
const GET_USER_PUSH_LIST = "/api/v1/user/notification/list";
const GET_USER_PUSH = "/api/v1/user/notification/id";
const POST_CREATE_USER_PUSH = "/api/v1/user/notification/create";
const PUT_UPDATE_USER_PUSH = "/api/v1/user/notification/update";
const PUT_CANCEL_USER_PUSH = "/api/v1/user/notification/cancel/id";
// Merchant Push
const GET_MERCHANT_PUSH_LIST = "/api/v1/merchant/notification/list";
const GET_MERCHANT_PUSH = "/api/v1/merchant/notification/id";
const POST_CREATE_MERCHANT_PUSH = "/api/v1/merchant/notification/create";
const PUT_UPDATE_MERCHANT_PUSH = "/api/v1/merchant/notification/update";
const PUT_CANCEL_MERCHANT_PUSH = "/api/v1/merchant/notification/cancel/id";
// FAQ
const GET_ALL_ONLINE_ENQUIRY = "/api/v1/faq/all";
const GET_ONLINE_ENQUIRY_LIST = "/api/v1/faq/list";
const GET_ONLINE_ENQUIRY = "/api/v1/faq";
const PUT_UPDATE_ONLINE_ENQUIRY = "/api/v1/faq";
// Spending
const GET_SPENDING_TRANSACTION_LIST = "/api/v1/spending/list";
const PUT_APPROVE_SPENDING_TRANSACTION = "/api/v1/spending/approve";
const PUT_REJECT_SPENDING_TRANSACTION = "/api/v1/spending/reject";
const GET_ALL_REJECT_REASON = "/api/v1/spending/rejectReason/all";
const POST_SUMBIT_SPENDING_TRANSACTION = "/api/v1/spending/submit";
const POST_UPDATE_SPENDING_TRANSACTION = "/api/v1/spending/edit";
const POST_RECALL_SPENDING_TRANSACTION = "/api/v1/spending/recall";
const POST_ESTIMATE_SPENDING_TRANSACTION = "/api/v1/spending/estimate";
// Point Transaction
const GET_POINT_TRANSACRION_LIST = "/api/v1/point/list";
const GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID =
  "/api/v1/point/spendingTransactionId";
// Point Adjustment
const GET_ALL_POINT_ADJUSTMENT_TYPE = "/api/v1/point_adjustment/type/all";
const POST_SUBMIT_POINT_ADJUSTMENT = "/api/v1/point_adjustment";
const POST_CREATE_POINT_ADJUSTMENT_TYPE = "/api/v1/point_adjustment/type";
// Image API
const POST_UPLOAD_IMAGE = "/api/v1/image/upload";
const POST_UPLOAD_PROFILE_IMAGE = "/api/v1/image/profile/upload";
const POST_UPLOAD_RECEIPT_IMAGE = "/api/v1/image/receipt/upload";
const POST_UPLOAD_MALL_IMAGE = "/api/v1/image/mall/upload";
const POST_UPLOAD_GIFT_IMAGE = "/api/v1/image/gift/upload";
const POST_UPLOAD_S_COUPON_IMAGE = "/api/v1/image/s-coupon/upload";
const POST_UPLOAD_ADVERTISEMENT_IMAGE = "/api/v1/image/advertisement/upload";
const POST_UPLOAD_BADGE_IMAGE = "/api/v1/image/badge/upload";
const POST_UPLOAD_BADGE_PROMOTION_IMAGE =
  "/api/v1/image/badge/promotion/upload";
const POST_UPLOAD_FOODIE_IMAGE = "/api/v1/image/foodie/upload";
const POST_UPLOAD_HOME_BANNER_IMAGE =
  "/api/v1/image/promotion-banner-ad/upload";

// Merchant
const GET_ALL_MERCHANT = "/api/v1/merchant/all";
const GET_MERCHANT_STOCK = "/api/v1/merchant/stock";
const GET_MERCHANT_REPORT = "/api/v1/merchant/report";
const GET_MERCHANT_BY_SHOP_ID = "/api/v1/merchant/shopId";
const POST_MERCHANT_REGISTER = "/api/v1/merchant/register";
const PUT_UPDATE_MERCHANT = "/api/v1/merchant/update";
// User Management
const PUT_UPDATE_USER_PASSWORD = "/api/v1/cms-user-management/password/update";
const GET_USER_LIST = "/api/v1/cms-user-management/list"; // TODO Update Api
const PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD =
  "/api/v1/cms-user-management/password/updateWithCurrentPassword";
const POST_CREATE_USER = "/api/v1/cms-user-management/create";
const PUT_UPDATE_USER_STATUS = "/api/v1/cms-user-management/status";
const GET_MY_ACCOUNT_INFO = "/api/v1/cms-user-management/me";
const PUT_UPDATE_ACCOUNT_STATUS = "/api/v1/cms-user-management/status";
// Inbox Message
const GET_INBOX_MESSAGE_SCHEDULE_LIST = "/api/v1/inbox_message/schedule/list";
const GET_INBOX_MESSAGE_SCHEDULE = "/api/v1/inbox_message/schedule/id";
const POST_CREATE_INBOX_MESSAGE_SCHEDULE = "/api/v1/inbox_message/schedule";
const PUT_UPDATE_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/inbox_message/schedule/update";
const PUT_CANCEL_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/inbox_message/schedule/cancel/id";
// Merchant Message
const GET_MERCHANT_INBOX_MESSAGE_LIST = "/api/v1/merchant_inbox_message/list";
const GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST =
  "/api/v1/merchant_inbox_message/schedule/list";
const GET_MERCHANT_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/merchant_inbox_message/schedule/id";
const POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/merchant_inbox_message/schedule";
const PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/merchant_inbox_message/schedule/update";
const PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE =
  "/api/v1/merchant_inbox_message/schedule/cancel/id";
// Parking
const GET_ALL_CAR_PARK = "/api/v1/mall/car-park/all";
const GET_CAR_PARK = "/api/v1/mall/car-park/id";
const PUT_UPDATE_CAR_PARK = "/api/v1/mall/car-park/update";
// Check In
const GET_CHECK_IN_EVENT_LIST = "/api/v1/checkin/list";
const POST_CREATE_CHECK_IN_EVENT = "/api/v1/checkin/create";
const GET_CHECK_IN_EVENT = "/api/v1/checkin";
const PUT_UPDATE_CHECK_IN_EVENT = "/api/v1/checkin/update/id";
// Custom Referral Point
const GET_CUSTOM_REFERRAL_POINT_LIST =
  "/api/v1/merchant/customReferralPoint/list";
const POST_CREATE_CUSTOM_REFERRAL_POINT =
  "/api/v1/merchant/customReferralPoint";
const GET_CUSTOM_REFERRAL_POINT = "/api/v1/merchant/customReferralPoint/id";
const PUT_UPDATE_CUSTOM_REFERRAL_POINT = "/api/v1/merchant/customReferralPoint";
const DELETE_CUSTOM_REFERRAL_POINT = "/api/v1/merchant/customReferralPoint/id";
// SDollar
const GET_SDOLLAR_SHOP_LIST = "/api/v1/mall/tenant/list";
const GET_SDOLLAR_SHOP = "/api/v1/mall/tenant";
const PUT_CONFIRM_PENDING_BALANCE = "/api/v1/mall/tenant/pending-balance";
const GET_ALL_SHOP_REMARK_TYPE = "/api/v1/mall/shop/remark-type/all";
const POST_CREATE_SHOP_REMARK_TYPE = "/api/v1/mall/shop/remark-type/create";
const POST_CREATE_SHOP_REMARK = "/api/v1/mall/shop/remark/create";
const GET_SHOP_REMARK_LIST = "/api/v1/mall/shop/remark/list";
const GET_ALL_BALANCE_ADJUST_REASON =
  "/api/v1/mall/tenant/balance-adjust-reason/all";
const POST_CREATE_BALANCE_ADJUST_REASON =
  "/api/v1/mall/tenant/balance-adjust-reason/create";
const POST_CREATE_BALANCE_ADJUSTMENT =
  "/api/v1/mall/tenant/pending-balance-adjustment/create";

const GET_TENANT_BALANCE_PERIOD = "/api/v1/mall/tenant/balance/all-period";
const GET_TENANT_BALANCE_RECORD_LIST =
  "/api/v1/mall/tenant/balance/transactions/list";
const POST_RESEND_TENANT_EMAIL = "/api/v1/mall/tenant/e-statement/send";
const GET_SDOLLAR_GLOBAL_RATE = "/api/v1/s-dollar/global-rate";
const POST_UPDATE_SDOLLAR_GLOBAL_RATE = "/api/v1/s-dollar/global-rate";
const GET_ALL_TENANT_BALANCE_RECORD_LIST =
  "/api/v1/mall/tenant/balance/transactions/all";
const PUT_ISSUE_CHEQUE = "/api/v1/mall/tenant";

// SDollar Coupon
const GET_SDOLLAR_COUPON_LIST = "/api/v1/s-dollar/coupon/list";
const GET_SDOLLAR_COUPON = "/api/v1/s-dollar/coupon";
const POST_CREATE_SDOLLAR_COUPON = "/api/v1/s-dollar/coupon/create";
const PUT_UPDATE_SDOLLAR_COUPON = "/api/v1/s-dollar/coupon/update";

const GET_SDOLLAR_SHORT_TNC = "/api/v1/s-dollar/terms";
const POST_UPDATE_SDOLLAR_SHORT_TNC = "/api/v1/s-dollar/terms";
const GET_S_COUPON_TNC = "/api/v1/s-coupon/terms";
const POST_UPDATE_S_COUPON_TNC = "/api/v1/s-coupon/terms";

const GET_ALL_TENANT_SHOP = "/api/v1/mall/tenant-shop/all";
const GET_ALL_FOODIE_TENANT_SHOP = "/api/v1/foodie/s-coupon/shop/tenant/list";

// S-Coupon
const GET_S_COUPON = "/api/v1/s-coupon";
const POST_CREATE_S_COUPON = "/api/v1/s-coupon/create";
const PUT_UPDATE_S_COUPON = "/api/v1/s-coupon/update";
const DELETE_S_COUPON = "/api/v1/s-coupon/delete/id";
const POST_ADD_S_COUPON_STOCK = "/api/v1/s-coupon/addSCouponItem";
const POST_MINUS_S_COUPON_STOCK = "/api/v1/s-coupon/minusSCouponItem";
const POST_UPDATE_S_COUPON_ENABLED = "/api/v1/s-coupon/updateSCouponEnabled";
const GET_S_COUPON_LIST = "/api/v1/s-coupon/list";
const GET_S_COUPON_STOCK_DETAIL = "/api/v1/s-coupon/stock/detail";
const GET_USER_RESERVEABLE_S_COUPON = "/api/v1/s-coupon/allSCouponForRedeem";
const GET_USER_S_COUPON = "/api/v1/s-coupon/user";
const POST_RESERVE_S_COUPON = "/api/v1/s-coupon/reserve";
const GET_COUPON_CODE_CSV = "/api/v1/s-coupon/third-party/unique-code/csv/get";

const PUT_ADD_QRCODE_AMOUNT = "/api/v1/checkin/id";
const GET_TENANT_STATMENT_PDF = "/api/v1/mall/tenant/e-statement/pdf/shop";

// Badge Promotion
const GET_ALL_SCHEDULE = "/api/v1/inbox_message/schedule/all";
const POST_CREATE_BADGE_PROMOTION = "/api/v1/badge/promotion";
const PUT_UPDATE_BADGE_PROMOTION = "/api/v1/badge/promotion";
const DELETE_BADGE_PROMOTION = "/api/v1/badge/promotion/id/";
const GET_BADGE_PROMOTION_LIST = "/api/v1/badge/promotion/list";
const GET_BADGE_PROMOTION_DETAIL = "/api/v1/badge/promotion";

const GET_USER_FREEZE_RECORD = "/api/v1/user_freeze_record/id";
const GET_USER_TERMINATE_RECORD = "/api/v1/user_terminate_record/id";

const GET_SPENDING_LIMIT = "/api/v1/spending/limit";
const POST_CHANGE_SPENDING_LIMIT = "/api/v1/spending/limit";

// Third Party Integrator
const GET_INTEGRATOR_LIST = "/api/v1/integrator/list";
const GET_INTEGRATOR = "/api/v1/integrator/id";
const POST_CREATE_INTEGRATOR = "/api/v1/integrator/create";
const PUT_UPDATE_INTEGRATOR = "/api/v1/integrator/update";
const PUT_UPDATE_INTEGRATOR_PASSWORD = "/api/v1/integrator/password/update";

// Mall
const GET_MALL_LIST = "/api/v1/mall/list";
const GET_MALL = "/api/v1/mall/";
const PUT_UPDATE_MALL = "/api/v1/mall/update";

// Contactlass parking
const GET_LICENSE_PLATE_RECORD_LIST =
  process.env.REACT_APP_PARKING_API_URL + "/getLicensePlatesRecordList";
const GET_CREDIT_CARD_LIST_BY_MEMBER_ID =
  process.env.REACT_APP_PARKING_API_URL + "/getCreditCardListByMemberId";
const GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY =
  process.env.REACT_APP_PARKING_API_URL +
  "/getLicensePlatesRecordWithParkingHistory";
const GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL =
  process.env.REACT_APP_PARKING_API_URL +
  "/getLicensePlatesRecordWithParkingHistoryDetail";
const PUT_EXIT_CARPARK = process.env.REACT_APP_PARKING_API_URL + "/exitCarpark";
const PUT_SETTLE_OUTSTANDING_PAYMENT =
  process.env.REACT_APP_PARKING_API_URL + "/settleOutstandingPayment";
const POST_FIND_PARKING_TRANSACTION =
  process.env.REACT_APP_PARKING_API_URL + "/inquiryParkingByOctopusCard";
const POST_FIND_REDEMPTION_POINT_BY_SITEID =
  process.env.REACT_APP_PARKING_API_URL + "/getTransactionRecordForRedemption";
const GET_CARPARK_LIST =
  process.env.REACT_APP_HEADLESS_CMS_URL + "/carparks?populate=*";
const GET_PARK_HISTORY_WITH_TRANSACTION =
  process.env.REACT_APP_PARKING_API_URL + "/getParkingHistoryWithTransaction";
const POST_PARK_HISTORY =
  process.env.REACT_APP_PARKING_API_URL + "/createParkingHistoryManually";

// Contactlass parking(simulation)
const PUT_VERIFY_LICENSE_PLATE =
  process.env.REACT_APP_PARKING_API_URL + "/verifyLicensePlate";
const POST_ENTER_CAR_PARK =
  process.env.REACT_APP_PARKING_API_URL + "/enterCarpark";
const PUT_EXIT_CAR_PARK_SYSTEM =
  process.env.REACT_APP_PARKING_API_URL + "/exitCarparkSystem";
const PUT_UPDATE_PARKING_TIME =
  process.env.REACT_APP_PARKING_API_URL + "/updateParkingTime";
const POST_PAY_BY_OCTOPUS =
  process.env.REACT_APP_PARKING_API_URL + "/payByOctopus";
const POST_PAY_BY_CONTACTLESS =
  process.env.REACT_APP_PARKING_API_URL + "/payByContactless";
const PUT_INVALIDATE_CREDIT_CARD =
  process.env.REACT_APP_PARKING_API_URL + "/invalidateCreditCard";

// Banner
const GET_BANNER_LIST =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/getAll";
const GET_BANNER_BY_ID =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/get";
const POST_CREATE_BANNER =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/create";
const PUT_UPDATE_BANNER =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/update";
const DELETE_BANNER_BY_ID =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/delete";
const GET_BANNER_REDIRECT_LIST =
  process.env.REACT_APP_PROMOTION_API_URL +
  "/api/v1/cms/banner/get/redirectSection";
const PUT_UPDATE_BANNER_ENABLED =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/setActive";

// Banner Category
const GET_BANNER_CATEGORY_LIST =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/banner/get/category";

// Banner Area
const GET_BANNER_AREA_LIST =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/bannerArea/getAll";

// Dynamic Banner List
const GET_DYNAMIC_BANNER_LIST =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/bannerList/getAll";
const GET_DYNAMIC_BANNER_LIST_BY_ID =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/bannerList/get";
const PUT_UPDATE_DYNAMIC_BANNER_LIST =
  process.env.REACT_APP_PROMOTION_API_URL + "/api/v1/cms/bannerList/update";

// Homepage banner
const GET_HOMEPAGE_BANNER_LIST = "/api/v1/promo_banner_ad/enquiry_list";
const GET_HOMEPAGE_BANNER_BY_ID = "/api/v1/promo_banner_ad/find";
const POST_CREATE_HOMEPAGE_BANNER = "/api/v1/promo_banner_ad/create";
const PUT_UPDATE_HOMEPAGE_BANNER = "/api/v1/promo_banner_ad/update";
const PUT_DISABLE_HOMEPAGE_BANNER = "/api/v1/promo_banner_ad/disable";
const PUT_ENABLE_HOMEPAGE_BANNER = "/api/v1/promo_banner_ad/enable";
const GET_HOMEPAGE_REDIRECT_LIST = "/api/v1/promo_banner_ad/redirect/list";

// Hot deal
const GET_HOT_DEAL_LIST =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/getHotDealsList";
const GET_HOT_DEAL_FULL_LIST =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/getHotDealsFullList";
const GET_HOT_DEAL =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/getHotDealDetail";
const POST_HOT_DEAL =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/createHotDeal";
const DELETE_HOT_DEAL =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/deleteHotDeal";
const UPDATE_HOT_DEAL =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/updateHotDeal";
const GET_FOODIE_GIFT_LIST = "/api/v1/gift/foodie/list";
const GET_FOODIE_COUPON_LIST = "/api/v1/s-coupon/foodie/list";
const DELETE_HOT_DEAL_ITEM =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/deleteHotDealItem";
const POST_UPDATE_HOT_DEAL_ENABLED =
  process.env.REACT_APP_API_URL_HOT_DEAL + "/api/enableHotDeal";
// const GET_FOODIE_GIFT_LIST = '/api/v1/gift/list/foodie';
// const GET_FOODIE_COUPON_LIST = '/api/v1/s-coupon/list/foodie';

// Sino Club Member
const GET_SINO_CLUB_MEMBER_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getMemberListForCMS";
const GET_SINO_CLUB_MEMBER_PROFILE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getProfileForCms";

const GET_SINO_UPGRADE_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getUpgradeFormListForCMS";
const GET_SINO_PARKING_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getParkingFormForCMS";
const GET_SINO_REJCET_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getDropDownList";
const UPDATE_APPROVE_FORM =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/approveForm";
const CREATE_SINO_CLUB_MEMBER =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/createMemberForCMS";
const CREATE_SINO_CLUB_MEMBER_BY_CSV =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/createMemberForCMSByCSV";
const UPDATE_SINO_CLUB_MEMBER =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/updateMemberForCMS";

const GET_SINO_DOC_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getUpgradeFormDocumentForCMS";
const UPDATE_SINO_DOC_TYPE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/updateDocumentForCMS";
const DELETE_SINO_DOC =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/deleteDocumentForCMS";
const UPLOAD_IMAGE = process.env.REACT_APP_API_URL_SINO_CLUB + "/uploadImage";

// Estate
const GET_SINO_ESTATE_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getSinoEstateListForCMS";
const GET_NON_SINO_ESTATE_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getNonSinoEstateListForCMS";
const GET_ESTATE = process.env.REACT_APP_API_URL_SINO_CLUB + "/getEstateById";
const POST_CREATE_ESTATE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/createEstate";
const POST_UPDATE_ESTATE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/updateEstate";

// Sino Club P Member Referral Code
const GET_P_MEMBER_REFERRAL_CODE_LIST =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getPMemberReferralCode";
const GET_P_MEMBER_REFERRAL_CODE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getPMemberReferralCodeById";
const GET_P_MEMBER_REFERRAL_CODE_CSV =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/getPMemberReferralCodeItem";
const CREATE_P_MEMBER_REFERRAL_CODE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/createPMemberReferralCode";
const UPDATE_P_MEMBER_REFERRAL_CODE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/updatePMemberReferralCode";
const UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/updatePMemberReferralCode";
const DELETE_P_MEMBER_REFERRAL_CODE =
  process.env.REACT_APP_API_URL_SINO_CLUB + "/deletePMemberReferralCode";

// Activity
const GET_ACTIVITY_LIST =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity";
const GET_ACTIVITY =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity";
const POST_CREATE_ACTIVITY =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity/create";
const PUT_UPDATE_ACTIVITY =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity";
const PUT_UPDATE_ACTIVITY_ENABLED =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity/setEnable";
const PUT_ALLOCATE_ACTIVITY_QUOTA =
  process.env.REACT_APP_ACTIVITY_API_URL +
  "/api/v1/cms/activity/segments/quota/allocate";
const GET_ACTIVITY_USED_REWARD_LIST =
  process.env.REACT_APP_ACTIVITY_API_URL +
  "/api/v1/cms/activity/rewards/scoupon-and-gift";

// Activity Member Segment
const GET_ACTIVITY_SEGMENTS =
  process.env.REACT_APP_ACTIVITY_API_URL + "/api/v1/cms/activity/segment/get";
const POST_CREATE_ACTIVITY_SEGMENT =
  process.env.REACT_APP_ACTIVITY_API_URL +
  "/api/v1/cms/activity/segment/create";
const PUT_UPDATE_ACTIVITY_SEGMENT =
  process.env.REACT_APP_ACTIVITY_API_URL +
  "/api/v1/cms/activity/segment/update";
const DELETE_ACTIVITY_SEGMENT =
  process.env.REACT_APP_ACTIVITY_API_URL +
  "/api/v1/cms/activity/segment/delete";

function authHeader() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

function activityAuthHeader() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
    "x-api-key": process.env.REACT_APP_ACTIVITY_API_KEY,
  };
}

function bannerAuthHeader() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
    "x-api-key": process.env.REACT_APP_PROMOTION_API_KEY,
  };
}

function authHeaderForContactless() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
    "ocp-apim-subscription-key": process.env.REACT_APP_API_KEY,
  };
}

export async function postLogin(action) {
  const data = {
    client_id: apiConfig.clientId,
    client_secret: apiConfig.clientSecret,
    grant_type: "password",
    ...action.data,
  };
  try {
    const response = await request.post(POST_LOGIN, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postLoginByRefreshToken(action) {
  const data = {
    client_id: apiConfig.clientId,
    client_secret: apiConfig.clientSecret,
    grant_type: "refresh_token",
    refresh_token: getRefreshToken(),
  };
  try {
    const response = await request.post(POST_LOGIN_BY_REFRESH_TOKEN, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMyAuthority(action) {
  try {
    const response = await request.get(GET_MY_AUTHORITY, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data.authority;
  } catch (error) {
    throw error;
  }
}

export async function getLogout(action) {
  try {
    const response = await request.get(GET_LOGOUT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberList(action) {
  try {
    const response = await request.get(GET_MEMBER_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberDetail(action) {
  try {
    // console.log('action', action);
    const response = await request.get(GET_MEMBER_DETAIL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postForceLogoutMember(action) {
  try {
    const response = await request.post(POST_FORCE_LOGOUT_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserRemarkList(action) {
  try {
    const response = await request.get(GET_USER_REMARK_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateUserRemark(action) {
  try {
    const response = await request.post(POST_CREATE_USER_REMARK, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllUserRemarkType(action) {
  try {
    const response = await request.get(GET_ALL_USER_REMARK_TYPE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateUserRemarkType(action) {
  try {
    const response = await request.post(
      POST_CREATE_USER_REMARK_TYPE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateMember(action) {
  try {
    const response = await request.post(POST_CREATE_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMember(action) {
  try {
    const response = await request.put(PUT_UPDATE_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserRecordList(action) {
  try {
    const response = await request.get(GET_USER_RECORD_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postTerminateMember(action) {
  try {
    const response = await request.post(POST_TERMINATE_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAccountWithPhone(action) {
  try {
    const response = await request.get(GET_ACCOUNT_WITH_PHONE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSendSmsVerify(action) {
  try {
    const response = await request.get(GET_SEND_SMS_VERIFY, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putChangeMemberPhone(action) {
  try {
    const response = await request.put(PUT_CHANGE_MEMBER_PHONE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postFreezeMember(action) {
  try {
    const response = await request.post(POST_FREEZE_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUnfreezeMember(action) {
  try {
    const response = await request.post(POST_UNFREEZE_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMemberReceivePromotionMessage(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMemberAllowTransferPersonalData(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllMall(action) {
  try {
    const response = await request.get(GET_ALL_MALL, {
      headers: {
        ...authHeader(),
      },
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name_en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function getShopList(action) {
  try {
    const response = await request.get(GET_SHOP_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateShop(action) {
  try {
    const response = await request.put(PUT_UPDATE_SHOP, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateRedeemedInConcierge(action) {
  try {
    const response = await request.post(
      PUT_UPDATE_REDEEMED_IN_CONCIERGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllShopByMallCode(action) {
  try {
    const response = await request.get(
      `${GET_ALL_SHOP_BY_MALL_CODE}/${action.mallCode}`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return _.sortBy(response.data, [
      function (o) {
        return o.name_en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function getAllShopCategory(action) {
  try {
    const response = await request.get(GET_ALL_SHOP_CATEGORY, {
      headers: {
        ...authHeader(),
      },
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name_en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function getShop(action) {
  try {
    const response = await request.get(GET_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllShop(action) {
  try {
    const response = await request.get(GET_ALL_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name_en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function postCreateShop(action) {
  try {
    const response = await request.post(POST_CREATE_SHOP, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftList(action) {
  try {
    const response = await request.get(GET_GIFT_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllFoodieShop(action) {
  try {
    const response = await request.get(GET_ALL_FOODIE_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name_en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function postUpdateGiftEnabled(action) {
  try {
    const response = await request.post(POST_UPDATE_GIFT_ENABLED, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftListForRedeem(action) {
  try {
    const response = await request.get(GET_GIFT_LIST_FOR_REDEEM, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftListForRedeemRemarkList(action) {
  try {
    const response = await request.get(GET_GIFT_LIST_FOR_REDEEM, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    if (!response.data || !response.data.result) return [];
    return Array.from(
      new Set(
        response.data.result.reduce(
          (prev, { rewardRemark }) =>
            rewardRemark ? [...prev, rewardRemark] : prev,
          []
        )
      )
    );
  } catch (error) {
    throw error;
  }
}

export async function postReserveGift(action) {
  try {
    const response = await request.post(POST_RESERVE_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftForUser(action) {
  try {
    const response = await request.get(GET_GIFT_FOR_USER, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postRedeemGift(action) {
  try {
    const response = await request.post(POST_REDEEM_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllGiftCategory(action) {
  try {
    const response = await request.get(GET_ALL_GIFT_CATEGORY, {
      headers: {
        ...authHeader(),
      },
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name.en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function postCreateGift(action) {
  try {
    const response = await request.post(POST_CREATE_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateGift(action) {
  try {
    const response = await request.put(PUT_UPDATE_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGift(action) {
  try {
    const response = await request.get(GET_GIFT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postAddGiftItem(action) {
  try {
    const response = await request.post(POST_ADD_GIFT_ITEM, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postMinusGiftItem(action) {
  try {
    const response = await request.post(POST_MINUS_GIFT_ITEM, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateSkuForGift(action) {
  try {
    const response = await request.post(POST_CREATE_SKU_FOR_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftStockDetail(action) {
  try {
    const response = await request.get(GET_GIFT_STOCK_DETAIL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftItemById(action) {
  try {
    const response = await request.get(
      `${GET_GIFT_ITEM_BY_ID}/${action.giftItemId}/withGiftInfo`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateGiftSKU(action) {
  try {
    const response = await request.put(`${PUT_UPDATE_GIFT_SKU}`, action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteGift(action) {
  try {
    const response = await request.delete(`${DELETE_GIFT}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteGiftSku(action) {
  try {
    const response = await request.delete(`${DELETE_GIFT_SKU}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftCodeCSV(action) {
  try {
    const response = await request.get(GET_GIFT_CODE_CSV, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getGiftTnC(action) {
  try {
    const response = await request.get(GET_GIFT_TNC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateGiftTnC(action) {
  try {
    const response = await request.post(POST_UPDATE_GIFT_TNC, action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Unpublished privilege
export async function getPrivilegeList(action) {
  try {
    const response = await request.get(GET_PRIVILEGE_LIST, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPrivilege(action) {
  try {
    const response = await request.get(`${GET_PRIVILEGE}/${action.id}`, {
      headers: { ...activityAuthHeader() },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreatePrivilege(action) {
  try {
    const response = await request.post(POST_CREATE_PRIVILEGE, action.data, {
      headers: { ...activityAuthHeader() },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdatePrivilege(action) {
  try {
    const response = await request.put(PUT_UPDATE_PRIVILEGE, action.data, {
      headers: { ...activityAuthHeader() },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdatePrivilegeEnabled(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_PRIVILEGE_ENABLED,
      action.params,
      { headers: { ...activityAuthHeader() } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Badge
export async function getAllBadge(action) {
  try {
    const response = await request.get(GET_ALL_BADGE, {
      headers: {
        ...authHeader(),
      },
    });
    return _.sortBy(response.data, [
      function (o) {
        return o.name.en.toLowerCase();
      },
    ]);
  } catch (error) {
    throw error;
  }
}

export async function getBadgeList(action) {
  try {
    const response = await request.get(GET_BADGE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateBadge(action) {
  try {
    const response = await request.post(POST_CREATE_BADGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateBadge(action) {
  try {
    const response = await request.put(PUT_UPDATE_BADGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBadge(action) {
  try {
    const response = await request.get(GET_BADGE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postGiveSpecialInvitationBadgeToMember(action) {
  try {
    const response = await request.post(
      POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteSpecialInvitationBadgeFromMember(action) {
  try {
    const response = await request.delete(
      DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER,
      {
        data: action.data,
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUsersThatHaveAchievedGivenBadge(action) {
  try {
    const response = await request.get(
      GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllMemberTags(action) {
  try {
    const response = await request.get(GET_ALL_MEMBER_TAGS, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberTagsList(action) {
  try {
    const response = await request.get(GET_MEMBER_TAGS_LIST, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberTagsListByMember(action) {
  try {
    const response = await request.get(GET_MEMBER_TAGS_LIST_BY_MEMBER, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberTags(action) {
  try {
    const response = await request.get(GET_MEMBER_TAGS, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateMemberTags(action) {
  try {
    const response = await request.post(POST_CREATE_MEMBER_TAGS, action.data, {
      headers: {
        ...activityAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMemberTags(action) {
  try {
    const response = await request.put(PUT_UPDATE_MEMBER_TAGS, action.data, {
      headers: {
        ...activityAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateMemberTagsEnabled(action) {
  try {
    const response = await request.put(
      POST_UPDATE_MEMBER_TAGS_ENABLED,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateMemberTagsTargetList(action) {
  try {
    const response = await request.post(
      POST_UPDATE_MEMBER_TAGS_TARGET_LIST,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postAddMemberTagsTargetList(action) {
  try {
    const response = await request.post(
      POST_ADD_MEMBER_TAGS_TARGET_LIST,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postRemoveMemberTagsTargetList(action) {
  try {
    const response = await request.post(
      POST_REMOVE_MEMBER_TAGS_TARGET_LIST,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postMemberTagsAddMember(action) {
  try {
    const response = await request.post(
      POST_MEMBER_TAGS_ADD_MEMBER,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postMemberTagsRemoveMember(action) {
  try {
    const response = await request.post(
      POST_MEMBER_TAGS_REMOVE_MEMBER,
      action.data,
      {
        headers: {
          ...activityAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCrazyAdList(action) {
  try {
    const response = await request.get(GET_CRAZY_AD_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCrazyAd(action) {
  try {
    const response = await request.get(GET_CRAZY_AD, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateCrazyAd(action) {
  try {
    const response = await request.post(POST_CREATE_CRAZY_AD, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateCrazyAd(action) {
  try {
    const response = await request.put(PUT_UPDATE_CRAZY_AD, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCampaignList(action) {
  try {
    const response = await request.get(GET_CAMPAIGN_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCampaign(action) {
  try {
    const response = await request.get(GET_CAMPAIGN, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateCampaign(action) {
  try {
    const response = await request.post(POST_CREATE_CAMPAIGN, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateCampaign(action) {
  try {
    const response = await request.put(PUT_UPDATE_CAMPAIGN, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllCampaign(action) {
  try {
    const response = await request.get(GET_ALL_CAMPAIGN, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPromotionList(action) {
  try {
    const response = await request.get(GET_PROMOTION_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPromotion(action) {
  try {
    const response = await request.get(GET_PROMOTION, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreatePromotion(action) {
  try {
    const response = await request.post(POST_CREATE_PROMOTION, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdatePromotion(action) {
  try {
    const response = await request.put(PUT_UPDATE_PROMOTION, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBonusRuleList(action) {
  try {
    const response = await request.get(GET_BONUS_RULE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBonusRule(action) {
  try {
    const response = await request.get(GET_BONUS_RULE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateBonusRule(action) {
  try {
    const response = await request.post(POST_CREATE_BONUS_RULE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateBonusRule(action) {
  try {
    const response = await request.put(PUT_UPDATE_BONUS_RULE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserPushList(action) {
  try {
    const response = await request.get(GET_USER_PUSH_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserPush(action) {
  try {
    const response = await request.get(`${GET_USER_PUSH}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateUserPush(action) {
  try {
    const response = await request.post(POST_CREATE_USER_PUSH, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateUserPush(action) {
  try {
    const response = await request.put(PUT_UPDATE_USER_PUSH, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putCancelUserPush(action) {
  try {
    const response = await request.put(
      `${PUT_CANCEL_USER_PUSH}/${action.id}`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantPushList(action) {
  try {
    const response = await request.get(GET_MERCHANT_PUSH_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantPush(action) {
  try {
    const response = await request.get(`${GET_MERCHANT_PUSH}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateMerchantPush(action) {
  try {
    const response = await request.post(
      POST_CREATE_MERCHANT_PUSH,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMerchantPush(action) {
  try {
    const response = await request.put(PUT_UPDATE_MERCHANT_PUSH, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putCancelMerchantPush(action) {
  try {
    const response = await request.put(
      `${PUT_CANCEL_MERCHANT_PUSH}/${action.id}`,
      null,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllOnlineEnquiry(action) {
  try {
    const response = await request.get(GET_ALL_ONLINE_ENQUIRY, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getOnlineEnquiryList(action) {
  try {
    const response = await request.get(GET_ONLINE_ENQUIRY_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getOnlineEnquiry(action) {
  try {
    const response = await request.get(GET_ONLINE_ENQUIRY, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateOnlineEnquiry(action) {
  try {
    const response = await request.put(PUT_UPDATE_ONLINE_ENQUIRY, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpendingTransactionList(action) {
  try {
    const response = await request.get(GET_SPENDING_TRANSACTION_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// TODO fake
export async function getCombineReceipts(action) {
  try {
    const response = await request.get(GET_SPENDING_TRANSACTION_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    /* console.log('getCombineReceipts');
     console.log(response); */
    const { result } = response.data;
    if (result.length > 2) {
      response.data.result = result.slice(0, 2);
    }
    /* console.log('getCombineReceipts');
    console.log(response.data.result); */
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putApproveSpendingTransaction(action) {
  try {
    const response = await request.put(
      PUT_APPROVE_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putRejectSpendingTransaction(action) {
  try {
    const response = await request.put(
      PUT_REJECT_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllRejectReason(action) {
  try {
    const response = await request.get(GET_ALL_REJECT_REASON, {
      headers: {
        ...authHeader(),
      },
    });
    const data = _.sortBy(response.data, [
      function (o) {
        return o.number;
      },
    ]);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function postSubmitSpendingTransaction(action) {
  try {
    const response = await request.post(
      POST_SUMBIT_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateSpendingTransaction(action) {
  try {
    const response = await request.post(
      POST_UPDATE_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPointTransactionList(action) {
  try {
    const response = await request.get(GET_POINT_TRANSACRION_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPointTransactionBySpendingTransactionId(action) {
  try {
    const response = await request.get(
      `${GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID}/${action.spendingTransactionId}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postRecallSpendingTransaction(action) {
  try {
    const response = await request.put(
      POST_RECALL_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postEstimateSpendingTransaction(action) {
  try {
    const response = await request.post(
      POST_ESTIMATE_SPENDING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllPointAdjustmentType(action) {
  try {
    const response = await request.get(GET_ALL_POINT_ADJUSTMENT_TYPE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postSubmitPointAdjustment(action) {
  try {
    const response = await request.post(
      POST_SUBMIT_POINT_ADJUSTMENT,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreatePointAdjustmentType(action) {
  try {
    const response = await request.post(
      POST_CREATE_POINT_ADJUSTMENT_TYPE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadImage(action) {
  try {
    const response = await request.post(POST_UPLOAD_IMAGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadProfileImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_PROFILE_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadReceiptImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_RECEIPT_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadMallImage(action) {
  try {
    const response = await request.post(POST_UPLOAD_MALL_IMAGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadGiftImage(action) {
  try {
    const response = await request.post(POST_UPLOAD_GIFT_IMAGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadSCouponImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_S_COUPON_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadAdvertisementImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_ADVERTISEMENT_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadBadgeImage(action) {
  try {
    const response = await request.post(POST_UPLOAD_BADGE_IMAGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadBadgePromotionImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_BADGE_PROMOTION_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadHomeBannerImage(action) {
  try {
    const response = await request.post(
      POST_UPLOAD_HOME_BANNER_IMAGE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllMerchant(action) {
  try {
    const response = await request.get(GET_ALL_MERCHANT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantStock(action) {
  try {
    const response = await request.get(GET_MERCHANT_STOCK, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantReport(action) {
  try {
    const response = await request.get(GET_MERCHANT_REPORT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantByShopId(action) {
  try {
    const response = await request.get(
      `${GET_MERCHANT_BY_SHOP_ID}\\${action.shopId}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postMerchantRegister(action) {
  try {
    const response = await request.post(POST_MERCHANT_REGISTER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMerchant(action) {
  try {
    const response = await request.post(PUT_UPDATE_MERCHANT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateUserPassword(action) {
  try {
    const response = await request.put(PUT_UPDATE_USER_PASSWORD, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserList(action) {
  try {
    const response = await request.get(GET_USER_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateUserPasswordWithCurrentPassword(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateUser(action) {
  try {
    const response = await request.post(POST_CREATE_USER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateUserStatus(action) {
  try {
    const response = await request.put(PUT_UPDATE_USER_STATUS, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMyAccountInfo(action) {
  try {
    const response = await request.get(GET_MY_ACCOUNT_INFO, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateAccountStatus(action) {
  try {
    const response = await request.put(PUT_UPDATE_ACCOUNT_STATUS, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getInboxMessageScheduleList(action) {
  try {
    const response = await request.get(GET_INBOX_MESSAGE_SCHEDULE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getInboxMessageSchedule(action) {
  try {
    const response = await request.get(
      `${GET_INBOX_MESSAGE_SCHEDULE}/${action.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateInboxMessageSchedule(action) {
  try {
    const response = await request.post(
      POST_CREATE_INBOX_MESSAGE_SCHEDULE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateInboxMessageSchedule(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_INBOX_MESSAGE_SCHEDULE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putCancelInboxMessageSchedule(action) {
  try {
    const response = await request.put(
      `${PUT_CANCEL_INBOX_MESSAGE_SCHEDULE}/${action.id}`,
      null,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantInboxMessageList(action) {
  try {
    const response = await request.get(GET_MERCHANT_INBOX_MESSAGE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantInboxMessageScheduleList(action) {
  try {
    const response = await request.get(
      GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMerchantInboxMessageSchedule(action) {
  try {
    const response = await request.get(
      `${GET_MERCHANT_INBOX_MESSAGE_SCHEDULE}/${action.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateMerchantInboxMessageSchedule(action) {
  try {
    const response = await request.post(
      POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMerchantInboxMessageSchedule(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putCancelMerchantInboxMessageSchedule(action) {
  try {
    const response = await request.put(
      `${PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE}/${action.id}`,
      null,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllCarPark(action) {
  try {
    const response = await request.get(GET_ALL_CAR_PARK, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCarPark(action) {
  try {
    const response = await request.get(`${GET_CAR_PARK}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateCarPark(action) {
  try {
    const response = await request.put(PUT_UPDATE_CAR_PARK, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCheckInEventList(action) {
  try {
    const response = await request.get(GET_CHECK_IN_EVENT_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateCheckInEvent(action) {
  try {
    const response = await request.post(
      POST_CREATE_CHECK_IN_EVENT,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCheckInEvent(action) {
  try {
    const response = await request.get(GET_CHECK_IN_EVENT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateCheckInEvent(action) {
  try {
    const response = await request.put(
      `${PUT_UPDATE_CHECK_IN_EVENT}/${action.id}`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Custom Referral Point
export async function getCustomReferralPointList(action) {
  try {
    const response = await request.get(GET_CUSTOM_REFERRAL_POINT_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateCustomReferralPoint(action) {
  try {
    const response = await request.post(
      POST_CREATE_CUSTOM_REFERRAL_POINT,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCustomReferralPoint(action) {
  try {
    const response = await request.get(
      `${GET_CUSTOM_REFERRAL_POINT}/${action.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateCustomReferralPoint(action) {
  try {
    const response = await request.put(
      `${PUT_UPDATE_CUSTOM_REFERRAL_POINT}`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteCustomReferralPoint(action) {
  try {
    const response = await request.delete(
      `${DELETE_CUSTOM_REFERRAL_POINT}/${action.id}`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// SDollar
export async function getSDollarShopList(action) {
  try {
    const response = await request.get(GET_SDOLLAR_SHOP_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSDollarShop(action) {
  try {
    const response = await request.get(GET_SDOLLAR_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putConfirmPendingBalance(action) {
  try {
    const response = await request.put(
      PUT_CONFIRM_PENDING_BALANCE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllShopRemarkType(action) {
  try {
    const response = await request.get(GET_ALL_SHOP_REMARK_TYPE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateShopRemarkType(action) {
  try {
    const response = await request.post(
      POST_CREATE_SHOP_REMARK_TYPE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateShopRemark(action) {
  try {
    const response = await request.post(POST_CREATE_SHOP_REMARK, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getShopRemarkList(action) {
  try {
    const response = await request.get(GET_SHOP_REMARK_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllBalanceAdjustReason(action) {
  try {
    const response = await request.get(GET_ALL_BALANCE_ADJUST_REASON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateBalanceAdjustReason(action) {
  try {
    const response = await request.post(
      POST_CREATE_BALANCE_ADJUST_REASON,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateBalanceAdjustment(action) {
  try {
    const response = await request.post(
      POST_CREATE_BALANCE_ADJUSTMENT,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTenantBalancePeriod(action) {
  try {
    const response = await request.get(GET_TENANT_BALANCE_PERIOD, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTenantBalanceRecordList(action) {
  try {
    const response = await request.get(GET_TENANT_BALANCE_RECORD_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postResendTenantEmail(action) {
  try {
    const response = await request.post(POST_RESEND_TENANT_EMAIL, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSDollarGlobalRate(action) {
  try {
    const response = await request.get(GET_SDOLLAR_GLOBAL_RATE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateSDollarGlobalRate(action) {
  try {
    const response = await request.post(
      POST_UPDATE_SDOLLAR_GLOBAL_RATE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllTenantBalanceRecordList(action) {
  try {
    const response = await request.get(GET_ALL_TENANT_BALANCE_RECORD_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putIssueCheque(action) {
  try {
    const response = await request.put(
      `${PUT_ISSUE_CHEQUE}/${action.id}/issueCheque`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// SDollar Coupon
export async function getSDollarCouponList(action) {
  try {
    const response = await request.get(GET_SDOLLAR_COUPON_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSDollarCoupon(action) {
  try {
    const response = await request.get(GET_SDOLLAR_COUPON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateSDollarCoupon(action) {
  try {
    const response = await request.post(
      POST_CREATE_SDOLLAR_COUPON,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateSDollarCoupon(action) {
  try {
    const response = await request.put(PUT_UPDATE_SDOLLAR_COUPON, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSDollarShortTnC(action) {
  try {
    const response = await request.get(GET_SDOLLAR_SHORT_TNC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateSDollarShortTnC(action) {
  try {
    const response = await request.post(
      POST_UPDATE_SDOLLAR_SHORT_TNC,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSCouponTnC(action) {
  try {
    const response = await request.get(GET_S_COUPON_TNC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateSCouponTnC(action) {
  try {
    const response = await request.post(POST_UPDATE_S_COUPON_TNC, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllTenantShop(action) {
  try {
    const response = await request.get(GET_ALL_TENANT_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllFoodieTenantShop(action) {
  try {
    const response = await request.get(GET_ALL_FOODIE_TENANT_SHOP, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// S-Coupon
export async function getSCoupon(action) {
  try {
    const response = await request.get(GET_S_COUPON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateSCoupon(action) {
  try {
    const response = await request.post(POST_CREATE_S_COUPON, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateSCoupon(action) {
  try {
    const response = await request.put(PUT_UPDATE_S_COUPON, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteSCoupon(action) {
  try {
    const response = await request.delete(`${DELETE_S_COUPON}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postAddSCouponStock(action) {
  try {
    const response = await request.post(POST_ADD_S_COUPON_STOCK, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postMinusSCouponStock(action) {
  try {
    const response = await request.post(
      POST_MINUS_S_COUPON_STOCK,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateSCouponEnabled(action) {
  try {
    const response = await request.post(
      POST_UPDATE_S_COUPON_ENABLED,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSCouponList(action) {
  try {
    const response = await request.get(GET_S_COUPON_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSCouponStockDetail(action) {
  try {
    const response = await request.get(GET_S_COUPON_STOCK_DETAIL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserReserveableSCoupon(action) {
  try {
    const response = await request.get(GET_USER_RESERVEABLE_S_COUPON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserReserveableSCouponRemarkList(action) {
  try {
    const response = await request.get(GET_USER_RESERVEABLE_S_COUPON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    if (!response.data || !response.data.result) return [];
    return Array.from(
      new Set(
        response.data.result.reduce(
          (prev, { rewardRemark }) =>
            rewardRemark ? [...prev, rewardRemark] : prev,
          []
        )
      )
    );
  } catch (error) {
    throw error;
  }
}

export async function getUserSCoupon(action) {
  try {
    const response = await request.get(GET_USER_S_COUPON, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postReserveSCoupon(action) {
  try {
    const response = await request.post(POST_RESERVE_S_COUPON, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCouponCodeCSV(action) {
  try {
    const response = await request.get(GET_COUPON_CODE_CSV, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putAddQRCodeAmount(action) {
  try {
    const response = await request.put(
      `${PUT_ADD_QRCODE_AMOUNT}/${action.data.id}/addQrcodeAmount`,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTenantStatementPDF(action) {
  try {
    const response = await request.get(
      `${GET_TENANT_STATMENT_PDF}/${action.data.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

// Badge Promotion
export async function getAllSchedule(action) {
  try {
    const response = await request.get(GET_ALL_SCHEDULE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateBadgePromotion(action) {
  try {
    const response = await request.post(
      POST_CREATE_BADGE_PROMOTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateBadgePromotion(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_BADGE_PROMOTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteBadgePromotion(action) {
  try {
    const response = await request.delete(
      `${DELETE_BADGE_PROMOTION}/${action.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBadgePromotionList(action) {
  try {
    const response = await request.get(GET_BADGE_PROMOTION_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBadgePromotionDetail(action) {
  try {
    const response = await request.get(GET_BADGE_PROMOTION_DETAIL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserFreezeRecord(action) {
  try {
    const response = await request.get(GET_USER_FREEZE_RECORD, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserTerminateRecord(action) {
  try {
    const response = await request.get(GET_USER_TERMINATE_RECORD, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpendingLimit(action) {
  try {
    const response = await request.get(GET_SPENDING_LIMIT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postChangeSpendingLimit(action) {
  try {
    const response = await request.post(
      POST_CHANGE_SPENDING_LIMIT,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Third Party Integrator

export async function getIntegratorList(action) {
  try {
    const response = await request.get(GET_INTEGRATOR_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getIntegrator(action) {
  try {
    const response = await request.get(
      `${GET_INTEGRATOR}/${action.params.id}`,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateIntegrator(action) {
  try {
    const response = await request.post(POST_CREATE_INTEGRATOR, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateIntegrator(action) {
  try {
    const response = await request.put(PUT_UPDATE_INTEGRATOR, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateIntegratorPassword(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_INTEGRATOR_PASSWORD,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Mall
export async function getMallList(action) {
  try {
    const response = await request.get(GET_MALL_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMall(action) {
  try {
    const response = await request.get(GET_MALL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateMall(action) {
  try {
    const response = await request.put(PUT_UPDATE_MALL, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUnlinkedSpendingTransaction(action) {
  try {
    const response = await request.get(GET_UNLINKED_SPENDING_TRANSACTION, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getLinkedSpendingTransaction(action) {
  /* console.log('in getLinkedSpendingTransaction');
   console.log(action); */
  try {
    const response = await request.get(GET_LINKED_SPENDING_TRANSACTION, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postSpendingLinkUp(action) {
  try {
    const response = await request.post(POST_LINK_UP_SPENDING, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postExtendPointExpirationDate(action) {
  try {
    const response = await request.post(
      POST_EXTEND_POINT_EXPIRATION_DATE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postGetParkingTransaction(action) {
  try {
    const response = await request.post(
      POST_GET_PARKING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postGetParkingTransactionRecord(action) {
  try {
    const response = await request.post(
      POST_FIND_REDEMPTION_POINT_BY_SITEID,
      action.data,
      {
        headers: {
          ...authHeaderForContactless(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postFindParkingTransaction(action) {
  try {
    const response = await request.post(
      POST_FIND_PARKING_TRANSACTION,
      action.data,
      {
        headers: {
          ...authHeaderForContactless(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postRedeemParkingHour(action) {
  try {
    const response = await request.post(POST_REDEEM_PARKING_HOUR, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postRedeemParkingHourNew(action) {
  try {
    const response = await request.post(
      POST_REDEEM_PARKING_HOUR_NEW,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postReturnGift(action) {
  try {
    const response = await request.post(POST_RETURN_GIFT, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postReturnSCoupon(action) {
  try {
    const response = await request.post(POST_RETURN_SCOUPON, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/*
{
    "countryCode": "852", 
    "phoneNumber": "99987654"
}
*/
export async function postRemovePhoneNumber(action) {
  try {
    const response = await request.post(POST_REMOVE_PHONE_NUMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putTriggerSms(action) {
  try {
    const response = await request.put(PUT_TRIGGER_SMS, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getVerificationCodeList(action) {
  try {
    const response = await request.get(GET_VERIFICATION_CODE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postBadgeDistribution(action) {
  try {
    const response = await request.post(POST_BADGE_DISTRIBUTION, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBadgeDistributionHistory(action) {
  try {
    const response = await request.get(GET_BADGE_DISTRIBUTION_HIS, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getLicensePlatesRecordList(action) {
  try {
    const response = await request.get(GET_LICENSE_PLATE_RECORD_LIST, {
      headers: {
        ...authHeaderForContactless(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getCreditCardListByMemberId(action) {
  try {
    const response = await request.get(GET_CREDIT_CARD_LIST_BY_MEMBER_ID, {
      headers: {
        ...authHeaderForContactless(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getLicensePlatesRecordWithParkingHistory(action) {
  try {
    const response = await request.get(
      GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY,
      {
        headers: {
          ...authHeaderForContactless(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getLicensePlatesRecordWithParkingHistoryDetail(action) {
  try {
    const response = await request.get(
      GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL,
      {
        headers: {
          ...authHeaderForContactless(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putExitCarpark(action) {
  try {
    const response = await request.put(PUT_EXIT_CARPARK, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putSettleOutstandingPayment(action) {
  try {
    const response = await request.put(
      PUT_SETTLE_OUTSTANDING_PAYMENT,
      action.data,
      {
        headers: {
          ...authHeaderForContactless(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putVerifyLicensePlate(action) {
  try {
    const response = await request.put(PUT_VERIFY_LICENSE_PLATE, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postEnterCarPark(action) {
  try {
    const response = await request.post(POST_ENTER_CAR_PARK, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putExitCarParkSystem(action) {
  try {
    const response = await request.put(PUT_EXIT_CAR_PARK_SYSTEM, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putUpdateParkingTime(action) {
  try {
    const response = await request.put(PUT_UPDATE_PARKING_TIME, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postPayByOctopus(action) {
  try {
    const response = await request.post(POST_PAY_BY_OCTOPUS, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postPayByContactless(action) {
  try {
    const response = await request.post(POST_PAY_BY_CONTACTLESS, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putInvalidateCreditCard(action) {
  try {
    const response = await request.put(
      PUT_INVALIDATE_CREDIT_CARD,
      action.data,
      {
        headers: {
          ...authHeaderForContactless(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCarparkList(action) {
  try {
    const response = await request.get(GET_CARPARK_LIST, {
      headers: {
        // ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postParkHistory(action) {
  try {
    const response = await request.post(POST_PARK_HISTORY, action.data, {
      headers: {
        ...authHeaderForContactless(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBannerList(action) {
  try {
    const response = await request.get(GET_BANNER_LIST, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getBannerById(action) {
  try {
    const response = await request.get(GET_BANNER_BY_ID, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postCreateBanner(action) {
  try {
    const response = await request.post(POST_CREATE_BANNER, action.data, {
      headers: {
        ...bannerAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putUpdateBanner(action) {
  try {
    const response = await request.put(PUT_UPDATE_BANNER, action.data, {
      headers: {
        ...bannerAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function deleteBanner(action) {
  try {
    const response = await request.delete(DELETE_BANNER_BY_ID, {
      params: action.params,
      headers: {
        ...bannerAuthHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putUpdateBannerEnabled(action) {
  try {
    const response = await request.put(PUT_UPDATE_BANNER_ENABLED, action.data, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getBannerRedirectList(action) {
  try {
    const response = await request.get(GET_BANNER_REDIRECT_LIST, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getBannerCategoryList(action) {
  try {
    const response = await request.get(GET_BANNER_CATEGORY_LIST, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getBannerAreaList(action) {
  try {
    const response = await request.get(GET_BANNER_AREA_LIST, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDynamicBannerList(action) {
  try {
    const response = await request.get(GET_DYNAMIC_BANNER_LIST, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getDynamicBannerListById(action) {
  try {
    const response = await request.get(GET_DYNAMIC_BANNER_LIST_BY_ID, {
      headers: {
        ...bannerAuthHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putUpdateDynamicBannerList(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_DYNAMIC_BANNER_LIST,
      action.data,
      {
        headers: {
          ...bannerAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getHomepageBannerList(action) {
  try {
    const response = await request.get(GET_HOMEPAGE_BANNER_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getHomepageBannerById(action) {
  try {
    const response = await request.get(GET_HOMEPAGE_BANNER_BY_ID, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postCreateHomepageBanner(action) {
  try {
    const response = await request.post(
      POST_CREATE_HOMEPAGE_BANNER,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putUpdateHomepageBanner(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_HOMEPAGE_BANNER,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putDisableHomepageBanner(action) {
  try {
    const response = await request.put(
      PUT_DISABLE_HOMEPAGE_BANNER,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function putEnableHomepageBanner(action) {
  try {
    const response = await request.put(
      PUT_ENABLE_HOMEPAGE_BANNER,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        params: action.params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getHomepageBannerRedirectList(action) {
  try {
    const response = await request.get(GET_HOMEPAGE_REDIRECT_LIST, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getHotDealList(action) {
  try {
    const response = await request.get(GET_HOT_DEAL_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function getHotDealFullList(action) {
  try {
    const response = await request.get(GET_HOT_DEAL_FULL_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data.data.hotDeals;
  } catch (error) {
    throw error;
  }
}
export async function getHotDealDetail(action) {
  try {
    const response = await request.get(GET_HOT_DEAL, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function createHotDeal(action) {
  try {
    const response = await request.post(POST_HOT_DEAL, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function deleteHotDeal(action) {
  try {
    const response = await request.post(DELETE_HOT_DEAL, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteHotDealItem(action) {
  try {
    const response = await request.post(DELETE_HOT_DEAL_ITEM, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateHotDeal(action) {
  try {
    const response = await request.post(UPDATE_HOT_DEAL, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getFoodieGiftList(action) {
  try {
    const response = await request.get(GET_FOODIE_GIFT_LIST, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getFoodieCouponList(action) {
  try {
    const response = await request.get(GET_FOODIE_COUPON_LIST, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUploadFoodieImage(action) {
  try {
    const response = await request.post(POST_UPLOAD_FOODIE_IMAGE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateHotDealEnabled(action) {
  try {
    const response = await request.post(
      POST_UPDATE_HOT_DEAL_ENABLED,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Sino Club Member

export async function getSinoClubMemberList(action) {
  try {
    const response = await request.get(GET_SINO_CLUB_MEMBER_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMemberProfile(action) {
  try {
    const response = await request.get(GET_SINO_CLUB_MEMBER_PROFILE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSinoUpgradeList(action) {
  try {
    const response = await request.get(GET_SINO_UPGRADE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSinoParkingList(action) {
  try {
    const response = await request.get(GET_SINO_PARKING_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSinoClubRejectReason(action) {
  try {
    const response = await request.get(GET_SINO_REJCET_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateApproveForm(action) {
  //   {
  //     "id": [1,2],
  //     "formType": "PARKING",  /// PARKING/UPGRADE
  //     "isApproved": true,  /// isApproved:true = approve, isApproved:false = reject
  //     "rejectedReasonId":1
  // }
  try {
    const response = await request.post(UPDATE_APPROVE_FORM, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSinoDocumentList(action) {
  try {
    const response = await request.get(GET_SINO_DOC_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function createSinoClubMember(action) {
  try {
    const response = await request.post(CREATE_SINO_CLUB_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function createSinoClubMemberByCSV(action) {
  try {
    const response = await request.post(
      CREATE_SINO_CLUB_MEMBER_BY_CSV,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        timeout: 600000 * 5, // 5 minutes timeout
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateSinoClubMember(action) {
  try {
    const response = await request.post(UPDATE_SINO_CLUB_MEMBER, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function updateSinoDocumentType(action) {
  try {
    const response = await request.post(UPDATE_SINO_DOC_TYPE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteSinoDocument(action) {
  try {
    const response = await request.post(DELETE_SINO_DOC, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateSinoClubImage(action) {
  try {
    const response = await request.post(UPLOAD_IMAGE, action.data, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getParkHistoryWithTransaction(action) {
  try {
    const response = await request.get(GET_PARK_HISTORY_WITH_TRANSACTION, {
      headers: {
        ...authHeaderForContactless(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getActivityList(action) {
  try {
    const response = await request.get(GET_ACTIVITY_LIST, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getActivity(action) {
  try {
    const response = await request.get(`${GET_ACTIVITY}/${action.id}`, {
      headers: { ...activityAuthHeader() },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateActivity(action) {
  try {
    const response = await request.post(POST_CREATE_ACTIVITY, action.data, {
      headers: { ...activityAuthHeader() },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateActivity(action) {
  try {
    const response = await request.put(PUT_UPDATE_ACTIVITY, action.data, {
      headers: { ...activityAuthHeader() },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateActivityEnabled(action) {
  try {
    const response = await request.put(PUT_UPDATE_ACTIVITY_ENABLED, null, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putAllocateActivityQuota(action) {
  try {
    const response = await request.put(
      PUT_ALLOCATE_ACTIVITY_QUOTA,
      action.data,
      {
        headers: { ...activityAuthHeader() },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getActivityUsedRewardList(action) {
  try {
    const response = await request.get(GET_ACTIVITY_USED_REWARD_LIST, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getActivitySegments(action) {
  try {
    const response = await request.get(GET_ACTIVITY_SEGMENTS, {
      headers: { ...activityAuthHeader() },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateActivitySegment(action) {
  try {
    const response = await request.post(
      POST_CREATE_ACTIVITY_SEGMENT,
      action.data,
      {
        headers: { ...activityAuthHeader() },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdateActivitySegment(action) {
  try {
    const response = await request.put(
      PUT_UPDATE_ACTIVITY_SEGMENT,
      action.data,
      { headers: { ...activityAuthHeader() } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteActivitySegment(action) {
  try {
    const response = await request.delete(
      `${DELETE_ACTIVITY_SEGMENT}/${action.id}`,
      { headers: { ...activityAuthHeader() } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPMemberReferralCodeList(action) {
  try {
    const response = await request.get(GET_P_MEMBER_REFERRAL_CODE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSinoEstateList(action) {
  try {
    const response = await request.get(GET_SINO_ESTATE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getNonSinoEstateList(action) {
  try {
    const response = await request.get(GET_NON_SINO_ESTATE_LIST, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPMemberReferralCode(action) {
  try {
    const response = await request.get(GET_P_MEMBER_REFERRAL_CODE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPMemberReferralCodeCSV(action) {
  try {
    const response = await request.get(GET_P_MEMBER_REFERRAL_CODE_CSV, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreatePMemberReferralCode(action) {
  try {
    const response = await request.post(
      CREATE_P_MEMBER_REFERRAL_CODE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdatePMemberReferralCode(action) {
  try {
    const response = await request.put(
      UPDATE_P_MEMBER_REFERRAL_CODE,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function putUpdatePMemberReferralCodeEnabled(action) {
  try {
    const response = await request.put(
      UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED,
      action.data,
      {
        headers: {
          ...authHeader(),
        },
        data: { action: "ENABLED" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deletePMemberReferralCode(action) {
  try {
    const response = await request.delete(DELETE_P_MEMBER_REFERRAL_CODE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getEstate(action) {
  try {
    const response = await request.get(GET_ESTATE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postCreateEstate(action) {
  try {
    const response = await request.post(POST_CREATE_ESTATE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateEstate(action) {
  try {
    const response = await request.post(POST_UPDATE_ESTATE, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
