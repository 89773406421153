import antdCn from 'antd/lib/locale-provider/zh_CN';
import appLocaleData from 'react-intl/locale-data/zh';
import zhCNMessages from '../locales/zh-Hans-CN.json';

const ZhCNLan = {
  messages: {
    ...zhCNMessages,
  },
  antd: antdCn,
  locale: 'zh-Hans-CN',
  data: appLocaleData,
};
export default ZhCNLan;
