import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import Button, { ButtonGroup } from '../../components/uielements/button';
import actions from '../../redux/languageSwitcher/actions';
import config from './config';

const { changeLanguage } = actions;

class LanguageSwitcher extends Component {
  changeLocale = (languageId) => {
    const { changeLanguage } = this.props;
    changeLanguage(languageId);
    console.log(languageId);
    if (languageId === 'chinese-traditional') {
      moment.locale('zh-hk');
    } else if (languageId === 'chinese-simplified') {
      moment.locale('zh-cn');
    } else {
      moment.locale('en');
    }
  };

  render() {
    const { language } = this.props;
    return (
      <div className="themeSwitchBlock">
        <div className="themeSwitchBtnWrapper">
          <ButtonGroup>
            {config.options.map((option) => {
              const { languageId, text } = option;
              const type = languageId === language.languageId ? 'danger' : '';
              const styling = {
                border: 'none',
              };
              return (
                <Button
                  type={type}
                  style={styling}
                  key={languageId}
                  onClick={() => {
                    this.changeLocale(languageId);
                  }}
                >
                  {text}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.LanguageSwitcher,
  }),
  { changeLanguage },
)(LanguageSwitcher);
