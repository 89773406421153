import { Form, Input, Select } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../../components/uielements/button';
import IntlMessages from '../../../components/utility/intlMessages';
import Modals from '../../../components/feedback/modal';
import WithDirection from '../../../settings/withDirection';
import ModalStyle from '../../DefaultStyle/modal.style';
import CreateUserRemarkTypeModal from './createUserRemarkTypeModal';
import { apiErrorHandler } from '../../../helpers/errorHelper';
import { getAllUserRemarkType, getUserRemarkList } from '../../../redux/request/actions';
import { toggleCreateUserRemarkModal, toggleCreateUserRemarkTypeModal } from '../../../redux/ui/actions';
import { postCreateUserRemark } from '../../../redux/request/apis';
import permission from '../../../settings/permission';
import _ from 'lodash';

const isoModal = ModalStyle(Modals);
const Modal = WithDirection(isoModal);
const FormItem = Form.Item;


class CreateUserRemarkModal extends Component {
  state = {
    submitLoading: false,
  };

  componentDidMount() {
    const { getAllUserRemarkType, userRemarkTypesFetched } = this.props;
    if (!userRemarkTypesFetched) {
      getAllUserRemarkType({});
    }
    this.initValue(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.initRemark !== nextProps.initRemark) {
      this.props.form.setFieldsValue({
        remark: nextProps.initRemark,
      });
    }
    if (this.props.initTypeId !== nextProps.initTypeId) {
      this.props.form.setFieldsValue({
        typeId: nextProps.initTypeId,
      });
    }
  }

  initValue = (props) => {
    this.props.form.setFieldsValue({
      remark: props.initRemark,
      typeId: props.initTypeId,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.createUserRemarkType(values);
      }
    });
  };

  createUserRemarkType = async (values) => {
    this.setState({ submitLoading: true });
    const {
      intl, toggleCreateUserRemarkModal, onSuccess, userId, action,
    } = this.props;
    const data = {
      userId,
      ...values,
      enabled: true,
    };
    try {
      await postCreateUserRemark({ data });
      this.setState({
        submitLoading: false,
      });
      this.props.form.resetFields();
      toggleCreateUserRemarkModal({
        open: false, userId: undefined, initRemark: undefined, canClose: false, updatedDate: Date.now(), initTypeId: undefined,
      });
      if (onSuccess) {
        onSuccess();
      }
      if (action) {
        action();
      }
    } catch (error) {
      apiErrorHandler(error, intl);
      this.setState({
        submitLoading: false,
      });
    }
  };

  handleCreateUserRemarkTypeSuccess = (typeId) => {
    const { getAllUserRemarkType } = this.props;
    getAllUserRemarkType();
    this.props.form.setFieldsValue({
      typeId,
    });
  }

  render() {
    const {
      authority, intl, toggleCreateUserRemarkModal, toggleCreateUserRemarkTypeModal, open, userRemarkTypes, canClose,
    } = this.props;
    const { submitLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    var filteredUserRemarkTypes = _.filter(userRemarkTypes, o => o.enabled === true);

    let locale = 'en';
    if (intl.locale === 'en-US') {
      locale = 'en';
    } else if (intl.locale === 'zh-Hans-HK') {
      locale = 'zh_hk';
    } else if (intl.locale === 'zh-Hans-CN') {
      locale = 'zh_cn';
    }

    let createUserRemarkTypeButton = null;
    if (permission.MEMBER.ADD_REMARK_TYPE.some(s => authority.includes(s))) {
      createUserRemarkTypeButton = (
        <div style={{ marginTop: '5px' }}>
          <Button
            className="add-type-btn"
            icon="plus"
            onClick={() => toggleCreateUserRemarkTypeModal({ open: true })}
          >
            <IntlMessages id="member.userRemark.newRemarkType" />
          </Button>
        </div>
      );
    }

    return (
      <Modal
        visible={open}
        closable={canClose}
        onCancel={canClose ? () => toggleCreateUserRemarkModal({
          open: false, userId: undefined, initRemark: undefined, canClose: false, initTypeId: undefined,
        }) : null}
        title={<IntlMessages id="member.userRemark.addRemark" />}
        footer={null}
      >
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={<IntlMessages id="member.userRemark.type" />}
          >
            {getFieldDecorator('typeId', {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select>
                {filteredUserRemarkTypes.map(type => (
                  <Select.Option value={type.id} key={type.id}>
                    {type.remark[locale]}
                  </Select.Option>
                ))}
              </Select>,
            )}
            {createUserRemarkTypeButton}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<IntlMessages id="member.userRemark.remark" />}
          >
            {getFieldDecorator('remark', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem wrapperCol={{ span: 12, offset: 8 }}>
            <Button
              className="sino-btn"
              htmlType="submit"
              loading={submitLoading}
            >
              <IntlMessages id="common.submit" />
            </Button>
          </FormItem>
        </Form>
        <CreateUserRemarkTypeModal onSuccess={this.handleCreateUserRemarkTypeSuccess} />
      </Modal>
    );
  }
}

const WrappedFormWIthSubmissionButton = Form.create()(
  CreateUserRemarkModal,
);

export default injectIntl(connect(
  state => ({
    authority: state.getMyAuthority.data,
    open: state.toggleCreateUserRemarkModal.open,
    userId: state.toggleCreateUserRemarkModal.userId,
    action: state.toggleCreateUserRemarkModal.action,
    canClose: state.toggleCreateUserRemarkModal.canClose,
    initRemark: state.toggleCreateUserRemarkModal.initRemark,
    initTypeId: state.toggleCreateUserRemarkModal.initTypeId,
    userRemarkTypes: state.getAllUserRemarkType.data,
    userRemarkTypesFetched: state.getAllUserRemarkType.dataFetched,
  }),
  {
    toggleCreateUserRemarkModal,
    toggleCreateUserRemarkTypeModal,
    getAllUserRemarkType,
    getUserRemarkList,
  },
)(WrappedFormWIthSubmissionButton));
