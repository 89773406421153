import 'babel-polyfill';
import promise from 'es6-promise';
import 'isomorphic-fetch';
import 'antd/dist/antd.css';

// import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './serviceWorker';
import DashApp from './dashApp';

promise.polyfill();

ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
unregister();

/*
if (!DEBUG_MODE_ON) {
    console = console || {};
    console.log = function(){};
}
*/
