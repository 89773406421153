class AuthHelper {
  checkExpirity = (tokenResponse) => {
    console.log('checkExpirity');
    if (!tokenResponse) {
      return {
        code: '500',
        message: 'Internal Server Error',
        detail: 'Internal Server Error',
      };
    }
    try {
      const expiredAt = Date.now() + tokenResponse.expires_in * 1000;
      if (expiredAt > new Date().getTime()) {
        return {
          ...tokenResponse,
          expired_at: new Date(),
        };
      }
      return {
        code: '400',
        message: 'Token expired',
        detail: 'Token expired',
      };
    } catch (e) {
      return {
        code: '500',
        message: 'Internal Server Error',
        detail: 'Internal Server Error',
      };
    }
  };
}

export function getAuthInfo() {
  const authInfo = sessionStorage.getItem('authInfo');
  if (authInfo) {
    return JSON.parse(authInfo);
  }
  return {};
}

export function setAuthInfo(authInfo) {
  sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
}

export function updateIdleTime() {
  sessionStorage.setItem('idleTime', Date.now());
}

export function isAlreadyIdleTooLong() {
  const timeNow = Date.now();
  if (sessionStorage.getItem("idleTime") == null) {
    return false;
  }
  if (sessionStorage.getItem("authInfo") == null) {
    return false;
  }

  const timeBefore = sessionStorage.getItem('idleTime');
  
  let idleTime = timeNow - timeBefore;
  // 1 min = 60000
  // 30 min = 1800000
  // 1 Day = 86400000
  if (idleTime > 1800000) {
    return true;
  }else{
    return false;
  }
}

export function clearAuthInfo() {
  sessionStorage.removeItem('authInfo');
  sessionStorage.removeItem('idleTime');
}

export function isLoggedIn() {
  const authInfo = getAuthInfo();
  return authInfo.access_token !== undefined;
}

export function getAccessToken() {
  const authInfo = getAuthInfo();
  return authInfo.access_token;
}

export function getRefreshToken() {
  const authInfo = getAuthInfo();
  return authInfo.refresh_token;
}

export default new AuthHelper();
