import axios from "axios";
import { apiConfig } from "../../settings";
import {
  setAuthInfo,
  clearAuthInfo,
  getRefreshToken,
  isAlreadyIdleTooLong,
  updateIdleTime,
} from "../../helpers/authHelper";

import { getLogout } from "../../redux/request/apis";

const instance = axios.create({
  baseURL: apiConfig.fetchUrl,
  timeout: 60000,
});

let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRrefreshed(token) {
  subscribers.map((cb) => cb(token));
}

function forceLogout() {
  isRefreshing = false;
  try {
    const data = {
      // memberId: members[i].memberId,
      // badgeId,
    };
    const response = getLogout({ data });
    clearAuthInfo();
    window.location = "/login";
  } catch (error) {
    clearAuthInfo();
    window.location = "/login";
  }
}

instance.interceptors.request.use(
  function (config) {
    if (isAlreadyIdleTooLong()) {
      forceLogout();
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    updateIdleTime();
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(undefined, (err) => {
  const {
    config,
    response: { status },
  } = err;
  const originalRequest = config;
  if (status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;

      const data = {
        client_id: apiConfig.clientId,
        client_secret: apiConfig.clientSecret,
        grant_type: "refresh_token",
        refresh_token: getRefreshToken(),
      };

      instance
        .post("/api/v1/user/login", data)
        .then((response) => {
          isRefreshing = false;
          setAuthInfo({
            ...response.data,
          });
          onRrefreshed(response.data.access_token);
          subscribers = [];
        })
        .catch(() => {
          forceLogout();
        });
    }
    const requestSubscribers = new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(axios(originalRequest));
      });
    });
    return requestSubscribers;
  }
  return Promise.reject(err);
});

export default instance;
