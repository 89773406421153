import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const WDReserveGiftModalWrapper = styled.div`
  .points-needed {
    font-size: 20px;
    color: #e5007e;
  }
  .gift-title {
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
    border-image-source: linear-gradient(to left, #fcaf17, #f37169, #e50076);
    border-image-slice: 1;
    padding: 10px 0px;
    margin-bottom: 10px;
  }
  .gift-info {
    margin-bottom: 10px;
    .title {
      display: inline-block;
      padding-right: 10px;
      font-weight: 800;
    }
    .value {
      display: inline-block;
    }
  }
`;

const ReserveGiftModalWrapper = WithDirection(WDReserveGiftModalWrapper);

export { ReserveGiftModalWrapper };
