import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';
import { isLoggedIn } from './helpers/authHelper';

const RestrictedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (isLoggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    ))
    }
  />
);

const PublicRoutes = ({ history }) => (
  <ConnectedRouter history={history}>
    <div>
      <Switch>
        <Route
          exact
          path="/login"
          component={asyncComponent(() => import('./containers/Login'))}
        />
        <Route
          exact
          path="/404"
          component={asyncComponent(() => import('./containers/Page404'))}
        />
        <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />
      </Switch>
    </div>
  </ConnectedRouter>
);

export default connect(state => ({
  isLoggedIn: state.Auth.accessToken !== null,
}))(PublicRoutes);
