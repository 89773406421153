import moment from "moment";

const dateFormat = "YYYYMMDD";
const dateTimeFormat = "YYYYMMDD HH:mm:ss";
const dateTimeSlashFormat = "YYYY/MM/DD HH:mm:ss";
const dateDisplayFormat = "YYYY-MM-DD";
const dateTimeDisplayFormat = "YYYY-MM-DD HH:mm:ss";

export function dateTimeStringToDate(dateString) {
  return moment(dateString, dateTimeFormat);
}

export function dateStringToDate(dateString) {
  return moment(dateString, dateFormat);
}

export function dateTimeToString(date) {
  return date.format(dateTimeDisplayFormat);
}

export function dateToString(date) {
  return date.format(dateDisplayFormat);
}

export function dateToAPIString(date) {
  return date.format(dateFormat);
}

export function dateTimeToAPIString(date) {
  return date.format(dateTimeFormat);
}

export function dateTimeToAPISlashString(date) {
  return date.format(dateTimeSlashFormat);
}

export function dateTimeStringToDisplayString(dateString) {
  const date = dateTimeStringToDate(dateString);
  return dateToString(date);
}

export function dateTimeStringToDisplayLaterString(dateString1, dateString2) {
  const date1 = dateTimeStringToDate(dateString1);
  const date2 = dateTimeStringToDate(dateString2);
  if (date1.isAfter(date2)) {
    return dateToString(date1);
  } else {
    return dateToString(date2);
  }
}

export function dateTimeStringToDateTimeDisplayString(dateString) {
  const date = dateTimeStringToDate(dateString);
  return dateTimeToString(date);
}
