import actions from './actions';

const initState = {
  accessToken: null,
  tokenType: null,
  refreshToken: null,
  expiresIn: 0,
  scope: null,
  accountId: 0,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        tokenType: action.payload.token_type,
        refreshToken: action.payload.refresh_token,
        expiresIn: action.payload.expires_in,
        scope: action.payload.scope,
        accountId: action.payload.accountId,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
