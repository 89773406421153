import { addLocaleData } from 'react-intl';
import Enlang from './entries/en-US';
import ZhCNlang from './entries/zh-Hans-CN';
import ZhHKlang from './entries/zh-Hans-HK';

// import '@formatjs/intl-pluralrules/polyfill';
// import '@formatjs/intl-pluralrules/locale-data/en';
// import '@formatjs/intl-pluralrules/locale-data/zh';

// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-relativetimeformat/locale-data/en';
// import '@formatjs/intl-relativetimeformat/locale-data/zh';

const AppLocale = {
  en: Enlang,
  zhCN: ZhCNlang,
  zhHK: ZhHKlang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zhCN.data);
addLocaleData(AppLocale.zhHK.data);

export default AppLocale;
