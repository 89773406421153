import notification from "../components/notification";

export function apiErrorHandler(err, intl, prefix) {
  try {
    const stringExists =
      !!intl.messages[`${prefix}error.${err.response.data.code}`] ||
      !!intl.messages[`${prefix}error.${err.response.data.returnCode}`] ||
      !!intl.messages[`${prefix}error.${err.response.data.errorCode}`];

    if (err.response.data.code == "E999") {
      notification("error", intl.formatMessage({ id: "freezeAccountError" }));
    } else if (err.response.data.code == "E998") {
      notification(
        "error",
        intl.formatMessage({ id: "terminatedAccountError" })
      );
    } else if (stringExists) {
      notification(
        "error",
        intl.formatMessage({ id: "common.error" }),
        (!!intl.messages[`${prefix}error.${err.response.data.code}`] &&
          intl.formatMessage({
            id: `${prefix}error.${err.response.data.code}`,
          })) ||
          (!!intl.messages[`${prefix}error.${err.response.data.returnCode}`] &&
            intl.formatMessage({
              id: `${prefix}error.${err.response.data.returnCode}`,
            })) ||
          (!!intl.messages[`${prefix}error.${err.response.data.errorCode}`] &&
            intl.formatMessage({
              id: `${prefix}error.${err.response.data.errorCode}`,
            }))
      );
    } else if (stringExists) {
      notification(
        "error",
        intl.formatMessage({ id: "common.error" }),
        intl.formatMessage({ id: `error.${err.response.data.code}` })
      );
    } else {
      notification(
        "error",
        intl.formatMessage({ id: "common.error" }),
        `${intl.formatMessage({ id: "error.unknownError" })} Error Code: ${
          err.response.code
        }, Error: ${err}`
      );
    }
  } catch (e) {
    console.error(e);
    notification(
      "error",
      intl.formatMessage({ id: "common.error" }),
      `${intl.formatMessage({
        id: "error.unknownError",
      })} Error: ${e} Server Error: ${err.response}`
    );
  }
}
