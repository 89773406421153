export function clearUser() {
  sessionStorage.removeItem('USER');
}
export function setUser(user) {
  sessionStorage.setItem('USER', JSON.stringify(user));
}

export function getUser() {
  try {
    const user = JSON.parse(sessionStorage.getItem('USER'));
    return user;
  } catch (err) {
    clearUser();
  }
}

export function clearAuthority() {
  sessionStorage.removeItem('AUTHORITY');
}

export function setAuthority(authority) {
  sessionStorage.setItem('AUTHORITY', JSON.stringify(authority));
}

export function getAuthority() {
  try {
    const authority = JSON.parse(sessionStorage.getItem('AUTHORITY'));
    return authority;
  } catch (err) {
    clearUser();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => (number > 1 ? 's' : '');
  const number = num => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export const showCountableNounsByNumber = (number, intl) => {
  if(intl.locale === 'en-US') {
      return Math.abs(number)>1 ? 's' : ''
  }else{
      return ''
  }
}